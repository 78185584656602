import React, { useCallback, useEffect, useState } from 'react';
import API from '../../utils/API';
import Layout from '../../components/layout/layout';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from './data.module.scss';
import dayjs from 'dayjs';
import Preloader from '../../components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal/Modal';
import { useHistory } from 'react-router-dom';
import { HandleError, handleRedirect } from '../../utils/helpers';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import DataInjuryManagement from '../../components/DataInjuryManagement/DataInjuryManagement';
import { sendAmplitudeData } from '../../utils/amplitude';

export default function Data(props) {
  const [data, setData] = useState(null);
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [success, setSuccess] = useState(false);
  const [deltaState, setDelta] = useState(0);
  const [tooltipData, setTooltipData] = useState(null);

  let history = useHistory();

  if (data && data.user && !data.user.is_data_manager) {
    history.push('/403');
  }

  const { t, i18n } = useTranslation();

  const textTooltipTitle = t('Data');

  const toggleTooltip = (inf) => {
    setTooltipData(inf);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.get('/data/');
      if (response.status === 200) {
        handleRedirect(response, history);
        setData(response.data);
        setLastUpdateDate(response.data.last_update);
        setImage(response.data.club.icon);
        if (props.user === '') {
          props.setUser(response.data.user);
        }
      }
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status) {
        HandleError(e, history);
      }
      console.log('Error fetch Data: ', e);
    }
  }, [setData, setLastUpdateDate, setImage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateReport = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        setLoading(true);
        let formData = new FormData();
        let delta = dayjs(new Date()).format('YYYY-MM-DD') !== e.target.data_date.value ? 1 : 0;

        formData.append('team_id', modalItem.team.id);
        formData.append('delta', deltaState);
        formData.append('csrfmiddlewaretoken', e.target.csrfmiddlewaretoken.value);

        const response = await API.post(`/schedule_run/`, formData);

        if (response.status === 200) {
          if (response.data && response.data.result === 'ok') {
            setSuccess(true);
            fetchData();
            console.log(`event: updated data Status:success up until: ${deltaState ? 'yesterday' : 'today'}`);
            sendAmplitudeData(`updated data`, {
              detail: `Status:success up until: ${deltaState ? 'yesterday' : 'today'}`,
            });
          } else {
            setModalItem(null);
            console.log(`event: updated data Status:failed up until: ${deltaState ? 'yesterday' : 'today'}`);
            sendAmplitudeData(`updated data`, {
              detail: `Status:failed up until: ${deltaState ? 'yesterday' : 'today'}`,
            });
          }
        } else {
          //throw new Error();
          console.log(`event: updated data Status:failed up until: ${deltaState ? 'yesterday' : 'today'}`);
          sendAmplitudeData(`updated data`, {
            detail: `Status:failed up until: ${deltaState ? 'yesterday' : 'today'}`,
          });
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.status) {
          HandleError(e, history);
        }
        console.log('Error post Data: ', e);
        console.log(`event: updated data Status:failed up until: ${deltaState ? 'yesterday' : 'today'}`);
        sendAmplitudeData(`updated data`, {
          detail: `Status:failed up until: ${deltaState ? 'yesterday' : 'today'}`,
        });
      }
    },
    [deltaState, modalItem],
  );

  return (
    <Layout isSidebarCollapsed={props.isSidebarCollapsed} changeCollapseSidebar={props.changeCollapseSidebar} user={props.user}>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.dataContainer}>
          <HeaderComponent isSidebarCollapsed={props.isSidebarCollapsed} title={t('Data')} lastUpdateDate={lastUpdateDate} lastUpdateTitle={'last-updated'} image={image} />

          <div className={styles.data}>
            {data &&
              data.latest_updates &&
              data.latest_updates.map((el) => {
                const latestUpdatesItem = { ...el };
                return (
                  <div key={el.team.id} className={`${styles.team} ${styles.panel}`}>
                    <div className={styles.team__wrap}>
                      <div className={styles.team__icon}>
                        <img src={data.club.icon} alt="icon" />
                      </div>
                      <div className={styles.team__cont}>
                        <form method="post" onSubmit={updateReport}>
                          <div className={styles.team__title}>
                            <div className={styles.team__titleCaption}>{t('Team')}:</div>
                            <div className={styles.team__titleName}>{el.team.name}</div>
                          </div>
                          <div className={styles.team__lastUpdate}>
                            <div className={`${styles.team__lastUpdateTitle} ${styles.textTransformCapitalize}`}>{t('last-update')}:</div>
                            <div className={styles.team__lastUpdateDate}>
                              {!latestUpdatesItem.update_in_progress ? el.date && dayjs(el.date).format('MMM. DD, YYYY') : <div>{t('in-progress')}</div>}
                            </div>
                          </div>
                          {data && data.csrf_token && <input type="hidden" required value={data.csrf_token} name="csrfmiddlewaretoken" />}
                          <input type="hidden" required value={latestUpdatesItem.id} name="team_id" />
                          <input type="hidden" required value={latestUpdatesItem.date} name="data_date" />

                          {!latestUpdatesItem.update_in_progress ? (
                            <div
                              className={styles.team__update}
                              onClick={() => {
                                setModalItem(latestUpdatesItem);
                                console.log(`event: Clicked update data`);
                                sendAmplitudeData(`Clicked update data`);
                              }}
                            >
                              <span>{t('Update')}</span>
                            </div>
                          ) : (
                            <div className={styles.team__inProgress}>{t('report-in-progress')}</div>
                          )}
                          {!!modalItem && (
                            <Modal
                              isSidebarCollapsed={props.isSidebarCollapsed}
                              isOpen={!!modalItem}
                              onClose={() => {
                                setModalItem(null);
                                setSuccess(false);
                              }}
                              maxWidth={400}
                              minHeight={160}
                            >
                              {!success && (
                                <div className={styles.modalUpdate}>
                                  <div className={styles.modalUpdate__title}>
                                    <span>{t('manual-app-update')}</span>
                                  </div>
                                  <div className={styles.modalUpdate__content}>
                                    <div className={styles.modalUpdate__description1}>
                                      {t('the-update-will-replace-zone7-insights-for-the-selected-day-in-case-they-exist') +
                                        ' ' +
                                        t('run-the-update-only-if-insights-are-missing-or-based-on-incomplete-data')}
                                    </div>
                                    <div className={styles.modalUpdate__description2}>{t('this-update-may-take-several-hours-a-notification-will-be-sent-to-the-teams-data-manager-once-comple')}</div>
                                    <div className={styles.modalUpdate__radioCont}>
                                      <div className={styles.modalUpdate__radioTitle}>{t('data-uploaded-until') + ':'}</div>
                                      <label className={styles.container}>
                                        {t('Today')}
                                        <input type="radio" name="radio" value="today" defaultChecked onChange={() => setDelta(0)} />
                                        <span className={styles.checkmark} />
                                      </label>
                                      <label className={styles.container}>
                                        {t('Yesterday')}
                                        <input type="radio" name="radio" value="yesterday" onChange={() => setDelta(1)} />
                                        <span className={styles.checkmark} />
                                      </label>
                                    </div>
                                    <div className={styles.modalUpdate__buttons}>
                                      <div className={styles.modalUpdate__button} onClick={() => setModalItem(null)}>
                                        <span>{t('Cancel')}</span>
                                      </div>
                                      <button className={styles.modalUpdate__button}>
                                        <span>{t('update')}</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {success && (
                                <div
                                  className={styles.contact__wrap}
                                  onClick={() => {
                                    setModalItem(null);
                                    setSuccess(false);
                                  }}
                                >
                                  <div className={`${styles.modalUpdate__title} ${styles.modalUpdate__titleSuccess} ${styles.textTransformCapitalize}`}>{t('running-app-update')}</div>
                                  <div className={styles.modalUpdate__content}>
                                    <div className={styles.modalUpdate__ok}>
                                      <img alt="check" src="/static/images/icons/check_circle.svg" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Modal>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                );
              })}
            {!!data && !!data.club && data.club.enable_injury_editing && <DataInjuryManagement {...data} isSidebarCollapsed={props.isSidebarCollapsed} />}
          </div>
        </div>
      )}
    </Layout>
  );
}