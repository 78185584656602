import React from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import styles from './layout.module.scss';

export const siteTitle = 'Zone7';

export default function Layout({ children, isSidebarCollapsed, changeCollapseSidebar, user }) {
  return (
    <div className={`${styles.container} ${!isSidebarCollapsed && styles['container--open']}`}>
      {/*<Head>*/}
      {/*    <link rel="icon" href="/favicon.png"/>*/}
      {/*    <meta*/}
      {/*        name="description"*/}
      {/*        content="zone7"*/}
      {/*    />*/}
      {/*    <meta name="og:title" content={siteTitle}/>*/}
      {/*</Head>*/}
      <header className={styles.header}>
        <Sidebar isSidebarCollapsed={isSidebarCollapsed} changeCollapseSidebar={changeCollapseSidebar} user={user} />
      </header>
      <main className={isSidebarCollapsed ? '' : 'collapsed'}>
        {window.matchMedia('(min-width: 1024px)').matches ? (
          children
        ) : isSidebarCollapsed ? (
          children
        ) : (
          <div
            className={styles.overlay}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              changeCollapseSidebar(true);
            }}
          >
            {children}
          </div>
        )}
      </main>
    </div>
  );
}
