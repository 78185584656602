import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Layout from '../../components/layout/layout';
import HistoryComponent from '../../components/HistoryComponent/HistoryComponent';
import API from '../../utils/API';
import styles from './history.module.scss';
import Preloader from '../../components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HandleError, handleRedirect } from '../../utils/helpers';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';

export default function History(props) {
  const [data, setData] = useState(null);
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const [date, setDate] = useState(new Date());

  const { t, i18n } = useTranslation();

  const fetchData = useCallback(
    async (newDate) => {
      try {
        setLoading(true);
        const dateParameter = newDate ? '?date=' + dayjs(newDate).format('YYYY-MM-DD') : '';
        const response = await API.get('/history/' + dateParameter);
        if (response.status === 200) {
          handleRedirect(response, history);
          setData(response.data);
          setLastUpdateDate(response.data.last_update);
          setImage(response.data.club.icon);
          if (props.user === '') {
            props.setUser(response.data.user);
          }
        }
        setLoading(false);
        window.scrollTo(0, 0);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.status) {
          HandleError(e, history);
        }
        console.log('Error fetch history data: ', e);
      }
    },
    [setData, setLastUpdateDate, setImage, setLoading],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const viewDate = useCallback(async () => {
    fetchData(date);
  }, [date]);

  const handleDate = useCallback(
    async (el) => {
      setDate(el);
    },
    [setDate],
  );

  return (
    <Layout isSidebarCollapsed={props.isSidebarCollapsed} changeCollapseSidebar={props.changeCollapseSidebar} user={props.user}>
      {/*<Head>*/}
      {/*    <title>History</title>*/}
      {/*    <meta name="viewport" content="initial-scale=1.0, width=device-width"/>*/}
      {/*</Head>*/}
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.historyContainer}>
          <HeaderComponent isSidebarCollapsed={props.isSidebarCollapsed} title={t('History')} lastUpdateDate={lastUpdateDate} lastUpdateTitle={'last-updated'} image={image} />

          <div className={styles.history}>
            <HistoryComponent {...data} viewDate={viewDate} handleDate={handleDate} date={date} />
          </div>
        </div>
      )}
    </Layout>
  );
}
