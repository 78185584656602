import styles from './ServerError.module.scss';

export default function ServerError() {
  return (
    <div className={styles.center}>
      <div className={styles.errorCode}>500</div>
      <div className={styles.errorDesc}>Internal Server Error</div>
      <div>The server encountered something unexpected that didn't allow it to complete the request. We apologize.</div>
      <br />
      <div>
        <a href="/" className="btn btn-primary m-t">
          Home
        </a>
      </div>
    </div>
  );
}
