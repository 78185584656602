import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BioRiskFactors.module.scss';
import Chart from 'react-apexcharts';
import Tooltip from '../../Tooltip/Tooltip';

export default function BioRiskFactors(props) {
  const [tooltipData, setTooltipData] = useState(null);

  const { t, i18n } = useTranslation();

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };

  let arrayBio = [];
  let minBio = -0.5;
  let maxBio = 0.5;

  if (props.biomechanical_factors && arrayBio.length === 0) {
    arrayBio = [props.biomechanical_factors[props.biomechanical_factors.length - 1], ...props.biomechanical_factors.slice(0, props.biomechanical_factors.length - 1)];
    minBio = props.biomechanical_factors_range.min;
    maxBio = props.biomechanical_factors_range.max;
  }

  const bioSeries = [
    {
      name: t('Average'),
      data: arrayBio && arrayBio.map((el) => el.average),
    },
    {
      name: t('Alert'),
      data: arrayBio && arrayBio.map((el) => el.value),
    },
  ];

  const bioOptions = {
    chart: {
      height: 350,
      type: 'radar',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      redrawOnParentResize: true,
    },
    colors: [props.colors.average, props.colors.alert],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [props.colors.average, props.colors.alert],
        inverseColors: false,
        opacityFrom: 0.3,
        opacityTo: 0.5,
        stops: [0, 100],
        colorStops: [],
      },
    },
    legend: {
      position: 'left',
      horizontalAlign: 'left',
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: '500',
      offsetY: 250,
      offsetX: 0,
      labels: {
        colors: [props.colors.white],
      },
      itemMargin: {
        vertical: 10,
      },
      markers: {
        width: 11,
        height: 11,
      },
    },
    markers: {
      size: 0,
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeWidth: 0.5,
          strokeColors: props.colors.gray,
          connectorColors: props.colors.gray,
          fill: {
            colors: [props.background ? 'transparent' : 'transparent'],
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 400,
        options: {
          legend: {
            fontSize: '14px',
            itemMargin: {
              horizontal: 30,
              vertical: 0,
            },
          },
          title: {
            style: {
              fontSize: '17px',
            },
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          legend: {
            position: 'top',
            horizontalAlign: 'center',
            offsetY: -20,
            height: 54,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          legend: {
            offsetX: -20,
          },
        },
      },
    ],
    stroke: {
      width: 1,
    },
    title: {
      text: t('biomechanical-risk-factors'),
      align: 'left',
      offsetX: -10,
      style: {
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        color: props.colors.white,
      },
    },
    xaxis: {
      categories: arrayBio && arrayBio.map((el) => el.name),
      labels: {
        show: true,
        style: {
          fontSize: '13px',
          fontFamily: 'Roboto',
          fontWeight: 'lighter',
        },
      },
    },
    yaxis: {
      min: minBio,
      max: maxBio,
      tickAmount: 4,
      labels: {
        formatter: (value) => {
          return '|';
        },
      },
    },
  };

  return (
    <>
      {props.biomechanical_factors && (
        <div className={`${styles.bioRiskFactors} ${styles.panel}`} style={props.background && { background: 'linear-gradient(#2C2F35, #2F3539)' }}>
          <div>
            <Chart options={bioOptions} series={bioSeries} type="radar" height={350} width="100%" />
          </div>
          <span
            className={styles.player__tooltip}
            onClick={(e) =>
              toggleTooltip({
                clientX: e.clientX,
                clientY: e.clientY,
                title: props.tooltips.biomechanical_risk_factors.title,
                content: props.tooltips.biomechanical_risk_factors.text,
              })
            }
          >
            i
          </span>
        </div>
      )}
      {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
    </>
  );
}
