import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import React, { useState, useCallback, useEffect, Suspense } from 'react';
import { ROUTES } from './utils/constants';
import Players from './pages/players';
import Player from './pages/player/[id]';
import MyTeam from './pages/my-team';
import Teams from './pages/teams';
import Data from './pages/data';
import TeamReport from './pages/team-report';
import ContactUs from './pages/contact-us';
import History from './pages/history';
import Login from './pages/login';
import NotFound from './components/Errors/NotFound/NotFound';
import BadRequest from './components/Errors/BadRequest/BadRequest';
import PermissionDenied from './components/Errors/PermissionDenied/PermissionDenied';
import ServerError from './components/Errors/ServerError/ServerError';
import PlayerSummaryContainer from './components/Team/PlayerSummaryContainer/PlayerSummaryContainer';
import { setAmplitudeUserId, setAmplitudeUserProperties, sendAmplitudeData } from './utils/amplitude';
import { setDefaultLocale } from 'react-datepicker';
import PlayerRadarContainer from './components/Player/PlayerRadarContainer/PlayerRadarContainer';
import PlayerRiskTrendContainer from './components/Player/PlayerRiskTrendContainer/PlayerRiskTrendContainer';
import DailyPlayersAtRiskContainer from './components/Team/DailyPlayersAtRiskContainer/DailyPlayersAtRiskContainer';
import RiskHeatmapContainer from './components/Team/RiskHeatmapContainer/RiskHeatmapContainer';
import WorkloadContainer from './components/Player/WorkloadContainer/WorkloadContainer';

function usePageViews() {
  let location = useLocation();

  //runs every time when change page
  useEffect(() => {
    // put your analytics code here
    // location.pathname - is an available parameter
    const parts = location.pathname.split('/');
    const page = parts.slice(0, 3).join('/');
    const suffix = parts.slice(3).join('/');
    console.log(`event: ${page}, ${suffix}`);
    sendAmplitudeData(`open ${page}`, { detail: suffix });
  }, [location]);
}

function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const [user, setUser] = useState('');
  const [userData, setUserData] = useState('');

  const changeCollapseSidebar = useCallback(
    (value) => {
      setIsSidebarCollapsed(value);
    },
    [setIsSidebarCollapsed],
  );

  let history = useHistory();

  let csrftoken = null;

  if (typeof localStorage !== 'undefined') {
    csrftoken = localStorage.getItem('csrftoken');
  }

  useEffect(() => {
    if (!csrftoken) {
      //history.push(ROUTES.LOGIN)
    }
  }, [csrftoken, history]);

  let propsObj = { isSidebarCollapsed, changeCollapseSidebar, user, setUser, userData, setUserData };

  //runs once from app(page) reload
  useEffect(() => {}, []);

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('zone7user', user.username);
    }
    // put your analytics code here
    setAmplitudeUserId(user.username);
    setAmplitudeUserProperties({
      admin: user.is_staff,
      club: user.club,
    });
  }, [user]);

  usePageViews();

  return (
    <>
      <Suspense fallback="loading">
        <Switch>
          <Route path={`${ROUTES.PLAYER}/:id/:date?`} render={() => <Player {...propsObj} />} />
          <Route path={ROUTES.PLAYERS} render={() => <Players {...propsObj} />} />
          <Route path={ROUTES.MY_TEAM} render={() => <MyTeam {...propsObj} />} />
          <Route path={ROUTES.TEAMS} render={() => <Teams {...propsObj} />} />
          <Route path={ROUTES.TEAM_REPORT} render={() => <TeamReport {...propsObj} />} />
          <Route path={ROUTES.HISTORY} render={() => <History {...propsObj} />} />
          <Route path={ROUTES.DATA} render={() => <Data {...propsObj} />} />
          <Route path={ROUTES.CONTACT_US} render={() => <ContactUs {...propsObj} />} />
          <Route path={ROUTES.LOGIN} render={() => <Login setUser={{setUser, setUserData}} />} />
          <Route path={ROUTES.PLAYER_SUMMARY} render={() => <PlayerSummaryContainer {...propsObj} />} />
          <Route path={ROUTES.PLAYERS_RISK} render={() => <DailyPlayersAtRiskContainer {...propsObj} />} />
          <Route path={`${ROUTES.RADAR}/:id/:date?`} render={() => <PlayerRadarContainer {...propsObj} />} />
          <Route path={`${ROUTES.RISKTREND}/:id/:date?`} render={() => <PlayerRiskTrendContainer {...propsObj} />} />
          <Route path={ROUTES.HEATMAP} render={() => <RiskHeatmapContainer {...propsObj} />} />
          <Route path={ROUTES.WORKLOAD} render={() => <WorkloadContainer {...propsObj} />} />
          <Route path={ROUTES.BASE} render={() => <Players {...propsObj} />} />
          <Route exact path={'/'} render={() => <Players {...propsObj} />} />
          <Route path={'/400'} render={() => <BadRequest />} />
          <Route path={'/403'} render={() => <PermissionDenied />} />
          <Route path={'/404'} render={() => <NotFound />} />
          <Route path={'/500'} render={() => <ServerError />} />
          <Route path={'*'} render={() => <NotFound />} />
        </Switch>
      </Suspense>
    </>
  );
}

export default App;
