import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PlayerSummary from '../PlayerSummary/PlayerSummary';
import Preloader from '../../Preloader/Preloader';
import { colors } from '../Team';

export default function PlayerSummaryContainer(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  // useEffect(async () => {
  //         const teamData = await fetchTeamData(history, setLoading);
  //         setData(teamData);
  //     }
  //     , []);

  useEffect(() => {
    function handleEvent(message) {
      setData(message.data);
      setLoading(false);
    }

    setLoading(true);

    window.addEventListener('message', handleEvent);

    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return (
    <>
      <PlayerSummary {...data} colors={{ ...colors }} background={true} />
    </>
  );
}
