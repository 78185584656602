import styles from './Preloader.module.scss';

export default function Preloader(props) {
  return (
    <>
      <div className={styles.cont}>
        <img src="/static/images/preloader.svg" alt="preloader" className={`${props.className ? props.className : ''}`} />
        {props.children}
      </div>
    </>
  );
}
