import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import styles from './MicroCycleSimulator.module.scss';
import API from '../../../utils/API';
import dayjs from 'dayjs';
import Preloader from '../../Preloader/Preloader';
import Modal from '../../Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { handleRedirect } from '../../../utils/helpers';
import { sendAmplitudeData } from '../../../utils/amplitude';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const MicroCycleSimulator = ({ simulator, ...props }) => {
  const refContainer = useRef(null);
  const refRiskTrend = useRef(null);
  const refSimulationDate = useRef(null);
  const [simulateData, setSimulateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [isInputFail, setIsInputFail] = useState(false);
  const [inputErrorsList, setInputErrorsList] = useState([]);
  const [isRequestFail, setIsRequestFail] = useState(false);
  const [featuresData, setFeaturesData] = useState(simulator?.features);
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [disableDots, setDisableDots] = useState(0);
  const [activeDots, setActiveDots] = useState(-1);
  const [inputValues, setInputValues] = useState({});
  const [typicalValues, setTypicalValues] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});
  const [typicalValidationValues, setTypicalValidationValues] = useState(null);
  const csrf_token = localStorage.getItem('csrftoken');
  const { t } = useTranslation();
  let history = useHistory();

  const simulatorInputFields = useCallback(() => {
    let simulatorObjects = Object.values(simulator.features);
    let fields = simulatorObjects.map((field) => field.name);
    let res = {};
    for (let i = 0; i < fields.length; i++) {
      res[fields[i]] = '';
    }
    return res;
  }, [simulator?.features]);

  const resetInputValues = useCallback(() => {
    const tempInputValues = {};
    simulator.dates.forEach((item) => Object.assign(tempInputValues, { [item.date]: simulatorInputFields() }));
    setInputValues(tempInputValues);
    return tempInputValues;
  }, [simulator?.dates]);

  useEffect(() => {
    const resetedInputValues = resetInputValues();
    getTypicalValues(resetedInputValues);
  }, [csrf_token]);

  // fill simulator with typical value after api call
  useEffect(() => {
    if (typicalValues !== null && typicalValidationValues !== null) {
      changedInputData(typicalValues);
    }
  }, [typicalValues, typicalValidationValues]);

  const changeFeaturesData = useCallback((localFeaturesData, response) => {
    let localFeaturesDataCopy = [...localFeaturesData];
    localFeaturesDataCopy.forEach((inputValue, inputKey) => {
      localFeaturesDataCopy[inputKey].min_value = response[inputValue.name].min;
      localFeaturesDataCopy[inputKey].max_value = response[inputValue.name].max;
    });
    setFeaturesData(localFeaturesDataCopy);
  }, []);

  const changeInputData = useCallback((localInputData, response) => {
    let localInputDataCopy = Object.assign({}, localInputData);

    for (let inputKey of Object.keys(localInputDataCopy)) {
      localInputDataCopy[inputKey] = response[inputKey];
    }
    setInputValues(localInputDataCopy);
  }, []);

  const remove = useCallback((arr, item) => arr.filter((i) => i !== item));
  const add = useCallback((arr, item) => (!arr.includes(item) ? [...arr, item] : arr));

  const fieldValidate = useCallback(
    (value, min, max, display_name, name, date) => {
      const isPositiveNumber = /^\+?(0|[0-9]\d*)$/;
      if ((isPositiveNumber.test(value) && value >= min && value <= max) || value === 0) {
        setInputErrorsList(remove(inputErrorsList, date + name));
        return true;
      } else {
        setIsModalOpen({ min, max, display_name });
        setInputErrorsList(add(inputErrorsList, date + name));
        setIsInputFail(true);
      }
    },
    [inputErrorsList],
  );

  const getNewToken = useCallback(async () => {
    try {
      const response = await API.get(`/refresh_token/`, { withCredentials: true });

      if (response.status === 200) {
        localStorage.setItem('csrftoken', response.data.csrf_token);
      }
    } catch (e) {
      console.log('Error when getting a new token ', e);
    }
  }, []);

  const handleSimulate = useCallback(async () => {
    const requestObj = {
      player: props.player.id,
      params: inputValues,
    };

    try {
      setLoading(true);
      const options = {
        withCredentials: true,
        headers: { 'X-CSRFToken': csrf_token },
      };
      const jsonRequestObj = JSON.stringify(requestObj);
      sendAmplitudeData(`run simulator`, { detail: `${props.player.first_name} ${props.player.last_name}` });

      const response = await API.post(`/simulator/`, jsonRequestObj, options);
      if (response.status === 200 && !response.data.error) {
        handleRedirect(response, history);
        setSimulateData({ simulation_result: response.data.simulation_result, warnings: response.data.warnings });
        if (response.data.warnings && response.data.warnings.length > 0) {
          setIsModalOpen(true);
        }
      }

      if (response.data.error) {
        setErrorMessages({ error: response?.data?.error?.date, details: response?.data?.error?.message });
        setIsModalOpen(true);
        setIsRequestFail(true);
      }
      setTimeout(() => {
        refRiskTrend.current.goToSlide(refContainer.current.state.currentSlide);
        refSimulationDate.current.goToSlide(refContainer.current.state.currentSlide);
      }, 10);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setIsRequestFail(true);

      if (!isModalOpen) {
        setIsModalOpen(true);
        setErrorMessages({ message: e.message });
      }
      console.log('Error to fetch simulate data ', e);
      // if (e.response && e.response.status) {
      //     HandleError(e, history);
      // }
    }
  }, [inputValues, props, isModalOpen, csrf_token, refContainer, refRiskTrend, refSimulationDate]);

  const getTypicalValues = useCallback(
    async (resetedInputValues) => {
      try {
        const options = {
          withCredentials: true,
          headers: { 'X-CSRFToken': csrf_token },
        };
        const response = await API.post(`/typical_values/`, JSON.stringify({ player: props.player.id }), options);
        if (response.status === 200 && !response.data.error) {
          handleRedirect(response, history);
          setTypicalValues(response.data.typical_values);
          setTypicalValidationValues(response.data.valid_values);
        }

        if (response.data.error) {
          setErrorMessages({ error: response?.data?.error, details: response?.data?.details });
          setIsModalOpen(true);
          setIsRequestFail(true);
        }
      } catch (e) {
        setIsRequestFail(true);
        if (e.response.status === 403) {
          await getNewToken();
        }

        if (!isModalOpen) {
          setIsModalOpen(true);
          setErrorMessages({ message: e.message });
        }
        console.log('Error to fetch typical data ', e);
      }
    },
    [props, featuresData, inputValues, isModalOpen, csrf_token],
  );

  const handleInputChange = useCallback(
    (value, display_name, date) => {
      const inputValuesCopy = JSON.parse(JSON.stringify(inputValues));
      inputValuesCopy[date] = {
        ...inputValuesCopy[date],
        [display_name]: value,
      };

      setInputValues(inputValuesCopy);
    },
    [inputValues, inputErrorsList],
  );

  const getCloseModal = useCallback(() => {
    setIsModalOpen(null);
    setIsRequestFail(false);
    setIsInputFail(false);
  }, []);

  const changedInputData = useCallback(
    (responseData) => {
      changeFeaturesData(featuresData, typicalValidationValues);
      changeInputData(inputValues, responseData);
    },
    [inputValues, featuresData, typicalValidationValues],
  );

  const getModalValidationBlockDescription = useCallback(() => {
    if (!isInputFail && isRequestFail) {
      return (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{errorMessages && errorMessages.error}</span>
          <span>{errorMessages && errorMessages.details}</span>
        </span>
      );
    } else if (!isInputFail && simulateData.warnings && simulateData.warnings.length > 0) {
      return (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          {simulateData.warnings.map((warning) => (
            <span key={warning.key}>{warning.text}</span>
          ))}
        </span>
      );
    } else {
      return '';
    }
  }, [isRequestFail, simulateData.warnings, errorMessages, isInputFail]);

  const InputsBlock = useCallback(
    (blockDate, gIndex) => {
      return featuresData.map((feature) => {
        return (
          <div className={`${styles.item} ${styles.optionItem}`} key={blockDate + feature.name}>
            <input
              className={`${styles.input} ${activeDots !== gIndex && styles.input_disabled}`}
              disabled={!typicalValidationValues || loading}
              placeholder={t(`${feature.unit}`)}
              type="text"
              onBlur={(event) => {
                fieldValidate(
                  +event.target.value,
                  typicalValidationValues && typicalValidationValues[feature.name].min,
                  typicalValidationValues && typicalValidationValues[feature.name].max,
                  feature.display_name,
                  feature.name,
                  blockDate,
                );
                handleInputChange(event.target.value, feature.name, blockDate);
                setInputFieldValue({});
              }}
              value={inputFieldValue.name === feature.name && inputFieldValue.date === blockDate ? inputFieldValue.value : inputValues?.[blockDate]?.[feature.name]}
              onChange={(event) => {
                setInputFieldValue({ date: blockDate, name: feature.name, value: event.target.value });
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  fieldValidate(
                    +event.target.value,
                    typicalValidationValues && typicalValidationValues[feature.name].min,
                    typicalValidationValues && typicalValidationValues[feature.name].max,
                    feature.display_name,
                    feature.name,
                    blockDate,
                  );
                  handleInputChange(event.target.value, feature.name, blockDate);
                  setInputFieldValue({});
                }
              }}
            />
          </div>
        );
      });
    },
    [inputValues, featuresData, inputFieldValue, inputErrorsList, typicalValidationValues, activeDots],
  );

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  const set = useCallback(() => {
    setTimeout(() => {
      setDisableDots(refContainer.current.state.currentSlide);
    }, 0);
  }, [refContainer]);

  return (
    <Fragment>
      <div className={styles.micro_cycle_simulator}>
        <div className={styles.micro_cycle_simulator__header_container}>
          <div className={styles.header}>{t('Micro-Cycle Simulator')}</div>
          <div className={styles.micro_cycle_simulator__header_button_container}>
            <button
              className={`${styles.btn__simulate} ${!typicalValidationValues || loading ? styles.disabled : ''}  ${styles.disable}`}
              style={{ marginRight: '41px' }}
              disabled={!typicalValidationValues || loading}
              onClick={() => {
                setInputErrorsList([]);
                changedInputData(typicalValues);
                console.log(`event: Added typical values"`);
                sendAmplitudeData(`Added typical values`);
              }}
            >
              {t('Typical Values')}
            </button>
            <button
              className={`${styles.btn__simulate} ${loading ? styles.disabled : ''} ${styles.disable}`}
              disabled={loading}
              onClick={() => {
                setInputErrorsList([]);
                resetInputValues();
              }}
            >
              {t('Clear Values')}
            </button>
          </div>
        </div>

        {isModalOpen && (
          <Modal isSidebarCollapsed={props.isSidebarCollapsed} isOpen={isModalOpen} maxWidth={321} minHeight={178} onClose={getCloseModal}>
            <div className={styles.local__modal_header}>{t('Warning')}</div>
            <div className={styles.local__modal_line} />
            <div className={styles.local__modal_validation_block}>
              {isInputFail && !isRequestFail && (
                <span style={{ marginBottom: '10px' }}>
                  <span style={{ textTransform: 'none' }} dangerouslySetInnerHTML={{ __html: t(`${isModalOpen.display_name}`) }} />
                  {` ${t('must be a number and within the range')} ${t('from')} ${isModalOpen.min} ${t('to')} ${isModalOpen.max}`}
                </span>
              )}
              {getModalValidationBlockDescription()}
            </div>
          </Modal>
        )}
        <div className={styles.simulator__container}>
          <div className={styles.simulator__container_options}>
            {featuresData.map((item) => {
              return <div className={styles.simulator__option} dangerouslySetInnerHTML={{ __html: t(`${item.display_name}`) }} key={item.display_name} />;
            })}
          </div>
          <div className={styles.simulator__container_carousel}>
            <Carousel
              swipeable={false}
              ref={refContainer}
              responsive={responsive}
              ssr={false}
              partialVisible={false}
              autoPlay={false}
              shouldResetAutoplay={false}
              slidesToSlide={1}
              renderDotsOutside
              arrows={false}
              draggable={false}
            >
              {simulator.dates.map((date, index) => {
                return (
                  <div className={styles.micro_cycle_simulator__item} key={date.date} onClick={() => setActiveDots(index)}>
                    <div className={`${styles.risk_display} ${index !== activeDots && styles.risk_display_not_active}`}>
                      {date.context}{date.context ? " - " : ""}{dayjs(date.date).format('MMMM D')}
                    </div>
                    {featuresData && InputsBlock(date.date, index)}
                  </div>
                );
              })}
            </Carousel>

            {refContainer.current && refContainer.current.state.containerWidth < refContainer.current.state.itemWidth * refContainer.current.state.totalItems && (
              <div className={styles.simulator__dot_container}>
                <div
                  className={styles.simulator__dot_button}
                  style={{
                    transform: 'rotate(90deg)',
                    backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`,
                    marginRight: '39px',
                  }}
                  onClick={() => {
                    refContainer.current.previous();
                    set();
                    if (refRiskTrend.current) {
                      refRiskTrend.current.previous();
                      refSimulationDate.current.previous();
                    }
                  }}
                />
                {simulator.dates.map((item, index) => {
                  const size = Math.round(refContainer.current.state.containerWidth / refContainer.current.state.itemWidth);
                  return <div className={`${styles.simulator__dot} ${(disableDots > index || index >= size + disableDots) && styles.simulator__dot_deactive}`} key={JSON.stringify(item)} />;
                })}
                <div
                  className={styles.simulator__dot_button}
                  style={{
                    transform: 'rotate(-90deg)',
                    backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`,
                    marginLeft: '30px',
                  }}
                  onClick={() => {
                    refContainer.current.next();
                    set();
                    if (refRiskTrend.current) {
                      refRiskTrend.current.next();
                      refSimulationDate.current.next();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.simulate__btns_block}>
          <button
            className={`${styles.btn__simulate} ${loading || (inputErrorsList && inputErrorsList.length > 0) ? styles.disabled : ''}`}
            disabled={loading || (inputErrorsList && inputErrorsList.length > 0)}
            onClick={() => {
              handleSimulate();
              if (props.refSimulation.current) props.refSimulation.current.scrollIntoView();
            }}
          >
            {t('Simulate')}
          </button>
        </div>
      </div>

      <div className={`${styles.r_t_simulator} ${loading ? styles.micro_cycle_simulator_preloader : ''}`} style={{ flexDirection: 'row' }}>
        {loading ? (
          <Preloader className={styles.preloader__img}>
            <p className={styles.preloader__text}>{t('Simulation Processing')}</p>
          </Preloader>
        ) : (
          simulateData &&
          simulateData.simulation_result &&
          simulateData.simulation_result.length > 0 && (
            <Fragment>
              <div className={`${styles.r_t_simulator__head} ${loading ? styles.margin__preloader : ''}`}>{t('risk-trend')}</div>
              <div className={`${styles.r_t_simulator__container}`}>
                <Carousel
                  ref={refRiskTrend}
                  swipeable={false}
                  responsive={responsive}
                  ssr={false}
                  partialVisible={false}
                  autoPlay={false}
                  shouldResetAutoplay={false}
                  slidesToSlide={1}
                  renderDotsOutside
                  arrows={false}
                  draggable={false}
                  className={`${styles.r_t_simulator__indications_block} ${
                    refContainer.current && refContainer.current.state.containerWidth <= refContainer.current.state.itemWidth * refContainer.current.state.totalItems
                      ? styles.r_t_simulator__indications_block_radius_left
                      : styles.r_t_simulator__indications_block_radius
                  }`}
                >
                  {simulateData &&
                    simulateData.simulation_result &&
                    simulateData.simulation_result.map((simulatorItem, index) => {
                      return (
                        <div key={simulatorItem.date} className={styles.r_t_simulator__result_item} style={{ minWidth: `${refContainer.current && refContainer.current.state.itemWidth}px` }}>
                          <div className={`${styles.r_t_simulator__indicator} `} onClick={() => setActiveDots(index)}>
                            <div className={`${styles.risk__indicator_container} ${index === activeDots && styles.risk__indicator_container_active}`}>
                              <div
                                className={`${styles.risk__indicator} 
                                   ${simulatorItem.prediction.contained === 'true' ? styles.contained : styles[simulatorItem.prediction.level]}`}
                              />
                            </div>
                            <div
                              className={`
                                ${styles.r_t_simulator__indicator_text} 
                                ${simulatorItem.prediction.contained === 'true' ? styles.contained : styles[simulatorItem.prediction.level]}`}
                            >
                              {simulatorItem.prediction.contained === 'true' ? t('contained-risk') : t(`${simulatorItem.prediction.level}-risk`)}
                            </div>
                          </div>
                          <div className={`${styles.arrow} ${index === simulateData.simulation_result.length - 1 && styles.opacity}`}>
                            <img src="/static/images/icons/arrow-up-outline.svg" alt="arrow" />
                          </div>
                        </div>
                      );
                    })}
                </Carousel>
                <Carousel
                  ref={refSimulationDate}
                  swipeable={false}
                  responsive={responsive}
                  ssr={false}
                  partialVisible={false}
                  autoPlay={false}
                  shouldResetAutoplay={false}
                  slidesToSlide={1}
                  renderDotsOutside
                  arrows={false}
                  draggable={false}
                >
                  {simulateData &&
                    simulateData.simulation_result &&
                    simulateData.simulation_result.length > 0 &&
                    simulateData.simulation_result.map((simulatorItem, index) => {
                      return (
                        <div key={simulatorItem.date} className={`${styles.r_t_simulator__result_item} `} style={{ minWidth: `${refContainer.current && refContainer.current.state.itemWidth}px` }}>
                          <div className={styles.r_t_simulator__output}>{t(`${dayjs(simulatorItem.date).format('MMMM D')}`)}</div>
                          <div className={`${styles.arrow}  ${index === simulateData.simulation_result.length - 1 && styles.opacity}`} />
                        </div>
                      );
                    })}
                </Carousel>
              </div>
            </Fragment>
          )
        )}
      </div>
    </Fragment>
  );
};

export default MicroCycleSimulator;
