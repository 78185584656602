import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ROUTES } from "../../utils/constants";
import { host } from "../../utils/API";
import TeamRiskFactors from "../Team/TeamRiskFactors/TeamRiskFactors";
import PlayerSummary from "../Team/PlayerSummary/PlayerSummary";
import Modal from "../Modal/Modal";
import styles from "./TeamReportComponent.module.scss";
import { CSVLink } from "react-csv";
import { sendAmplitudeData } from "../../utils/amplitude";

const colors = {
  white: "#ffffff",
  black32: "#202020",
  black40: "#282828",
  low_count: "#0f98d7",
  low_count_grad: "#708fff",
  contained_count: "#eb7800",
  contained_count_grad: "#bf950c",
  medium: "#e75d0d",
  risk_count: "#da2a16",
  risk_count_grad: "#da2a16",
  injured_count: "#9ea2a8",
  injured_count_grad: "#9ea2a8",
};

const teamRiskFactorsNames = {
  ["biomechanics"]: "biomechanics.svg",
  ["count"]: "count.svg",
  ["distance"]: "distance.svg",
  ["duration"]: "duration.svg",
  ["general"]: "count.svg",
  ["heart rate"]: "heart_rate.svg",
  ["injury history"]: "injury_history.svg",
  ["previous injuries"]: "injury_history.svg",
  ["lightning"]: "lightning1.svg",
  ["speed"]: "speed.svg",
  ["team schedule"]: "team_schedule.svg",
};

export default function TeamReportComponent(props) {
  const [active, setActive] = useState([]);
  const [modalDateItem, setModalDateItem] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const { t, i18n } = useTranslation();

  const addOrRemove = useCallback((arr, item) =>
    arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]
  );

  const togglePanel = useCallback(
    (id) => {
      setActive(addOrRemove(active, id));
    },
    [active, setActive]
  );

  const [isRiskItems, setIsRiskItems] = useState([]);

  const toggleRisks = useCallback(
    (e) => {
      setIsRiskItems((prevState) => addOrRemove(prevState, e));
    },
    [setIsRiskItems]
  );

  // for csv file
  var headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
  ];

  var data = fillCsvData();

  var csvReport = {
    data: data,
    headers: headers,
    filename: ["Zone7_" + dayjs(props.report_date).format("MMM_DD ") + ".csv"],
  };

  function fillCsvData() {
    const name = [];
    const recommendations = [];
    const categories = [];

    if (props.player_data && props.player_data.length > 0) {
      for (let i = 0; i < props.player_data.length; i++) {
        // iterator over player
        name.push({
          firstName: props.player_data[i].alert.player.first_name,
          lastName: props.player_data[i].alert.player.last_name,
        });
        if (
          props.player_data[i].recommendations &&
          props.player_data[i].recommendations.length > 0
        ) {
          for (
            let j = 0;
            j < props.player_data[i].recommendations.length;
            j++
          ) {
            // iterator over player recommendations
            if (i === 0) {
              // only for the first player we want to update/create the headers
              headers.push({
                label: props.player_data[i].recommendations[j].category,
                key: "category" + j,
              });
            }

            if (
              props.player_data[i].recommendations[j].recommendations &&
              props.player_data[i].recommendations[j].recommendations.length > 0
            ) {
              for (
                let k = 0;
                k <
                props.player_data[i].recommendations[j].recommendations.length;
                k++
              ) {
                // iterator over player recommendations per category
                recommendations[k] =
                  props.player_data[i].recommendations[j].recommendations[k]
                    .key +
                  ": " +
                  props.player_data[i].recommendations[j].recommendations[k]
                    .value;
              }
              categories[j] = {
                ["category" + j]: recommendations
                  .toString()
                  .split(",")
                  .join("\n")
                  .replaceAll("&sup", "^")
                  .replaceAll(";", "")
                  .replaceAll("number of", ""),
              };
              recommendations.length = 0;
            } else {
              categories[j] = { ["category" + j]: "" };
              recommendations.length = 0;
            }
          }
        }
        //merge categories into name
        categories.map((item, index) => Object.assign(name[i], item, index));
      }
    }
    return name;
  }

  return (
    <>
      <div className={styles.panels}>
        <div className={styles.teamReport__head}>
          <div className={styles.teamReport__headWrap}>
            <div className={styles.teamReport__date}>
              {t("report-for")}:{" "}
              {dayjs(props.report_date).format("MMM. DD YYYY")}
            </div>
            <div className={styles.teamReport__next}>
              {t("next-session")}: {props.next_session}
            </div>
          </div>

          {props.team.enable_recommendation_export ? (
            <a
              href={`${host}/core/api/export_recommendations`}
              onClick={() => {
                sendAmplitudeData(`csv file export`, {
                  detail: props.club.name,
                });
              }}
            >
              <button className={styles.teamReport__csvButton}>
              Export Recommendations
              </button>
            </a>
          ) : (
            <CSVLink
              {...csvReport}
              onClick={() => {
                sendAmplitudeData(`csv file export`, {
                  detail: props.club.name,
                });
              }}
            >
              <button className={styles.teamReport__csvButton}>
              Export Recommendations
              </button>
            </CSVLink>
          )}
        </div>
        <div className={styles.block}>
          <TeamRiskFactors {...props} />

          <PlayerSummary {...props} colors={{ ...colors }} />
        </div>
        <div className={styles.players}>
          <div className={styles["players__title"]}>
            {t("Players")}
            {/* <div className={styles.showAll} onClick={() => setShowAll(!showAll)}>
              {!showAll ? t('show-all') : t('hide-all')}
            </div> */}
          </div>
          <div className={styles["players__container"]}>
            {props.player_data &&
              props.player_data.map((el) => {
                let isRiskFactor =
                  isRiskItems.filter((e) => e === el.alert.player.id).length ===
                  1;
                return (
                  <div key={el.alert.player.id}>
                    <div className={styles.playerWrapper}>
                      <div className={`${styles.player} ${styles.panel}`}>
                        <div className={styles.player__details}>
                          <div className={styles.player__wrap}>
                            <div className={styles.player__list}>
                              <div className={styles.player__image}>
                                {el.alert.player.has_image ? (
                                  <img
                                    src={`${host}/core/api/player_image/${el.alert.player.id}/`}
                                  />
                                ) : (
                                  <div
                                    className={`${
                                      styles.player__imageInitials
                                    } ${styles[el.alert.level]}`}
                                  >
                                    {el.alert.player.initials}
                                  </div>
                                )}
                                {el.alert.player
                                    .enable_modified_thresholds && (
                                    <span className={`${styles.modified} ${styles[el.alert.level]}`}></span>
                                  )}
                              </div>
                            </div>
                            <div className={styles.player__cont}>
                              <Link
                                to={`${ROUTES.PLAYER}/${el.alert.player.id}`}
                              >
                                <div className={styles.player__title}>
                                  {`${el.alert.player.first_name} ${el.alert.player.last_name}`}
                                  {/* {el.alert.player
                                    .enable_modified_thresholds && (
                                    <span className={styles.asterisk}>*</span>
                                  )} */}
                                </div>
                                <div
                                  className={`${styles.player__level} ${
                                    styles[el.alert.level]
                                  }`}
                                >
                                  {t(el.alert.level + "-risk")}
                                </div>
                                {el.alert.detected !== "" && (
                                  <div className={styles.player__lastUpdate}>
                                    Detected{" "}
                                    {dayjs(el.alert.detected).fromNow("dd")} ago
                                  </div>
                                )}
                              </Link>
                            </div>
                          </div>
                          {/*<div className={`${styles.player__detected} ${styles[el.alert.level]}`}>*/}
                          {/*    <div className={styles.player__detectedTitle}>*/}
                          {/*        {t('Context')}*/}
                          {/*    </div>*/}
                          {/*    <div className={styles.player__detectedDate} dangerouslySetInnerHTML={{__html: el.risk_context.training_status }}>*/}
                          {/*    </div>*/}
                          {/*</div>*/}
                        </div>
                        <div className={styles.accordionWrap}>
                          <div
                            className={styles.accordion}
                            onClick={() => togglePanel(el.alert.player.id)}
                          >
                            {active.includes(el.alert.player.id) ? (
                              <img src="/static/images/icons/minus.svg" />
                            ) : (
                              <img src="/static/images/icons/plus.svg" />
                            )}
                          </div>
                        </div>
                        <div
                          className={`${styles.risksInfo} ${
                            styles[el.alert.level]
                          } ${
                            active.includes(el.alert.player.id) && styles.active
                          }`}
                        >
                          <div className={styles.risks}>
                            <div className={styles.risks__head}>
                              <div
                                className={styles.riskManagement}
                                onClick={() => toggleRisks(el.alert.player.id)}
                              >
                                <div
                                  className={`${styles.riskManagement__title} ${
                                    !isRiskFactor && styles.show
                                  }`}
                                >
                                  {t("risk-management")}
                                </div>
                              </div>
                              <div
                                className={styles.riskFactors}
                                onClick={() => toggleRisks(el.alert.player.id)}
                              >
                                <div
                                  className={`${styles.riskFactors__title} ${
                                    isRiskFactor && styles.show
                                  }`}
                                >
                                  {t("risk-factors")}
                                </div>
                              </div>
                            </div>
                            <div className={styles.risks__content}>
                              <div
                                className={`${styles.riskManagement__content} ${
                                  !isRiskFactor && styles.show
                                }`}
                              >
                                {el.complete_rest ? (
                                  <div
                                    className={`${styles.riskManagement__recommendations} ${styles.noWorkload}`}
                                  >
                                    {t("complete-rest")}
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      styles.riskManagement__recommendations
                                    }
                                  >
                                    {el.recommendations.map(
                                      (recommendation) => {
                                        return (
                                          <RecommendationCategory
                                            key={recommendation.category}
                                            {...recommendation}
                                            t={t}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                              <div
                                className={`${styles.riskFactors__content} ${
                                  isRiskFactor && styles.show
                                }`}
                              >
                                <div
                                  className={styles.riskFactors__contributions}
                                >
                                  <div className={styles.contributions__name}>
                                    {t("Workload")}
                                  </div>
                                  <div
                                    className={
                                      styles.riskFactors__contributionsWorkload
                                    }
                                  >
                                    {el.contributions.workload &&
                                      el.contributions.workload.map(
                                        (contribution) => {
                                          return (
                                            <div
                                              key={contribution.display_name}
                                              className={
                                                styles.contributions__valueWrapper
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.contributions__value
                                                }
                                              >
                                                <img
                                                  src={`/static/images/icons/${
                                                    teamRiskFactorsNames[
                                                      contribution.group
                                                    ]
                                                  }`}
                                                />
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      contribution.display_name,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                  {el.contributions.non_workload && (
                                    <div className={styles.contributions__name}>
                                      {t("non-workload")}
                                    </div>
                                  )}
                                  {el.contributions.non_workload && (
                                    <div
                                      className={
                                        styles.riskFactors__contributionsNonworkload
                                      }
                                    >
                                      {el.contributions.non_workload.map(
                                        (contribution) => {
                                          return (
                                            <div
                                              key={contribution.name}
                                              className={
                                                styles.contributions__value
                                              }
                                              dangerouslySetInnerHTML={{
                                                __html: contribution.name,
                                              }}
                                            ></div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {el.risk_context.recommended_dates &&
                      active.includes(el.alert.player.id) && (
                        <div className={styles.reference}>
                          <div className={`${styles.reference__title}`}>
                            {t("Reference Sessions")}
                          </div>
                          <div className={styles.reference__dates}>
                            {el.risk_context.recommended_dates.map((date) => {
                              return (
                                <div
                                  key={date.date}
                                  className={`${styles.reference__date}
                                                                             ${
                                                                               styles.panel
                                                                             }
                                                                             ${
                                                                               date.sessions &&
                                                                               date
                                                                                 .sessions
                                                                                 .length >
                                                                                 0 &&
                                                                               date
                                                                                 .sessions[0]
                                                                                 .drills
                                                                                 .length >
                                                                                 0 &&
                                                                               styles.reference__dateActive
                                                                             }`}
                                  onClick={() => {
                                    date.sessions &&
                                      date.sessions.length > 0 &&
                                      date.sessions[0].drills.length > 0 &&
                                      setModalDateItem(date);
                                  }}
                                >
                                  {dayjs(date.date).format("MMMM DD, YYYY")}
                                </div>
                              );
                            })}
                            {!!modalDateItem && (
                              <Modal
                                isSidebarCollapsed={props.isSidebarCollapsed}
                                isOpen={!!modalDateItem}
                                onClose={() => setModalDateItem(null)}
                              >
                                <div className={styles.recommended}>
                                  <div className={styles.recommended__total}>
                                    <div className={styles.recommended__date}>
                                      {dayjs(modalDateItem.date).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </div>
                                    {modalDateItem.sessions &&
                                      modalDateItem.sessions.length > 0 && (
                                        <div
                                          className={styles.recommended__time}
                                        >
                                          <div
                                            className={
                                              styles.recommended__startTime
                                            }
                                          >
                                            {t("start-time") +
                                              ": " +
                                              modalDateItem.sessions[0]
                                                .session_start_time}
                                          </div>
                                          <div
                                            className={
                                              styles.recommended__totalDuration
                                            }
                                          >
                                            {t(
                                              "total-duration-fd747cf7093bd6b5c8eb3b40658a3b13"
                                            ) +
                                              ": " +
                                              modalDateItem.sessions[0]
                                                .session_duration}
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  <div className={styles.recommended__drills}>
                                    <div className={styles.recommended__drill}>
                                      <div className={styles.recommended__name}>
                                        {t("Drill")}
                                      </div>
                                      <div
                                        className={styles.recommended__duration}
                                      >
                                        {t("Duration")}
                                      </div>
                                    </div>
                                    {modalDateItem.sessions &&
                                      modalDateItem.sessions.length > 0 &&
                                      modalDateItem.sessions[0].drills.map(
                                        (drill) => {
                                          return (
                                            <div
                                              key={drill.name}
                                              className={
                                                styles.recommended__drill
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.recommended__name
                                                }
                                              >
                                                {drill.name}
                                              </div>
                                              <div
                                                className={
                                                  styles.recommended__duration
                                                }
                                              >{`${Math.round(
                                                drill.duration
                                              )} ${t("Minutes")}`}</div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </Modal>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

function RecommendationCategory(props) {
  return (
    <div className={styles.recommendationCategory}>
      <div className={styles.recommendationCategory__name}>
        {props.category}
      </div>
      {props.no_workload ? (
        <div
          className={`${styles.recommendationCategory__keys} ${
            props.active && styles.active
          } ${styles.noWorkload}`}
        >
          {props.t("avoid-any-workload-in-this-category")}
        </div>
      ) : (
        <div className={styles.recommendationCategory__keys}>
          {props.recommendations &&
            props.recommendations.map((el) => {
              let key = el.key && el.key[0].toUpperCase() + el.key.substring(1);
              return (
                <div
                  key={key}
                  className={styles.recommendationCategory__keysItem}
                >
                  <div
                    className={styles.recommendationCategory__key}
                    dangerouslySetInnerHTML={{
                      __html: `<div><span>${key}</span></div>
                                         <span className="${styles.recommendationCategory__value}">
                                            ${el.value}
                                         </span>`,
                    }}
                  ></div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
