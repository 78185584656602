import React, { useCallback, useEffect, useState } from 'react';
import API from '../../utils/API';
import Layout from '../../components/layout/layout';
import styles from './contact-us.module.scss';
import Tooltip from '../../components/Tooltip/Tooltip';
import Preloader from '../../components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';
import { HandleError, handleRedirect } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';

export default function ContactUs(props) {
  const [data, setData] = useState(null);
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teamName, setTeamName] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [tooltipData, setTooltipData] = useState(null);

  let history = useHistory();

  const { t, i18n } = useTranslation();

  const toggleTooltip = (inf) => {
    setTooltipData(inf);
  };

  const getNewToken = useCallback(async () => {
    try {
      const response = await API.get(`/refresh_token/`, { withCredentials: true });

      if (response.status === 200) {
        localStorage.setItem('csrftoken', response.data.csrf_token);
      }
    } catch (e) {
      console.log('Error when getting a new token ', e);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.get('/contact/');
      if (response.status === 200) {
        handleRedirect(response, history);
        setData(response.data);
        setLastUpdateDate(response.data.last_update);
        setName(response.data.user.first_name);
        setLastName(response.data.user.last_name);
        setTitle(response.data.user.title);
        setTeamName(response.data.team.name);
        setImage(response.data.club.icon);
        if (props.user === '') {
          props.setUser(response.data.user);
        }
      }
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setLoading(false);
      if (e.response.status === 403) {
        await getNewToken();
      }
      if (e.response && e.response.status) {
        HandleError(e, history);
      }
      console.log('Error fetch contact us data: ', e);
    }
  }, [setData, setLastUpdateDate, setImage, setLoading, setName, setLastName, setTeamName, setTitle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const sendContact = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        setLoading(true);

        let formData = new FormData();

        formData.append('name', e.target.name.value);
        formData.append('lastName', e.target.lastName.value);
        formData.append('team', e.target.team.value);
        formData.append('title', e.target.title.value);
        formData.append('message', e.target.message.value);
        formData.append('csrfmiddlewaretoken', e.target.csrfmiddlewaretoken.value);

        const response = await API.post(`/submit_contact/`, formData);

        if (response.status === 200) {
          setSuccess(true);
        } else {
          //throw new Error();
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response.status === 403) {
          await getNewToken();
        }
        if (e.response && e.response.status) {
          HandleError(e, history);
        }
        console.log('Error post Contact: ', e);
      }
    },
    [setSuccess],
  );

  const textTooltipTitle = t('contact-us');

  return (
    <Layout isSidebarCollapsed={props.isSidebarCollapsed} changeCollapseSidebar={props.changeCollapseSidebar} user={props.user}>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.contactUsContainer}>
          <HeaderComponent isSidebarCollapsed={props.isSidebarCollapsed} title={t('contact-us')} lastUpdateDate={lastUpdateDate} lastUpdateTitle={'last-updated'} image={image} />

          <div className={styles.contact__wrapper}>
            <div className={`${styles.contact} ${styles.panel}`}>
              <div className={styles.contact__wrap}>
                <form method="post" onSubmit={sendContact}>
                  <div className={styles.contact__block1}>
                    <div className={styles.contact__firstName}>
                      <div className={styles.contact__label}>{t('first-name')}</div>
                      <div className={styles.contact__firstNameItem}>
                        <input type="text" name="name" placeholder={t('enter-your') + ' ' + t('first-name').toLowerCase()} defaultValue={name} />
                      </div>
                    </div>
                    <div className={styles.contact__lastName}>
                      <div className={styles.contact__label}>{t('last-name')}</div>
                      <div className={styles.contact__lastNameItem}>
                        <input type="text" name="lastName" placeholder={t('enter-your') + ' ' + t('last-name').toLowerCase()} defaultValue={lastName} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact__block2}>
                    <div className={styles.contact__team}>
                      <div className={styles.contact__label}>{t('Team')}</div>
                      <div className={styles.contact__teamItem}>
                        <input type="text" name="team" placeholder={t('enter-your') + ' ' + t('team')} defaultValue={teamName} />
                      </div>
                    </div>
                    <div className={styles.contact__title}>
                      <div className={styles.contact__label}>{t('Title')}</div>
                      <div className={styles.contact__titleItem}>
                        <input type="text" name="title" placeholder={t('enter-your') + ' ' + t('title')} defaultValue={title} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact__block3}>
                    <div className={styles.contact__message}>
                      <div className={styles.contact__label}>{t('Message')}</div>
                      <div className={styles.contact__messageItem}>
                        <textarea name="message" maxLength="400" value={message} onChange={(ev) => setMessage(ev.target.value)} placeholder={t('enter-your') + ' ' + t('message')} />
                        <p className={styles.contact__messageItem_count}>{message.length}/400</p>
                      </div>
                    </div>
                  </div>
                  {data && data.csrf_token && <input type="hidden" required value={data.csrf_token} name="csrfmiddlewaretoken" />}
                  <button type="submit" className={styles.contact__submit}>
                    <span>{t('Submit')}</span>
                  </button>
                </form>
              </div>
              <div
                className={styles.tooltip}
                onClick={(e) =>
                  toggleTooltip({
                    clientX: e.clientX,
                    clientY: e.clientY,
                    title: textTooltipTitle,
                    content: textTooltipTitle + '.',
                  })
                }
              >
                i
              </div>
              {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
            </div>

            {success && (
              <div className={styles['success__form']}>
                <div onClick={() => setSuccess(false)} className={styles['success__form-back']} />
                <div className={styles['success__wrapper']}>
                  <div className={styles['success__title']}>{t('your-request-has-been-submitted')}</div>
                  <div className={styles['success__text']}>{t('we-will-be-in-touch-asap')}.</div>
                  <div className={styles['success__image-wrapper']}>
                    <img alt="check" src="/static/images/icons/check_circle.svg" />
                  </div>
                  <button
                    onClick={() => {
                      setSuccess(false);
                      setMessage('');
                    }}
                    className={styles['success__close']}
                  >
                    <img src="/static/images/icons/plus.svg" alt="close" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}
