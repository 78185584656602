import React, { useState } from 'react';
import Select from 'react-select';
import styles from './MultiSelect.module.scss';
import { components } from "react-select";


const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


export default function MultiSelect(props) {
  const [selectedOption, setSelectedOption] = useState(null);

  const dropdownChangeHandler = (event) => {
    props.onChangeFilter(event);
    console.log(event);
};

  return (
    <div className={styles.select}>
      <div className={styles.select__label}>{props.label}</div>
      <Select
        isMulti
        defaultValue={selectedOption}
        onChange={dropdownChangeHandler}
        options={props.options}
        placeholder={props.placeholder}
        className='react-select-container'
        classNamePrefix="react-select"
        styles={props.customStyles}
        value={props.selected}
        //closeMenuOnSelect={false}
        //hideSelectedOptions={true}
        //components={{
        //  Option
        //}}
      />
      {/* <div>{selectedOption}</div> */}
    </div>
  );
}