import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from '../../Preloader/Preloader';
import { colors } from '../PlayerInfo/PlayerInfo';
import BioRiskFactors from '../BioRiskFactors/BioRiskFactors';
import { fetchRadarData } from '../../../utils/helpers';

export default function PlayerRadarContainer(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id, date } = useParams();
  let history = useHistory();

  // useEffect(() => {
  //         async function f() {
  //             const radarData = await fetchRadarData(history, setLoading, id, date);
  //             setData(radarData);
  //         }
  //
  //         f();
  //     }
  //     , [history, setLoading, id, date]);

  useEffect(() => {
    function handleEvent(message) {
      setData(message.data);
      setLoading(false);
    }

    setLoading(true);

    window.addEventListener('message', handleEvent);

    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return (
    <>
      <BioRiskFactors {...data} colors={{ ...colors }} background={true} />
    </>
  );
}
