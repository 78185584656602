import React, { useCallback, useEffect, useState, useRef } from 'react';
import css from './HeaderComponent.module.scss';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import API from '../../utils/API';
import FindPlayer from '../FindPlayer/FindPlayer';
import { HandleError, handleRedirect } from '../../utils/helpers';

export default function HeaderComponent(props) {
  const [data, setData] = useState(null);
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);

  let history = useHistory();
  const { t } = useTranslation();
  const clubData = JSON.parse(localStorage.getItem('clubData'));

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.get('/user_data/');
      if (response.status === 200) {
        handleRedirect(response, history);
        setData(response.data);
        //setLastUpdateDate(response.data.last_update);
        setImage(response.data.club.icon);
      }
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status) {
        HandleError(e, history);
      }
      console.log('Error fetch team report data: ', e);
    }
  }, [setData, setLastUpdateDate, setImage, setLoading]);

  useEffect(() => {
    if (clubData) {
      setImage(clubData.club.icon);
      //setClubName(clubData.club.name)
      //setLastUpdateDate(clubData.teams[0].last_update)
    }
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
    // fetchData();
    return () => setLoading(false);
  }, []);

  return (
    <div className={`${css['header']} `}>
      <div className={css['header__menu']}>
        <div className={css['header__title']}>{props.title}</div>

        {/*{props.renderSimulatorBtn && <ButtonSimulator handleSimulator={props.handleSimulator} btnSimulatorChecked={props.btnSimulatorChecked} />}*/}
        
        <div className={css['header__content']}>
        <FindPlayer isSidebarCollapsed={props.isSidebarCollapsed} />
          {props.lastUpdateDate !== '' && (
            <div className={css['header__last-update']}>
              {t(props.lastUpdateTitle)}: {dayjs(props.lastUpdateDate).format('MMMM DD, YYYY')}
            </div>
          )}

         
        </div>
      </div>
      <div className={`${css['header__info']}`}>
        <img className={css['header__info-image']} src={image} alt="info" />
      </div>
    </div>
  );
}
