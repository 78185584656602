import React from 'react';
import TeamRiskFactors from './TeamRiskFactors/TeamRiskFactors';
import PlayerSummary from './PlayerSummary/PlayerSummary';
import DailyPlayersAtRisk from './DailyPlayersAtRisk/DailyPlayersAtRisk';
import RiskHeatmap from './RiskHeatmap/RiskHeatmap';
import styles from './Team.module.scss';

export const colors = {
  white: '#ffffff',
  black32: '#202020',
  black40: '#282828',
  low_count: '#0f98d7',
  low_count_grad: '#708fff',
  contained_count: '#eb7800',
  contained_count_grad: '#bf950c',
  medium: '#e75d0d',
  medium_count: '#e75d0d',
  medium_count_grad: '#eb7800',
  risk_count: '#da2a16',
  risk_count_grad: '#da2a16',
  contained: '#bf950c',
  high: '#da2a16',
  high_count: '#da2a16',
  high_count_grad: '#e03300',
  injured: '#9ea2a8',
  injured_count: '#9ea2a8',
  injured_count_grad: '#9ea2a8',
  [null]: '#0f98d7',
  [null + '_count']: '#0f98d7',
  [null + '_count' + '_grad']: '#0f98d7',
};

export default function Team(props) {
  return (
    <>
      <div className={styles.panels}>
        <div className={styles.block1}>
          <TeamRiskFactors {...props} />
          <PlayerSummary {...props} colors={{ ...colors }} />
        </div>

        <DailyPlayersAtRisk {...props} colors={{ ...colors }} />

        <RiskHeatmap {...props} colors={{ ...colors }} />
      </div>
    </>
  );
}
