import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './assets/locales/en/translation';
import ES from './assets/locales/es/translation';
import dayjs from "dayjs";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
require('dayjs/locale/es');
// //require('dayjs/locale/en');


const resources = {
    en: { translation: EN },
    es: { translation: ES }
};

const userLang = navigator.language.substr(0,2);

let lang = "en";

if (userLang === "es") {
    dayjs.locale('es');
    lang = "es";
    registerLocale('es', es);
    setDefaultLocale('es');
}

i18n
// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
.use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
// pass the i18n instance to react-i18next.
.init({
    resources,
    lng: lang,
    fallbackLng: 'en',
    debug: false,
    //keySeparator: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    }
});

export default i18n;