import Tooltip from '../../Tooltip/Tooltip';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RecommendationCategory from '../../Players/RecommendationCategory/RecommendationCategory';
import styles from './Recommendations.module.scss';
import { sendAmplitudeData } from '../../../utils/amplitude';
import dayjs from 'dayjs';
import Context from '../Context/Context';

export default function Recommendations(props) {
  const [active, setActive] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [selectedButton, setSelectedButton] = useState(props.micro_cycle_recommendations && props.micro_cycle_window && props.micro_cycle_recommendations.length - props.micro_cycle_window);
  const [recommendations, setRecommendations] = useState(
    props.micro_cycle_recommendations ? props.micro_cycle_recommendations[props.micro_cycle_recommendations.length - props.micro_cycle_window].recommendations : props.recommendations,
  );

  const [showedCycles, setShowedCycles] = useState(
    window.matchMedia('(min-width: 767px)').matches ? props.micro_cycle_recommendations && props.micro_cycle_recommendations.map((e, i) => i) : [0, 1, 2],
  );

  const { t } = useTranslation();

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };

  const togglePanel = useCallback(() => {
    setActive(!active);
    if (!active && props.alert) {
      sendAmplitudeData(`player recommendations panel shown`, { detail: `${props.alert.player.first_name} ${props.alert.player.last_name}` });
    }
  }, [active, setActive, props.alert && props.alert.player.first_name, props.alert && props.alert.player.last_name]);

  const changeSelectedButton = useCallback((value) => {
    setSelectedButton(value);
    setRecommendations(props.micro_cycle_recommendations[value] && props.micro_cycle_recommendations[value].recommendations);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (!props.micro_cycle_recommendations) return;
      if (window.innerWidth < 767 && showedCycles.length > 3) {
        setShowedCycles([
          +selectedButton === 0 ? +selectedButton + 2 : +selectedButton - 1,
          +selectedButton,
          +selectedButton === props.micro_cycle_recommendations.length - 1 ? +selectedButton - 2 : +(+selectedButton) + 1,
        ]);
      } else if (window.innerWidth > 767 && showedCycles.length <= 3 && props.micro_cycle_recommendations.length > 3) {
        setShowedCycles(props.micro_cycle_recommendations.map((e, i) => i));
      }
    };

    window.addEventListener('resize', handleResize);
  }, [selectedButton, showedCycles]);

  return (
    <>
      {recommendations && (
        <div className={`${styles.riskManagementMicro} ${styles.panel} ${active && styles.active}`}>
          {props.micro_cycle_recommendations && (
            <div className={styles.riskManagement__head}>
              <div className={styles.riskManagement__title}>
                <span className={styles.riskManagement__titleText}>{`${t('risk-management')} - ${t('Upcoming')} ${props.micro_cycle_window} ${t(props.micro_cycle_window > 1 ? 'Days' : 'Day')}`}</span>
                <span
                  className={`${styles.player__tooltip} ${styles.micro_cycle}`}
                  onClick={(e) =>
                    toggleTooltip({
                      clientX: e.clientX,
                      clientY: e.clientY,
                      title: props.tooltips.recommendations.title,
                      content: props.tooltips.recommendations.text,
                    })
                  }
                >
                  i
                </span>
              </div>
              <div className={styles.riskManagement__radio}>
                {props.micro_cycle_recommendations && (
                  <div className={styles.riskManagement__arrows}>
                    {
                      <div className={styles.riskManagement__radioButtonRect} style={{ visibility: showedCycles.includes(0) ? 'hidden' : 'visible', boxShadow: '3px 0 4px 0 rgba(0,0,0,0.3)' }}>
                        <div
                          className={`${styles.riskManagement__radioButtonArrow} ${styles.riskManagement__radioButtonArrow_left}`}
                          onClick={() => {
                            let newShowedCycles = [...showedCycles];
                            newShowedCycles[newShowedCycles.length - 1] = newShowedCycles[0] - 1 < 0 ? 0 : newShowedCycles[0] - 1;
                            newShowedCycles.sort();
                            setShowedCycles(newShowedCycles);
                          }}
                        >
                          <img alt="arrow" src="/static/images/icons/arrow_right.svg" />
                        </div>
                      </div>
                    }
                    <div className={styles.riskManagement__radioButtons}>
                      {props.micro_cycle_recommendations.map((rec, ind) => {
                        return (
                          showedCycles.includes(ind) && (
                            <div className={styles.riskManagement__radioButton} key={rec.micro_cycle_date}>
                              <label className={`${styles.riskManagement__radioButtonLabel} ${rec.enabled && styles.enabled} ${ind === 0 && styles.riskManagement__radioButtonRiskDetected}`}>
                                <span className={styles.riskManagement__radioButtonLabelText}>{`${dayjs(rec.micro_cycle_date).format('MMMM D')} ${
                                  rec.session_reference ? '- ' + rec.session_reference : ''
                                }`}</span>
                                <input
                                  type="radio"
                                  name="react-tips"
                                  value={ind}
                                  checked={selectedButton == ind}
                                  onChange={(e) => rec.enabled && changeSelectedButton(e.target.value)}
                                  className={styles.riskManagement__radioButtonInput}
                                />
                                <span className={`${styles.riskManagement__radioButtonCheckmark} ${rec.expected_outcome ? styles[rec.expected_outcome.group] : styles.disabled}`}></span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                    {
                      <div
                        className={styles.riskManagement__radioButtonRect}
                        style={{ visibility: showedCycles.includes(props.micro_cycle_recommendations.length - 1) ? 'hidden' : 'visible', boxShadow: '-3px 0 4px 0 rgba(0,0,0,0.3)' }}
                      >
                        <div
                          className={styles.riskManagement__radioButtonArrow}
                          //style={{position: 'absolute', right: '18px'}}
                          onClick={() => {
                            let newShowedCycles = [...showedCycles];
                            newShowedCycles[0] = newShowedCycles[newShowedCycles.length - 1] + 1;
                            newShowedCycles.sort();
                            setShowedCycles(newShowedCycles);
                          }}
                        >
                          <img alt="arrow" src="/static/images/icons/arrow_right.svg" />
                        </div>
                      </div>
                    }
                  </div>
                )}
              </div>
              <div className={styles.riskManagement__riskDetected}>{showedCycles.includes(0) && t('risk-detected')}</div>
            </div>
          )}

          {recommendations && (
            <div className={`${styles.riskManagement}`}>
              <div className={`${styles.riskManagement__head} ${props.micro_cycle_recommendations && styles.micro_cycle}`}>
                {!props.micro_cycle_recommendations && (
                  <div className={styles.riskManagement__title}>
                    <span className={styles.riskManagement__titleText}>{t('risk-management')}</span>
                    <span
                      className={styles.player__tooltip}
                      onClick={(e) =>
                        toggleTooltip({
                          clientX: e.clientX,
                          clientY: e.clientY,
                          title: props.tooltips.recommendations.title,
                          content: props.tooltips.recommendations.text,
                        })
                      }
                    >
                      i
                    </span>
                  </div>
                )}
                {props.micro_cycle_recommendations &&
                  props.micro_cycle_recommendations[selectedButton].recommended_dates &&
                  props.micro_cycle_recommendations[selectedButton].recommended_dates.length > 0 && (
                    <Context recommended_dates={props.micro_cycle_recommendations[selectedButton].recommended_dates} isSidebarCollapsed={true} micro_cycle={true} />
                  )}
                {!props.micro_cycle_recommendations && props.expected_outcomes && props.expected_outcomes.no_adjustments.group !== null && props.expected_outcomes.with_adjustments.group !== null && (
                  <div className={styles.riskManagement__prediction}>
                    <div className={styles.riskManagement__predictionTitle}>{t('expected-outcome-of-modification')}</div>
                    <div className={styles.riskManagement__predictionBody}>
                      <div className={styles.riskManagement__predictionOutcome}>
                        {/* <div className={styles.riskManagement__predictionOutcomeTitle}>{t('intervention-expected-outcome')}</div> */}
                        <div className={styles.riskManagement__predictionButtons}>
                          {/* <div
                            className={`${styles.riskManagement__predictionButton} ${styles.riskManagement__predictionButtonCurrent} ${
                              props.alert.level === 'contained' ? styles.contained : styles.high
                            }`}
                          >
                            <div>{`${props.alert.level === 'contained' ? t('contained') : t('Risk')}`}</div>
                          </div>
                          <div className={styles.riskManagement__predictionArrow}>
                            <img alt="arrow" src="/static/images/icons/arrow_right.svg" />
                          </div> */}
                          <div className={styles.riskManagement__predictionOutcomeTitle}>
                            {t('forecasted-risk-with-typical')} [{t(props.session_reference)}] {`${props.session_reference === 'MD' ? t('Match') : t('Session')}`}
                          </div>
                          <div className={`${styles.riskManagement__predictionButton} ${styles.riskManagement__predictionButtonExpected} ${styles[props.expected_outcomes.no_adjustments.group]}`}>
                            <div>{t(props.expected_outcomes.no_adjustments.text)}</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.riskManagement__predictionOutcome}>
                        {/* <div className={styles.riskManagement__predictionOutcomeTitle}>{t('no-intervention-expected-outcome')}</div> */}
                        <div className={styles.riskManagement__predictionButtons}>
                          {/* <div
                            className={`${styles.riskManagement__predictionButton} ${styles.riskManagement__predictionButtonCurrent} ${
                              props.alert.level === 'contained' ? styles.contained : styles.high
                            }`}
                          > 
                            <div>{`${props.alert.level === 'contained' ? t('contained') : t('Risk')}`}</div>
                          </div>
                          <div className={styles.riskManagement__predictionArrow}>
                            <img alt="arrow" src="/static/images/icons/arrow_right.svg" />
                          </div> */}
                          <div className={styles.riskManagement__predictionOutcomeTitle}>{
                            t('forecasted-risk-with-modified')} [{t(props.session_reference)}] {`${props.session_reference === 'MD' ? t('Match') : t('Session')}`}
                          </div>
                          <div className={`${styles.riskManagement__predictionButton} ${styles.riskManagement__predictionButtonExpected} ${styles[props.expected_outcomes.with_adjustments.group]}`}>
                            <div>{t(props.expected_outcomes.with_adjustments.text)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {props.complete_rest || (props.micro_cycle_recommendations && props.micro_cycle_recommendations[selectedButton].complete_rest) ? (
                <div className={`${styles.riskManagement__recommendations} ${styles.riskManagement__completeRest}`}>{t('complete-rest')}</div>
              ) : (
                <div
                  className={`${styles.riskManagement__recommendations} ${recommendations !== undefined && recommendations.length > 0 && styles.showRecommendations}
                    ${
                      !props.micro_cycle_recommendations && props.expected_outcomes &&
                      props.expected_outcomes.no_adjustments.group !== null &&
                      props.expected_outcomes.with_adjustments.group !== null &&
                      (recommendations.length > 3 ? styles.alignBottom : styles.alignTop)
                    }`}
                >
                  {recommendations.map((el) => {
                    return <RecommendationCategory key={el.category} {...el} active={active} nextSessionShort={props.next_session_short} />;
                  })}
                </div>
              )}
              <div className={`${styles.accordion} ${props.micro_cycle_recommendations && styles.micro_cycle}`} onClick={() => togglePanel()}>
                <div className={styles.accordion__arrow}>
                  {active ? <img className={styles.accordion__arrow__up} alt="up" src="/static/images/icons/arrow.svg" /> : <img className={styles.accordion__arrow__down} alt="down" src="/static/images/icons/arrow.svg" />}</div>
              </div>
            </div>
          )}
        </div>
      )}
      {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
    </>
  );
}
