import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import styles from './PlayerEditInjury.module.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import API from '../../../utils/API';
import PlayerInjuryErrorModal from '../../PlayerInjuryErrorModal';
import MicroCycleSimulator from '../MicroCycleSimulator/MicroCycleSimulator';
import 'react-datepicker/dist/react-datepicker.css';

export default function PlayerEditInjury({ data, selectDate, ...props }) {
  const refSimulation = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowTextarea, setIsShowTextarea] = useState(false);
  const [isSideDropdown, setIsSideDropdown] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [isTypeDropdown, setIsTypeDropdown] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState(110);
  const [isSettingDropdown, setIsSettingDropdown] = useState(false);
  const [isContactDropdown, setIsContactDropdown] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isReturnDateOpen, setIsReturnDateOpen] = useState(false);
  const [players, setPlayers] = useState({});
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorList, setErrorList] = useState('');
  const [error, setError] = useState([]);
  const dateFormat = props.club?.date_format === 'M-D-Y' ? 'MMMM do yyyy' : 'dd/MM/yyyy';


  const { t } = useTranslation();
  const csrf_token = localStorage.getItem('csrftoken');

  const getCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setIsShowTextarea(false);
    setIsSideDropdown(false);
    setIsTypeDropdown(false);
    setIsReturnDateOpen(false);
    setIsDateOpen(false);
    setIsContactDropdown(false);
    setIsSettingDropdown(false);
    setIsShowTextarea(false);
    setIsEdit(false);
    setErrorModalOpen(false);
    setErrorList('');
    setError([]);
  }, []);

  const fetchPlayers = useCallback(async () => {
    try {
      const response = await API.get(`/injuries/?page=${1}`, { withCredentials: true });
      if (response.status === 200) {
        setPlayers(response.data);
      }
    } catch (e) {
      console.log('Error with Injuries fetching players in team', e);
    }
  }, []);

  const getNewToken = useCallback(async () => {
    try {
      const response = await API.get(`/refresh_token/`, { withCredentials: true });

      if (response.status === 200) {
        localStorage.setItem('csrftoken', response.data.csrf_token);
      }
    } catch (e) {
      console.log('Error when getting a new token ', e);
    }
  }, []);

  const submitInjury = useCallback(
    async (submitPlayerInput) => {
      const getOwnPropertyNames = Object.getOwnPropertyNames(submitPlayerInput);
      const empty = [];
      getOwnPropertyNames.forEach((key) => {
        if (!submitPlayerInput[key] && !['description', 'return_date', 'id', 'side', 'is_contact', 'match_or_training'].includes(key)) {
          empty.push(key);
        }
      });
      if (!empty.length) {
        let formData = new FormData();
        [submitPlayerInput].forEach((player) => {
          Object.entries(player).forEach((item) => {
            if (item[0] === 'date' || item[0] === 'return_date') {
              item[1] && formData.append(item[0], dayjs(item[1]).format('YYYY-MM-DD'));
            } else {
              item[1] && formData.append(item[0], item[1]);
            }
          });
        });
        const options = {
          withCredentials: true,
          headers: { 'X-CSRFToken': csrf_token },
        };
        try {
          const response = await API.post(`/submit_injury/`, formData, options);
          if (response.status === 200) {
            console.log(response.data);
          }
        } catch (e) {
          if (e.response.status === 403) {
            await getNewToken();
          }
          console.log('Error with submit injury players in team', e);
        } finally {
          getCloseModal();
          window.location.reload();
        }
      } else {
        setErrorModalOpen(true);
        setError(empty);
        setErrorList(
          empty
            .map((item) => {
              const mass = item.split('_');
              if (mass.length < 2) {
                return mass[0];
              } else {
                if (mass[0].length > mass[1].length) {
                  return mass[0];
                } else {
                  return mass[1];
                }
              }
            })
            .join(', '),
        );
      }
    },
    [csrf_token, selectedPlayer],
  );

  useEffect(() => {
    // fetchPlayers();
    if (props.player && props.player.injured) {
      if (props.injury) {
        const obj = {};
        Object.entries(props.injury).forEach(([key, value]) => {
          !props.injury[key] ? (obj[key] = undefined) : (obj[key] = value);
        });
        setSelectedPlayer(obj);
      }
    }
  }, []);

  useEffect(() => {
    setIsEdit(!!props.injury);
  }, [props]);

  useEffect(() => {
    if (!props?.player?.injured) {
      setSelectedPlayer({});
    } else {
      if (props.injury) {
        if (!isEdit) {
          const obj = {};
          Object.entries(props.injury).forEach(([key, value]) => {
            !props.injury[key] ? (obj[key] = undefined) : (obj[key] = value);
          });
          setSelectedPlayer(obj);
        }
      }
    }
  }, [isModalOpen]);

  const today = new Date();
  const date = new Date(selectedPlayer?.date);
  const tomorrow = selectedPlayer?.date ? new Date(date.getTime() + 24 * 60 * 60 * 1000) : new Date(today.getTime() + 24 * 60 * 60 * 1000);

  return (
    <Fragment>
      {props.isSidebarCollapsed && props.simulator && <MicroCycleSimulator {...props} isSidebarCollapsed={props.isSidebarCollapsed} refSimulation={refSimulation} />}
      {!!(props && props.club) && (
        <div className={styles.edit_injury_block} ref={refSimulation}>
          <div className={styles.edit_injury__line} />
          <button
            className={`${styles.injury_modal__button} ${styles.injury_modal__button_openModal}`}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {props?.player?.injured ? t('Edit Injury') : t('Report New Injury')}
          </button>
        </div>
      )}
      {isModalOpen && (
        <Modal isSidebarCollapsed={props.isSidebarCollapsed} isOpen={isModalOpen} maxWidth={577} minHeight={628} onClose={getCloseModal} notBackgroundClose={true}>
          <div className={styles.injury_modal}>
            <div className={styles.injury_modal__title}>{props?.player?.injured ? t('Edit injury') : t('Add injury')}</div>
            <div className={styles.injury_modal__fields_group}>
              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                  {t('Player')}
                </span>
                <div className={`${styles.injury_modal__select_group}`}>
                  <div className={`${styles.injury_modal__select}`}>
                    <button className={`${styles.dropdown__button} ${styles.dropdown__button_long}`} onClick={() => setIsEdit(!isEdit)} style={{ cursor: 'auto' }}>
                      {props && props.player && `${props.player.first_name} ${props.player.last_name}`}
                    </button>
                  </div>
                </div>
              </div>

              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                  {t('Date')}
                </span>
                <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                  <div className={styles.injury_modal__select} style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.png)` }}>
                    {(!selectedPlayer.hasOwnProperty('date') || !selectedPlayer.date) && (
                      <span className={`${styles.dropdown__button_line__datepicker} ${error.includes('type') ? styles.error : ''}`} />
                    )}
                    <DatePicker
                      className={`${styles.injury_modal__select} ${styles.injury_modal__select_datepicker}`}
                      dateFormat={dateFormat}
                      selected={selectedPlayer.date && dayjs(selectedPlayer.date).toDate()}
                      onCalendarOpen={() => setIsDateOpen(!isDateOpen)}
                      onCalendarClose={() => setIsDateOpen(!isDateOpen)}
                      onChange={(date) => setSelectedPlayer({ ...selectedPlayer, date: date, return_date: undefined })}
                      maxDate={dayjs().toDate()}
                      showDisabledMonthNavigation
                    />
                  </div>
                </div>
              </div>

              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name}>{t('Type')}</span>

                <div
                  className={`
                                    ${styles.injury_modal__select_group} 
                                    ${isTypeDropdown && styles['dropdown__button--active']}`}
                >
                  <div
                    className={`
                                            ${styles.injury_modal__select}
                                            ${styles.dropdown__button} 
                                            ${styles.dropdown__button_short}
                                            ${isTypeDropdown && styles['dropdown__button--active']}
                                         `}
                    style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.png)` }}
                    onClick={() => setIsTypeDropdown(!isTypeDropdown)}
                  >
                    {!selectedPlayer.hasOwnProperty('type') || !selectedPlayer.type ? (
                      <span className={`${styles.dropdown__button_line__datepicker} ${error.includes('type') ? styles.error : ''}`} style={{ top: '10px', left: '-30px' }} />
                    ) : (
                      <span>{selectedPlayer?.type}</span>
                    )}
                    <div className={`${styles.close_button}`} onClick={() => setIsTypeDropdown(!isTypeDropdown)} />

                    <div className={`${styles.injury_modal__popup_substrate} ${isTypeDropdown ? styles.block : styles.none}`} onClick={() => setIsTypeDropdown(false)} />

                    <ul
                      className={`${styles.dropdown__list} ${isTypeDropdown ? styles['dropdown__list--visible'] : ''}`}
                      style={{ maxHeight: '350px', backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` }}
                    >
                      {data &&
                        data.injury_values &&
                        data.injury_values.types &&
                        data.injury_values.types.map((injuryType) => {
                          return (
                            <li
                              className={styles['dropdown__list-item']}
                              value={injuryType?.value}
                              key={injuryType?.name + injuryType?.value}
                              onClick={() => {
                                setSelectedPlayer({
                                  ...selectedPlayer,
                                  type: injuryType.value,
                                });
                                setIsTypeDropdown(false);
                              }}
                            >
                              {injuryType.name}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name}>{t('Side')}</span>

                <div
                  className={`
                                    ${styles.injury_modal__select_group} 
                                    ${isSideDropdown && styles['dropdown__button--active']}
                                    `}
                >
                  <div
                    className={`
                                            ${styles.injury_modal__select} 
                                            ${styles.dropdown__button} 
                                            ${styles.dropdown__button_short}
                                            ${isSideDropdown && styles['dropdown__button--active']}`}
                    style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.png)` }}
                    onClick={() => setIsSideDropdown(!isSideDropdown)}
                  >
                    {!selectedPlayer.hasOwnProperty('side') || !selectedPlayer.side ? (
                      <span className={`${styles.dropdown__button_line__datepicker} ${error.includes('side') ? styles.error : ''}`} style={{ top: '10px', left: '-30px' }} />
                    ) : (
                      <span>{selectedPlayer?.side}</span>
                    )}
                    <div className={`${styles.close_button}`} onClick={() => setIsSideDropdown(!isSideDropdown)} />
                    <div className={`${styles.injury_modal__popup_substrate} ${isSideDropdown ? styles.block : styles.none}`} onClick={() => setIsSideDropdown(false)} />
                    <ul
                      className={`${styles.dropdown__list} ${isSideDropdown && styles['dropdown__list--visible']}`}
                      style={{ maxHeight: '350px', backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` }}
                    >
                      {data &&
                        data.injury_values &&
                        data.injury_values.sides &&
                        data.injury_values.sides.map((injurySide) => {
                          return (
                            <li
                              className={styles['dropdown__list-item']}
                              value={injurySide?.value}
                              key={injurySide?.name + injurySide?.value}
                              onClick={() => {
                                setSelectedPlayer({
                                  ...selectedPlayer,
                                  side: injurySide?.value,
                                });
                                setIsSideDropdown(false);
                              }}
                            >
                              {injurySide?.name}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                  {t('Setting')}
                </span>

                <div className={`${styles.injury_modal__select_group} ${isSettingDropdown && styles['dropdown__button--active']}`}>
                  <div
                    className={`
                                    ${styles.injury_modal__select}
                                    ${styles.dropdown__button}
                                    ${styles.dropdown__button_short}
                                    ${isSettingDropdown && styles['dropdown__button--active']}
                                    `}
                    style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.png)` }}
                    onClick={() => setIsSettingDropdown(!isSettingDropdown)}
                  >
                    {!selectedPlayer.hasOwnProperty('match_or_training') || !selectedPlayer.match_or_training ? (
                      <span className={`${styles.dropdown__button_line__datepicker} ${error.includes('Setting') ? styles.error : ''}`} style={{ top: '10px', left: '-30px' }} />
                    ) : (
                      <span>{selectedPlayer?.match_or_training}</span>
                    )}
                    <div className={styles.close_button} onClick={() => setIsSettingDropdown(!isSettingDropdown)} />

                    <div className={`${styles.injury_modal__popup_substrate} ${isSettingDropdown ? styles.block : styles.none}`} onClick={() => setIsSettingDropdown(false)} />

                    <ul
                      className={`${styles.dropdown__list} ${isSettingDropdown && styles['dropdown__list--visible']}`}
                      style={{ maxHeight: '350px', backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` }}
                    >
                      <li
                        className={styles['dropdown__list-item']}
                        onClick={() => {
                          setSelectedPlayer({ ...selectedPlayer, match_or_training: 'match' });
                          setIsSettingDropdown(false);
                        }}
                      >
                        {t('Match')}
                      </li>
                      <li
                        className={styles['dropdown__list-item']}
                        onClick={() => {
                          setSelectedPlayer({
                            ...selectedPlayer,
                            match_or_training: 'training',
                          });
                          setIsSettingDropdown(false);
                        }}
                      >
                        {t('Training')}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                  {t('Contact')}
                </span>
                <div className={`${styles.injury_modal__select_group} ${isContactDropdown && styles['dropdown__button--active']}`}>
                  <div
                    className={`${styles.injury_modal__select} ${styles.dropdown__button} ${styles.dropdown__button_short} ${isContactDropdown && styles['dropdown__button--active']}`}
                    style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.png)` }}
                    onClick={() => {
                      setIsContactDropdown(!isContactDropdown);
                      setIsSideDropdown(false);
                      setIsSettingDropdown(false);
                    }}
                  >
                    {!selectedPlayer.hasOwnProperty('is_contact') || !selectedPlayer.is_contact ? (
                      <span className={`${styles.dropdown__button_line__datepicker} ${error.includes('is_contact') ? styles.error : ''}`} style={{ top: '10px', left: '-30px' }} />
                    ) : (
                      <span>{selectedPlayer?.is_contact}</span>
                    )}
                    <div className={styles.close_button} onClick={() => setIsContactDropdown(!isContactDropdown)} />

                    <div className={`${styles.injury_modal__popup_substrate} ${isContactDropdown ? styles.block : styles.none}`} onClick={() => setIsContactDropdown(false)} />

                    <ul
                      className={`${styles.dropdown__list} ${isContactDropdown && styles['dropdown__list--visible']}`}
                      style={{ maxHeight: '350px', backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` }}
                    >
                      <li
                        className={styles['dropdown__list-item']}
                        onClick={() => {
                          setSelectedPlayer({ ...selectedPlayer, is_contact: 'yes' });
                          setIsContactDropdown(false);
                        }}
                      >
                        {t('Yes')}
                      </li>
                      <li
                        className={styles['dropdown__list-item']}
                        onClick={() => {
                          setSelectedPlayer({ ...selectedPlayer, is_contact: 'no' });
                          setIsContactDropdown(false);
                        }}
                      >
                        {t('No')}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                  {t('Description')}
                </span>

                <div className={`${styles.injury_modal__popup_substrate} ${isShowTextarea ? styles.block : styles.none}`} onClick={() => setIsShowTextarea(false)} />
                <div className={`${styles.injury_modal__select_group} ${styles.dropdown__button_long__description}`}>
                  <div
                    className={`${styles.injury_modal__select} ${styles.injury_modal__select_description}`}
                    style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.png)`, position: 'relative' }}
                    onClick={() => {
                      setIsShowTextarea(true);
                      setIsTypeDropdown(false);
                      setIsSideDropdown(false);
                      setIsSettingDropdown(false);
                      setIsContactDropdown(false);
                    }}
                  >
                    {selectedPlayer?.description && selectedPlayer?.description?.length > 0
                      ? selectedPlayer?.description?.length < 46
                        ? selectedPlayer?.description
                        : `${selectedPlayer?.description.substr(0, 40)}...`
                      : ''}
                  </div>
                  {isShowTextarea && (
                    <div style={{ position: 'absolute', top: '-1px', left: '10px', width: '300px' }}>
                      {/*<div*/}
                      {/*    className={styles.injury_modal__select_textarea__button}*/}
                      {/*    style={{top: textareaHeight === 110 ? '85px' : '270px'}}*/}
                      {/*    // onClick={() => {*/}
                      {/*    //     if (textareaHeight === 110) {*/}
                      {/*    //         setTextareaHeight(305);*/}
                      {/*    //     } else if (textareaHeight === 305) {*/}
                      {/*    //         setTextareaHeight(110);*/}
                      {/*    //     } else {*/}
                      {/*    //         setTextareaHeight(110);*/}
                      {/*    //     }*/}
                      {/*    // }}*/}
                      {/*>*/}
                      {/*<span*/}
                      {/*    className={styles.injury_modal__select_textarea__button_top}*/}
                      {/*    style={{backgroundImage: `url(/static/images/icons/description-arrow.svg)`}}*/}
                      {/*/>*/}
                      {/*<span*/}
                      {/*    className={styles.injury_modal__select_textarea__button_bottom}*/}
                      {/*    style={{backgroundImage: `url(/static/images/icons/description-arrow.svg)`}}*/}
                      {/*/>*/}
                      {/*</div>*/}

                      <button
                        className={styles.injury_modal__select_textarea__button_close}
                        style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` }}
                        onClick={() => {
                          setIsShowTextarea(false);
                        }}
                      />

                      <textarea
                        className={styles.injury_modal__select_textarea}
                        placeholder={t('Type Description')}
                        style={{ height: `${textareaHeight}px` }}
                        value={selectedPlayer?.description}
                        onChange={(event) => {
                          setSelectedPlayer({ ...selectedPlayer, description: event.target.value });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.injury_modal__fields_item}>
                <span className={styles.injury_modal__fields_name}>{t('Return Date')}</span>
                <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                  <div className={styles.injury_modal__select} style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.png)` }}>
                    {!selectedPlayer.hasOwnProperty('return_date') && <span className={`${styles.dropdown__button_line__datepicker}`} />}
                    <DatePicker
                      className={`${styles.injury_modal__select} ${styles.injury_modal__select_datepicker}`}
                      dateFormat={dateFormat}
                      selected={selectedPlayer.return_date && dayjs(selectedPlayer.return_date).toDate()}
                      onCalendarOpen={() => {
                        setIsReturnDateOpen(!isReturnDateOpen);
                        setIsSideDropdown(false);
                        setIsSettingDropdown(false);
                        setIsContactDropdown(false);
                      }}
                      onCalendarClose={() => setIsReturnDateOpen(!isReturnDateOpen)}
                      onChange={(date) => {
                        setSelectedPlayer({
                          ...selectedPlayer,
                          return_date: date,
                        });
                      }}
                      minDate={tomorrow}
                      showDisabledMonthNavigation
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.injury_modal__buttons_block}>
              <div className={styles.injury_modal__buttons}>
                <button className={`${styles.injury_modal__button} ${styles.injury_modal__button_inModal}`} onClick={() => getCloseModal()}>
                  {t('Cancel')}
                </button>
                <button
                  className={`${styles.injury_modal__button} ${styles.injury_modal__button_inModal}`}
                  onClick={() => {
                    // console.log(data?.last_update);
                    // if (dayjs(data?.last_update).isSame(selectDate) || dayjs(data?.last_update).isBefore(selectDate)) {
                    const injuriesData = {
                      date: selectedPlayer?.date,
                      side: selectedPlayer?.side,
                      type: selectedPlayer?.type,
                      player_id: props?.player?.id,
                      is_contact: selectedPlayer?.is_contact,
                      description: selectedPlayer?.description ? selectedPlayer?.description : '',
                      return_date: selectedPlayer?.return_date,
                      match_or_training: selectedPlayer?.match_or_training,
                      id: selectedPlayer?.id ? selectedPlayer?.id : null,
                    };

                    selectedPlayer.player &&
                      setPlayers({
                        ...players,
                        injuries: !isEdit
                          ? [
                              ...players.injuries,
                              {
                                ...injuriesData,
                                player: selectedPlayer?.player,
                                id: null,
                              },
                            ]
                          : [
                              ...players.injuries.filter((player) => player.id !== selectedPlayer?.id),
                              {
                                ...injuriesData,
                                player: selectedPlayer?.player,
                                id: selectedPlayer?.injury_id,
                              },
                            ],
                      });
                    submitInjury(injuriesData);
                    // }
                  }}
                >
                  {t('Complete')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <PlayerInjuryErrorModal isSidebarCollapsed={props.isSidebarCollapsed} errorModalOpen={errorModalOpen} errorList={errorList} close={() => setErrorModalOpen(false)} />
    </Fragment>
  );
}
