import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import API from '../../../utils/API';
import Layout from '../../../components/layout/layout';
import PlayerInfo from '../../../components/Player/PlayerInfo/PlayerInfo';
import styles from './player.module.scss';
import Preloader from '../../../components/Preloader/Preloader';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../utils/constants';
import { HandleError, handleRedirect } from '../../../utils/helpers';
import { useHistory } from 'react-router-dom';
import HeaderComponent from '../../../components/HeaderComponent/HeaderComponent';

export default function Player(props) {
  const router = useParams();
  const playerId = router.id;
  const playerDate = router.date;

  const [data, setData] = useState(null);
  const [lastUpdate, setLastUpdate] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateView, setDateView] = useState(playerDate ? new Date(playerDate + 'T12:00:00') : null);
  const [btnSimulatorChecked, setBtnSimulatorChecked] = useState(false);

  const handleSimulator = useCallback(() => {
    setBtnSimulatorChecked(!btnSimulatorChecked);
  }, [setBtnSimulatorChecked, btnSimulatorChecked]);

  let history = useHistory();

  const { t, i18n } = useTranslation();

  const fetchData = useCallback(
    async (id, newDate) => {
      try {
        setLoading(true);
        const dateParameter = newDate ? '?date=' + dayjs(newDate).format('YYYY-MM-DD') : '';
        const response = await API.get('/player/' + id + '/' + dateParameter);
        if (response.status === 200) {
          handleRedirect(response, history);

          setData(response.data);
          setLastUpdate(response.data.last_update);
          setImage(response.data.club.icon);
          if (props.user === '') {
            props.setUser(response.data.user);
          }
        }
        setLoading(false);
        window.scrollTo(0, 0);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.status) {
          HandleError(e, history);
        }
        console.log('Error fetch player data: ', e);
      }
    },
    [setData, setLastUpdate, setImage, setLoading],
  );

  const viewDate = useCallback(
    async (newDate) => {
      history.push(`${ROUTES.PLAYER}/${playerId}/${dayjs(newDate).format('YYYY-MM-DD')}`);
      //fetchData(playerId, newDate);
    },
    [playerId],
  );

  const handleDate = useCallback(
    async (el) => {
      setDateView(el);
      //window.history.replaceState({}, "", `${ROUTES.PLAYER}/${playerId}/${dayjs(el).format('YYYY-MM-DD')}` );
    },
    [setDateView, playerId],
  );

  useEffect(() => {
    playerId && fetchData(playerId, dateView);
  }, [fetchData, playerId, history.location]);

  return (
    <Layout isSidebarCollapsed={props.isSidebarCollapsed} changeCollapseSidebar={props.changeCollapseSidebar} user={props.user}>
      {/*{data &&*/}
      {/*<Head>*/}
      {/*    <title>{`${data.player.first_name} ${data.player.last_name}`}</title>*/}
      {/*    <meta name="viewport" content="initial-scale=1.0, width=device-width"/>*/}
      {/*</Head>}*/}
      {loading ? (
        <Preloader />
      ) : (
        data && (
          <div className={styles.playerContainer}>
            <HeaderComponent
              isSidebarCollapsed={props.isSidebarCollapsed}
              title={`${data.player.first_name} ${data.player.last_name}`}
              image={image}
              lastUpdateTitle={dayjs(lastUpdate).diff(dayjs(dateView), 'h') > 0 ? 'currently-displayed' : 'last-updated'}
              lastUpdateDate={dayjs(lastUpdate).diff(dayjs(dateView), 'h') > 0 ? dateView : lastUpdate}
              renderSimulatorBtn={!!data.simulator}
              handleSimulator={handleSimulator}
              btnSimulatorChecked={btnSimulatorChecked}
            />

            <PlayerInfo {...data} viewDate={viewDate} handleDate={handleDate} date={dateView} isSidebarCollapsed={props.isSidebarCollapsed} btnSimulatorChecked={btnSimulatorChecked} />
          </div>
        )
      )}
    </Layout>
  );
}
