import styles from './Modal.module.scss';

export default function Modal(props) {
  return (
    <>
      {props.isOpen && (
        <div
          className={`${styles.modalOverlay} ${!props.isSidebarCollapsed ? styles.modalOverlayCollapsed : ''} ${props.isBlackModal ? styles.modalOverlayUpper : ''}`}
          onClick={() => {
            !props.notBackgroundClose && props.onClose();
          }}
        >
          <div
            className={`${styles.modalBody} ${props.isBlackModal && styles.modalBodyBlack} ${props.isBlackModal ? styles.modalBodyBlack : ''}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ maxWidth: props.maxWidth || 1000, minHeight: props.minHeight || 270 }}
          >
            <div className={styles.modalClose} onClick={props.onClose} style={{ display: props.display || 'flex' }}>
              <img src="/static/images/icons/plus.svg" alt="close" />
            </div>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}