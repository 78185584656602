import styles from './Tooltip.module.scss';

export default function Tooltip(props) {
  let clientX = props.clientX - 276 > 20 ? props.clientX - 276 : 20;
  return (
    <div className={styles.tooltipOverlay} onClick={props.toggleTooltip}>
      <div className={styles.tooltip} style={{ left: window.matchMedia('(min-width: 768px)').matches ? clientX - 20 : clientX - 50, top: props.clientY + 20 }}>
        <div className={styles.tooltip__title}>{props.title}</div>
        <div className={styles.tooltip__close} onClick={props.toggleTooltip}>
          <img src="/static/images/icons/plus.svg" alt="close" />
        </div>
        <div className={styles.tooltip__content} dangerouslySetInnerHTML={{ __html: props.content }}></div>
      </div>
    </div>
  );
}
