import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import API, { host } from '../../utils/API';
import styles from './UserDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../utils/constants';

export default function UserDropdown(props) {
  let history = useHistory();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const logout = useCallback(async () => {
    try {
      API.defaults.withCredentials = true;

      const dataLogout = await API.get('/logout/', { withCredentials: true });
      if (dataLogout.status === 200) {
        if (dataLogout.data.result === 'ok') {
          props.changeCollapseSidebar(true);
          localStorage.removeItem('csrftoken');
          localStorage.removeItem('userPersonalData');
          localStorage.removeItem('clubData');
          history.push(ROUTES.LOGIN);
        }
      }
    } catch (e) {
      console.log('Error logout: ', e);
    }
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <div className={styles.dropdownOverlay} onClick={props.changeIsUserDropdown}>
      <div className={styles.dropdown}>
        <div className={styles.dropdown__item}>
          {/* <a href={`${host}/change_password`}>{t('change-password')}</a> */}
          <Link to={ROUTES.CONTACT_US}>
            <img src="/static/images/icons/help.svg" alt="help" />
            <span>{t('Contact-Us')}</span>
          </Link>
        </div>
        <div className={styles.dropdown__item}>
          <a href={`${host}/change_password`}>
            <img src="/static/images/icons/settings.svg" alt="change-password" />
            <span>{t('change-password')}</span>
          </a>
        </div>
        <div className={styles.dropdown__item} onClick={logout}>
          <Link to={ROUTES.LOGIN}>
            <img src="/static/images/icons/logout.svg" alt="logout" />
            <span>{t('Logout')}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
