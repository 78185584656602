import React, { useCallback, useEffect, useState } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import styles from './HistoryComponent.module.scss';
import { host } from '../../utils/API';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { ROUTES } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function HistoryComponent(props) {
  const [tooltipData, setTooltipData] = useState(null);
  const [active, setActive] = useState([]);
  const [dateFormat, setDateFormat] = useState('dd/MM/yyyy');
  const { t, i18n } = useTranslation();

  const textTooltipTitle = t('History');

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };
  const addOrRemove = useCallback((arr, item) => (arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]));

  const togglePanel = useCallback(
    (id) => {
      setActive(addOrRemove(active, id));
    },
    [active, setActive],
  );

  useEffect(() => {
    if (props.club) {
      setDateFormat(props.club.date_format === 'M-D-Y' ? 'MM/dd/yyyy' : 'dd/MM/yyyy');
    }
  }, [props.club]);

  return (
    <>
      <div className={styles.panels}>
        <div className={`${styles.history} ${styles.panel}`}>
          <div className={styles.historyDate}>
            <div className={styles.select__wrap}>
              <div className={styles.select__dateLabel}>{t('select-date')}</div>
            </div>
            <div className={styles.select}>
              <div className={styles.select__date}>
                <DatePicker
                  className="datePicker"
                  popperClassName="datePickerPopper"
                  dateFormat={dateFormat}
                  maxDate={new Date(props.last_update)}
                  selected={new Date(props.date)}
                  onChange={(date) => props.handleDate(date)}
                />
              </div>
              <div className={styles.select__dateView} onClick={() => props.viewDate()}>
                {t('view-date')}
              </div>
            </div>
          </div>
        </div>
        {props.start_date && (
          <div className={styles.playerData}>
            <div className={styles.playerData__date}>{dayjs(props.start_date).format('dddd, MMMM DD, YYYY')}</div>
            <div className={styles.playerSummary__items}>
              {props.player_data &&
                props.player_data.map((el) => {
                  return (
                    <div key={el.alert.player.id} className={`${styles.player} ${styles.panel}`}>
                      <div className={styles.playerWrapper}>
                        <div className={styles.player__details}>
                          <Link className={styles.player__wrap} to={`${ROUTES.PLAYER}/${el.alert.player.id}/${dayjs(props.start_date).format('YYYY-MM-DD')}`}>
                            <div className={styles.player__list}>
                              <div className={styles.player__image}>
                                {el.alert.player.has_image ? (
                                  <img alt="player" src={`${host}/core/api/player_image/${el.alert.player.id}/`} />
                                ) : (
                                  <div className={`${styles.player__imageInitials} ${styles[el.alert.level]}`}>{el.alert.player.initials}</div>
                                )}
                              </div>
                            </div>
                            <div className={styles.player__cont}>
                              <div className={styles.player__title}>{`${el.alert.player.first_name} ${el.alert.player.last_name}`}</div>
                              <div className={`${styles.player__level} ${styles[el.alert.level]}`}>{t(el.alert.level) + ' ' + t('Risk')}</div>
                            </div>
                          </Link>
                          <div className={`${styles.player__detected} ${styles[el.alert.level]}`}>
                            <div className={styles.player__detectedTitle}>{t('Detected')}</div>
                            <div className={styles.player__detectedDate}>{dayjs(el.alert.detected).format('MMM. DD, YYYY')}</div>
                          </div>
                        </div>
                        <div className={styles.accordionWrap}>
                          <div className={styles.accordion} onClick={() => togglePanel(el.alert.player.id)}>
                            {active.includes(el.alert.player.id) ? <img src="/static/images/icons/minus.svg" /> : <img src="/static/images/icons/plus.svg" />}
                          </div>
                        </div>
                        <div className={`${styles.riskManagement} ${active.includes(el.alert.player.id) && styles.active} ${styles[el.alert.level]}`}>
                          <div className={styles.riskManagement__title}>{t('Recommendations')}</div>
                          {el.complete_rest ? (
                            <div className={`${styles.riskManagement__recommendations} ${styles.noWorkload}`}>{t('complete-rest')}</div>
                          ) : (
                            <div className={`${styles.riskManagement__recommendations} ${el.recommendations !== undefined && el.recommendations.length > 0 && styles.showRecommendations}`}>
                              {el.recommendations.map((el) => {
                                return <RecommendationCategory key={el.category} {...el} t={t} />;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function RecommendationCategory(props) {
  return (
    <div className={styles.recommendationCategory}>
      <div className={styles.recommendationCategory__name}>{props.category}</div>
      {props.no_workload ? (
        <div className={`${styles.recommendationCategory__keys} ${props.active && styles.active} ${styles.noWorkload}`}>{props.t('avoid-any-workload-in-this-category')}</div>
      ) : (
        <div className={styles.recommendationCategory__keys}>
          {props.recommendations &&
            props.recommendations.map((el) => {
              return (
                <div key={el.key} className={styles.recommendationCategory__keysItem}>
                  <div
                    className={styles.recommendationCategory__key}
                    dangerouslySetInnerHTML={{
                      __html: `<div>${el.key[0].toUpperCase() + el.key.substring(1)}</div>
                                         <span className="${styles.recommendationCategory__value}">
                                            ${el.value}
                                        </span>`,
                    }}
                  ></div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
