import styles from './NotFound.module.scss';

export default function NotFound() {
  return (
    <div className={styles.center}>
      <div className={styles.errorCode}>404</div>
      <div className={styles.errorDesc}>Page Not Found</div>
      <div>Sorry, but the page you are looking for was not found</div>
    </div>
  );
}