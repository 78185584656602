import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Modal from '../../Modal/Modal';
import Tooltip from '../../Tooltip/Tooltip';
import styles from './RiskFactors.module.scss';
import { sendAmplitudeData } from '../../../utils/amplitude';

export const workloadLevels = {
  standard: 'standard-data-range',
  low: 'risk-decrease',
  high: 'risk-increase',
};

export default function RiskFactors(props) {
  const [modalWorkloadItem, setModalWorkloadItem] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);

  const { t, i18n } = useTranslation();

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };

  let isSafari = navigator.userAgent.indexOf('Safari') === 102;

  const openModal = (data) => {
    setModalWorkloadItem(data);
    sendAmplitudeData(`factor graph opened`, { detail: data.contribution.key });
  };

  const workloadSeries = [
    {
      name: '',
      type: 'bar',
      data:
        modalWorkloadItem !== null &&
        modalWorkloadItem.raw_data.map((el) => {
          return {
            x: new Date(el.date + 'T12:00:00'),
            y: el.parameter_lower_threshold,
          };
        }),
    },
    {
      name: 'Standard Data Range',
      type: 'bar',
      data:
        modalWorkloadItem !== null &&
        modalWorkloadItem.raw_data.map((el) => {
          return {
            x: new Date(el.date + 'T12:00:00'),
            y: el.parameter_upper_threshold - el.parameter_lower_threshold,
          };
        }),
    },
  ];

  let maxWorkload = 0;
  if (modalWorkloadItem !== null) {
    maxWorkload = modalWorkloadItem.raw_data.reduce((max, el) => {
      return Math.max(max, el.value, el.parameter_upper_threshold);
    }, 0);
  }

  const workloadOptions = {
    chart: {
      height: 260,
      type: 'bar',
      stacked: true,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    annotations: {
      points:
        modalWorkloadItem !== null &&
        modalWorkloadItem.raw_data.map((el) => {
          return {
            x: new Date(el.date + 'T12:00:00').getTime(),
            y: el.value,
            marker: {
              size: 6,
              strokeColor: '#303338',
              fillColor: el.value >= el.parameter_lower_threshold && el.value <= el.parameter_upper_threshold ? props.colors.average : props.colors.alert,
              strokeWidth: 5,
              radius: 5,
            },
          };
        }),
      xaxis: props.games !== undefined && [
        {
          x: new Date(props.alert.detected + 'T12:00:00').getTime(),
          borderColor: props.colors.alert,
          strokeDashArray: 0,
          label: {
            borderWidth: 0,
            style: {
              fontSize: 12,
              color: props.colors.white + '75',
              background: 'transparent',
              fontFamily: 'Roboto',
            },
            orientation: 'horizontal',
            offsetY: -25,
            text: 'Risk Detected',
          },
        },
        ...props.games.map((el) => {
          const riskDetectedColision =
            props.alert.detected &&
            (dayjs(props.alert.detected).isSame(dayjs(el.date)) ||
              dayjs(props.alert.detected).add(1, 'd').isSame(dayjs(el.date)) ||
              dayjs(props.alert.detected).subtract(1, 'd').isSame(dayjs(el.date)));
          return {
            x: new Date(el.date + 'T12:00:00').getTime(),
            label: {
              borderWidth: 0,
              style: {
                color: props.colors.white + '75',
                background: 'transparent',
              },
              orientation: 'horizontal',
              offsetY: -10,
              text: riskDetectedColision ? '' : el.name,
            },
          };
        }),
      ],
    },
    colors: ['transparent', props.colors.gray],
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
      padding: {
        right: 33,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        startingShape: 'rounded',
        endingShape: 'rounded',
        columnWidth: '40%',
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    title: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        hideOverlappingLabels: true,
        formatter: function (val) {
          return dayjs(val).format('MMM DD');
        },
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          colors: ['#ffffff'],
          cssClass: 'apexcharts-xaxis-label',
        },
      },
      tickAmount: 9,
      axisBorder: {
        show: false,
      },
      //hideOverlappingLabels: true,
      axisTicks: {
        show: true,
        color: props.colors.gray,
        // autoskip: true,
        // maxTicksLimit: 4,
        // interval: 3
      },
    },
    yaxis: {
      show: true,
      decimalsInFloat: 0,
      forceNiceScale: true,
      max: maxWorkload,
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          colors: ['#ffffff'],
        },
      },
    },
  };

  return (
    <>
      {props.workload_contributions && (
        <div className={`${styles.riskFactor} ${styles.panel}`}>
          <div className={styles.riskFactor__title}>
            <span>{t('risk-factors')}</span>
            <span
              className={styles.player__tooltip}
              onClick={(e) =>
                toggleTooltip({
                  clientX: e.clientX,
                  clientY: e.clientY,
                  title: props.tooltips.risk_factors.title,
                  content: props.tooltips.risk_factors.text,
                })
              }
            >
              i
            </span>
          </div>
          <div className={styles.riskFactor__subtitle}>{t('impact-on-injury-risk')}</div>
          <ul className={styles.riskFactor__list}>
            {props.workload_contributions.map((el) => {
              let worloadRiskLevel = el.contribution.level.toLowerCase();

              const itemNotClickable = props.no_graph_factors.filter((i) => i === el.contribution.key).length > 0;

              return (
                <li
                  key={el.contribution.display_name}
                  className={`${styles[worloadRiskLevel]} ${styles[props.alert.level.toString().substr(0, 2)]} ${styles.dot} ${styles.riskFactor__list_itemCenter}`}
                >
                  <div 
                    className={`${styles.riskFactorItem}`} 
                    onClick={() => {
                      !itemNotClickable && openModal(el);
                    }}>
                    <div
                      className={`${styles.riskFactorItem__name} 
                                                 ${!itemNotClickable && styles.riskFactorItem__nameActive}`}
                      dangerouslySetInnerHTML={{ __html: el.contribution.display_name }}
                    ></div>
                    <div className={styles.riskFactorItem__levelWrapper}>
                      <div className={`${styles.riskFactorItem__level} ${styles[props.alert.level.toString().substr(0, 2)]} ${styles[worloadRiskLevel]}`}>{t(worloadRiskLevel)}</div>
                    </div>
                  </div>
                </li>
              );
            })}
            {props.non_workload_contributions.map((el) => {
              let worloadRiskLevel = el.level.toLowerCase();
              return (
                <li key={el.name} className={`${styles[worloadRiskLevel]} ${styles[props.alert.level.toString().substr(0, 2)]} ${styles.dot} ${styles.riskFactor__list_itemCenter}`}>
                  <div className={`${styles.riskFactorItem}`}>
                    <div className={`${styles.riskFactorItem__name}`} dangerouslySetInnerHTML={{ __html: el.name }}></div>
                    <div className={styles.riskFactorItem__levelWrapper}>
                      <div className={`${styles.riskFactorItem__level} ${styles[props.alert.level.toString().substr(0, 2)]} ${styles[worloadRiskLevel]}`}>{t(worloadRiskLevel)}</div>
                    </div>
                  </div>
                </li>
              );
            })}
            {!!modalWorkloadItem && (
              <Modal isSidebarCollapsed={props.isSidebarCollapsed} isOpen={!!modalWorkloadItem} onClose={() => setModalWorkloadItem(null)} minHeight={117}>
                <div className={styles.workload}>
                  <div className={styles.workload__wrap}>
                    <div className={styles.workload__title} dangerouslySetInnerHTML={{ __html: modalWorkloadItem !== null && modalWorkloadItem.contribution.display_name }}></div>
                    <ul className={`${styles.workloadLegend} ${isSafari && styles.workloadLegend__safari}`}>
                      {Object.keys(workloadLevels).map((el) => {
                        return (
                          <li
                            key={el}
                            className={`
                                                ${styles.workloadLegend__item} 
                                                ${styles[el]} 
                                                ${styles.dot}
                                            `}
                          >
                            <div className={styles.workloadLegend__name}>{t(workloadLevels[el])}</div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <Chart options={workloadOptions} series={workloadSeries} type="bar" height={window.matchMedia('(min-width: 1024px)').matches ? 400 : 220} width="100%" />
                </div>
                <div className={styles.workload__mobile}>
                  <div className={styles.workload__mobileTitle} dangerouslySetInnerHTML={{ __html: modalWorkloadItem.contribution.display_name }}></div>
                  <div className={styles.workload__mobileContent}>{t('to-view-the-raw-data-graphs-please-view-this-page-on-a-larger-screen')}</div>
                </div>
              </Modal>
            )}
          </ul>
        </div>
      )}
      {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
    </>
  );
}
