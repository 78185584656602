import React, { useState, useEffect } from 'react';
import { colors } from '../PlayerInfo/PlayerInfo';
import dayjs from 'dayjs';
import styles from '../RiskFactors/RiskFactors.module.scss';
import Chart from 'react-apexcharts';
import { workloadLevels } from '../RiskFactors/RiskFactors';
import { useTranslation } from 'react-i18next';

export default function WorkloadContainer() {
  const [data, setData] = useState(null);

  const { t, i18n } = useTranslation();

  let isSafari = navigator.userAgent.indexOf('Safari') === 102;

  const workloadSeries = [
    {
      name: '',
      type: 'bar',
      data:
        data !== null &&
        data.raw_data.map((el) => {
          return {
            x: new Date(el.date + 'T12:00:00'),
            y: el.parameter_lower_threshold,
          };
        }),
    },
    {
      name: 'Standard Data Range',
      type: 'bar',
      data:
        data !== null &&
        data.raw_data.map((el) => {
          return {
            x: new Date(el.date + 'T12:00:00'),
            y: el.parameter_upper_threshold - el.parameter_lower_threshold,
          };
        }),
    },
  ];

  let maxWorkload = 0;
  if (data !== null) {
    maxWorkload = data.raw_data.reduce((max, el) => {
      return Math.max(max, el.value, el.parameter_upper_threshold);
    }, 0);
  }

  const workloadOptions = {
    chart: {
      height: 260,
      type: 'bar',
      stacked: true,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    annotations: {
      points:
        data !== null &&
        data.raw_data.map((el) => {
          return {
            x: new Date(el.date + 'T12:00:00').getTime(),
            y: el.value,
            marker: {
              size: 6,
              strokeColor: '#303338',
              fillColor: el.value >= el.parameter_lower_threshold && el.value <= el.parameter_upper_threshold ? colors.average : colors.alert,
              strokeWidth: 5,
              radius: 5,
            },
          };
        }),
      xaxis: data !== null &&
        data.games !== undefined && [
          {
            x: new Date(data.alert.detected + 'T12:00:00').getTime(),
            borderColor: colors.alert,
            strokeDashArray: 0,
            label: {
              borderWidth: 0,
              style: {
                fontSize: 12,
                color: '#ffffff75',
                background: 'transparent',
                fontFamily: 'Roboto',
              },
              orientation: 'horizontal',
              offsetY: -25,
              text: 'Risk Detected',
            },
          },
          ...data.games.map((el) => {
            const riskDetectedColision =
              data.alert.detected &&
              (dayjs(data.alert.detected).isSame(dayjs(el.date)) ||
                dayjs(data.alert.detected).add(1, 'd').isSame(dayjs(el.date)) ||
                dayjs(data.alert.detected).subtract(1, 'd').isSame(dayjs(el.date)));
            return {
              x: new Date(el.date + 'T12:00:00').getTime(),
              label: {
                borderWidth: 0,
                style: {
                  // color: colors.gray,
                  color: colors.white + '75',
                  // background: colors.black32,
                  background: 'transparent',
                },
                orientation: 'horizontal',
                offsetY: -10,
                opacity: 0.75,
                text: riskDetectedColision ? '' : el.name,
              },
            };
          }),
        ],
    },
    colors: ['transparent', colors.gray],
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        startingShape: 'rounded',
        endingShape: 'rounded',
        columnWidth: '40%',
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    title: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        hideOverlappingLabels: true,
        formatter: function (val) {
          return dayjs(val).format('MMM DD');
        },
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          colors: ['#ffffff'],
          cssClass: 'apexcharts-xaxis-label',
        },
      },
      tickAmount: 9,
      axisBorder: {
        show: false,
      },
      //hideOverlappingLabels: true,
      axisTicks: {
        show: true,
        color: colors.gray,
        // autoskip: true,
        // maxTicksLimit: 4,
        // interval: 3
      },
    },
    yaxis: {
      show: true,
      decimalsInFloat: 0,
      forceNiceScale: true,
      max: maxWorkload,
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          colors: ['#ffffff'],
        },
      },
    },
  };

  useEffect(() => {
    function handleEvent(message) {
      setData(message.data);
      console.log('workload: ', message.data);
    }

    window.addEventListener('message', handleEvent);

    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return (
    data && (
      <div className={styles.riskFactor} style={{ background: '#2E3237' }}>
        <div className={styles.workload}>
          <div className={styles.workload__wrap}>
            <ul className={`${styles.workloadLegend} ${isSafari && styles.workloadLegend__safari}`}>
              {Object.keys(workloadLevels).map((el) => {
                return (
                  <li key={el} className={`${styles.workloadLegend__item} ${styles[el]} ${styles.dot}`}>
                    <div className={styles.workloadLegend__name}>{t(workloadLevels[el])}</div>
                  </li>
                );
              })}
            </ul>
          </div>
          <Chart options={workloadOptions} series={workloadSeries} type="bar" height={window.matchMedia('(min-width: 768px)').matches ? 400 : 220} width="100%" />
        </div>
        <div className={styles.workload__mobile}>
          <div className={styles.workload__mobileContent}>{t('to-view-the-raw-data-graphs-please-view-this-page-on-a-larger-screen')}</div>
        </div>
      </div>
    )
  );
}
