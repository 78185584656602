import React, { useLayoutEffect, useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import am4lang_en from '@amcharts/amcharts4/lang/en';
import { host } from '../../../utils/API';
import styles from './RiskHeatmap.module.scss';

let am4core = null;
let am4charts = null;
if (process.browser) {
  am4core = require('@amcharts/amcharts4/core');
  am4charts = require('@amcharts/amcharts4/charts');
}

export default function RiskHeatmap(props) {
  const [playersRangesState, setPlayersRanges] = useState([]);
  const [active, setActive] = useState('Position');

  const { t, i18n } = useTranslation();

  const changeActive = useCallback(
    (item) => {
      setActive(item);

      let sortingFunction;

      if (item === 'Position') {
        sortingFunction = (a, b) => {
          if (a.player.position.group === b.player.position.group) {
            return a.player.id - b.player.id;
          } else {
            return a.player.position.group.localeCompare(b.player.position.group);
          }
        };
      }
      if (item === 'Days') {
        sortingFunction = (a, b) => props.heat_map_risk_count[b.player.id] - props.heat_map_risk_count[a.player.id];
      }
      if (item === 'Name') {
        sortingFunction = (a, b) => (a.player.first_name + a.player.last_name).localeCompare(b.player.first_name + b.player.last_name);
      }

      const playersRangesSorted = [...playersRangesState].sort(sortingFunction);

      setPlayersRanges(playersRangesSorted);
    },
    [setActive, playersRangesState, setPlayersRanges],
  );

  const buildData = useCallback(() => {
    const playersRanges = [];

    if (props.heat_map !== undefined) {
      let sortedPlayers = [...props.heat_map_players].sort((a, b) => a.id - b.id);

      for (let day of props.heat_map) {
        for (let player of sortedPlayers) {
          const alertPlayer = day.alerts.filter((el) => el.player.id === player.id);

          if (alertPlayer.length > 0) {
            const playerRanges = playersRanges.filter((e) => e.player.id === player.id);

            if (playerRanges.length === 0) {
              playersRanges.push({
                start: day.date,
                end: day.date,
                player: player,
                level: alertPlayer[0].level,
              });

              if (day.date === props.heat_map[props.heat_map.length - 1].date) {
                playersRanges[playersRanges.length - 1].end = dayjs(day.date).add(1, 'day').format('YYYY-MM-DD');
              }
            } else if (playerRanges[playerRanges.length - 1].level !== alertPlayer[0].level) {
              playerRanges[playerRanges.length - 1].end = day.date;

              playersRanges.push({
                start: day.date,
                end: day.date,
                player: player,
                level: alertPlayer[0].level,
              });

              if (day.date === props.heat_map[props.heat_map.length - 1].date) {
                playersRanges[playersRanges.length - 1].end = dayjs(day.date).add(1, 'day').format('YYYY-MM-DD');
              }
            } else {
              playerRanges[playerRanges.length - 1].end = day.date;

              if (day.date === props.heat_map[props.heat_map.length - 1].date) {
                playerRanges[playerRanges.length - 1].end = dayjs(day.date).add(1, 'day').format('YYYY-MM-DD');
              }
            }
          }
        }
      }
      playersRanges.sort((a, b) => {
        if (a.player.position.group === b.player.position.group) {
          return a.player.id - b.player.id;
        } else {
          return a.player.position.group.localeCompare(b.player.position.group);
        }
      });
      setPlayersRanges(playersRanges);
    }
  }, [setPlayersRanges]);

  useEffect(() => {
    buildData();
  }, [props.heat_map]);

  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create('chartdiv', am4charts.XYChart);

    //chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    if (i18n.language === 'es') {
      chart.language.locale = am4lang_es_ES;
    }
    // chart.dateFormatter.language = new am4core.Language();
    // chart.dateFormatter.language.locale = am4lang_es_ES;

    chart.paddingRight = 4;
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm';

    chart.data = playersRangesState.map((el) => {
      return {
        start: el.start,
        end: el.end,
        task: el.level,
        category: el.player.first_name + ' ' + el.player.last_name,
        id: el.player.id,
        initials: el.player.initials,
        href: `${host}/core/api/player_image/${el.player.id}/`,
        has_image: el.player.has_image,
        injured: el.player.injured,
        positiongroup: el.player.position.group,
        color: props.colors[el.level],
        color_grad: props.colors[el.level + '_grad'],
      };
    });

    chart.dateFormatter.dateFormat = 'yyyy-MM-dd';
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.dataFields.task = 'task';
    categoryAxis.renderer.inversed = true;

    categoryAxis.renderer.minGridDistance = 10;

    categoryAxis.renderer.grid.template.disabled = true;

    categoryAxis.renderer.labels.template.fill = am4core.color(props.colors.white);
    categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.labels.template.fontWeight = 300;

    categoryAxis.renderer.labels.template.align = 'left';

    categoryAxis.renderer.labels.template.paddingRight = 35;
    categoryAxis.renderer.labels.template.paddingLeft = 30;

    let avatarBullet = categoryAxis.renderer.labels.template.createChild(am4charts.CircleBullet);
    avatarBullet.circle.radius = 14;
    avatarBullet.valign = 'bottom';
    avatarBullet.align = 'center';
    avatarBullet.isMeasured = true;
    avatarBullet.mouseEnabled = false;
    avatarBullet.verticalCenter = 'bottom';
    avatarBullet.interactionsEnabled = false;

    let image = avatarBullet.createChild(am4core.Image);
    image.width = 30;
    image.height = 30;
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'middle';
    image.propertyFields.href = 'href';

    image.dx = -20;
    image.dy = 6;

    image.adapter.add('mask', function (mask, target) {
      let circleBullet = target.parent;
      return circleBullet.circle;
    });

    let bulletInitials = categoryAxis.renderer.labels.template.createChild(am4charts.LabelBullet);
    bulletInitials.label.text = '{initials}';
    bulletInitials.label.truncate = false;
    bulletInitials.label.hideOversized = false;
    bulletInitials.label.horizontalCenter = 'middle';
    bulletInitials.label.verticalCenter = 'middle';
    bulletInitials.label.fill = am4core.color(props.colors.white);
    bulletInitials.dx = -20;
    bulletInitials.dy = 8;

    let fillModifier1 = new am4core.LinearGradientModifier();
    image.fillModifier = fillModifier1;

    image.adapter.add('fill', function (fill, target) {
      if (target && target.dataItem && target.dataItem._dataContext && target.dataItem._dataContext.has_image === false) {
        let gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color(props.colors.high));
        gradient.addColor(am4core.color(props.colors.high));

        target.disabled = true;

        target.parent.fill = am4core.color(props.colors.black40);
        target.parent.fillOpacity = 0.5;
        target.parent.stroke = am4core.color(props.colors.white);
        target.parent.strokeOpacity = 0.5;

        target.parent.dx = -20;
        target.parent.dy = 6;

        return gradient;
      }

      return fill;
    });

    let fillModifier2 = new am4core.LinearGradientModifier();
    bulletInitials.fillModifier = fillModifier2;

    bulletInitials.adapter.add('fill', function (fill, target) {
      if (target && target.dataItem && target.dataItem._dataContext && target.dataItem._dataContext.has_image === true) {
        let gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color(props.colors.high));
        gradient.addColor(am4core.color(props.colors.high));

        target.disabled = true;

        return gradient;
      }

      return fill;
    });

    let injuredBullet = categoryAxis.renderer.labels.template.createChild(am4charts.CircleBullet);
    injuredBullet.circle.radius = 3;
    injuredBullet.valign = 'bottom';
    injuredBullet.align = 'center';
    injuredBullet.isMeasured = true;
    injuredBullet.mouseEnabled = false;
    injuredBullet.verticalCenter = 'bottom';
    injuredBullet.background.fill = am4core.color(props.colors.white);
    injuredBullet.opacity = 0.5;
    injuredBullet.interactionsEnabled = false;
    injuredBullet.dx = -40;
    injuredBullet.dy = 4;
    injuredBullet.disabled = true;
    injuredBullet.propertyFields.disabled = 'injured';

    chart.maskBullets = false;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.baseInterval = { count: 1, timeUnit: 'day' };
    if (!props.background) {
      dateAxis.renderer.tooltipLocation = 0;
    }
    dateAxis.renderer.grid.template.disabled = false;

    dateAxis.renderer.opposite = true;

    dateAxis.renderer.labels.template.fill = am4core.color(props.colors.white);
    dateAxis.renderer.labels.template.fontSize = 13;
    dateAxis.renderer.labels.template.fontWeight = 100;
    dateAxis.renderer.labels.template.opacity = 0.5;

    dateAxis.renderer.grid.template.opacity = 0;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.renderer.ticks.template.strokeOpacity = 0;
    dateAxis.renderer.ticks.template.stroke = am4core.color(props.colors.white);
    dateAxis.renderer.ticks.template.length = 6;
    dateAxis.renderer.baseGrid.disabled = false;
    dateAxis.renderer.minGridDistance = 50;

    let series1 = chart.series.push(new am4charts.ColumnSeries());

    series1.columns.template.height = 16;

    series1.dataFields.openDateX = 'start';
    series1.dataFields.dateX = 'end';
    series1.dataFields.categoryY = 'category';
    series1.dataFields.task = 'task';
    series1.columns.template.propertyFields.fill = 'color'; // get color from data
    series1.columns.template.propertyFields.stroke = 'color';
    series1.columns.template.strokeOpacity = 1;

    series1.columns.template.column.cornerRadius(10, 10, 10, 10);
    series1.columns.template.column.paddingRight = 4;

    series1.columns.template.adapter.add('fill', (fill, target) => {
      let gradient = new am4core.LinearGradient();
      if (target.dataItem && target.dataItem.task) {
        gradient.addColor(am4core.color(props.colors[target.dataItem.task + '_count']));
        gradient.addColor(am4core.color(props.colors[target.dataItem.task + '_count_grad']));
      }
      if (target.dataItem && target.dataItem.task == null) {
        gradient.addColor(am4core.color(props.colors['low_count']));
        gradient.addColor(am4core.color(props.colors['low_count_grad']));
      }
      return target.dataItem ? gradient : fill;
    });

    let fillModifier = new am4core.LinearGradientModifier();
    series1.columns.template.fillModifier = fillModifier;

    series1.showOnInit = false;

    // Set cell size in pixels
    let cellSize = 30;
    chart.events.on('datavalidated', function (ev) {
      if (props.heat_map_players) {
        // Get objects of interest
        let chart = ev.target;
        let categoryAxis = chart.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        let adjustHeight = props.heat_map_players.length * cellSize - categoryAxis.pixelHeight;

        // get current chart height
        let targetHeight = chart.pixelHeight + adjustHeight;

        // Set it on chart's container
        chart.svgContainer.htmlElement.style.height = targetHeight + 'px';
      }
    });

    const createPositionRange = (category, endCategory, name) => {
      let range = categoryAxis.axisRanges.create();
      range.category = category;
      range.endCategory = endCategory;
      range.label.text = name;
      range.label.opacity = 1;
      range.label.location = 0.5;
      range.label.horizontalCenter = 'middle';
      range.grid.disabled = true;
      range.label.rotation = 90;
      range.label.dx = chart.pixelWidth - 44;
      range.locations.category = 0.2;
      range.locations.endCategory = 0.8;

      range.axisFill.fill = am4core.color('#fff');
      range.axisFill.fillOpacity = 0;
      range.grid.strokeOpacity = 0;
    };

    chart.events.on('sizechanged', function (ev) {
      ev.target.yAxes.values[0].axisRanges.template.label.dx = chart.pixelWidth - 44;
    });

    if (active === 'Position') {
      series1.events.on('datavalidated', function () {
        if (props.positions) {
          for (let group of props.positions) {
            let players = props.heat_map_players.filter((el) => el.position.group === group).sort((a, b) => a.id - b.id);

            if (players.length > 0) {
              createPositionRange(
                players[0].first_name + ' ' + players[0].last_name,
                players[players.length - 1].first_name + ' ' + players[players.length - 1].last_name,
                players.length > 2 ? group : '',
              );
            }
          }
        }
      });
    }
    if (!props.background) {
      series1.tooltip.label.interactionsEnabled = true;
      series1.tooltip.keepTargetHover = true;
      series1.columns.template.tooltipHTML = '<a href="player/{id.urlEncode()}">{category}</a>';
    }
    chart.responsive.enabled = true;
    chart.responsive.useDefault = false;

    chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 375) {
          return true;
        }
        return false;
      },
      state: function (target, stateId) {
        if (target instanceof am4charts.Chart) {
          let state = target.states.create(stateId);
          //state.properties.paddingTop = 0;
          return state;
        }
        if (target instanceof am4charts.AxisLabel && target.parent instanceof am4charts.AxisRendererY) {
          let state = target.states.create(stateId);
          state.properties.dx = -40;
          //state.properties.disabled = true;
          //state.properties.inside = true;
          //state.properties.maxLabelPosition = 1;
          //let state = target.states.create(stateId);
          return state;
        }
        if (target instanceof am4core.Scrollbar) {
          let state = target.states.create(stateId);
          state.properties.disabled = false;
          return state;
        }
        // if (target.parent instanceof am4charts.AxisRendererY) {
        //     target.pixelWidth = 300;
        // }
        return null;
      },
    });

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.disabled = true;

    const emptyDivs = document.querySelectorAll('body > div:not(#root)');

    if (emptyDivs) {
      emptyDivs.forEach((el) => {
        el.style.display = 'none';
      });
    }

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [playersRangesState, props.positions]);

  return (
    <>
      {props.heat_map && (
        <div className={`${styles.riskHeatmap} ${styles.panel}`} style={props.background && { background: 'linear-gradient(#2C2F35, #2F3539)' }}>
          <div className={styles.riskHeatmap__head}>
            <div className={styles.riskHeatmap__title}>{t('risk-heatmap')}</div>
            <div className={styles.riskHeatmap__sort}>
              <div className={`${styles.riskHeatmap__sortItem} ${styles.riskHeatmap__position} ${active === 'Position' && styles.active}`} onClick={() => changeActive('Position')}>
                {t('Position')}
              </div>
              <div className={`${styles.riskHeatmap__sortItem} ${styles.riskHeatmap__days} ${active === 'Days' && styles.active}`} onClick={() => changeActive('Days')}>
                {t('days-at-risk')}
              </div>
              <div className={`${styles.riskHeatmap__sortItem} ${styles.riskHeatmap__name} ${active === 'Name' && styles.active}`} onClick={() => changeActive('Name')}>
                {t('Name')}
              </div>
            </div>
            <ul className={styles.riskHeatmap__injuryUl}>
              <li className={styles.riskHeatmap__highRisk}>{t('high-risk')}</li>
              <li  className={styles.riskHeatmap__mediumRisk}>{t('medium-risk')}</li >
              <li  className={styles.riskHeatmap__lowRisk}>{t('low-risk')}</li >
              <li  className={styles.riskHeatmap__injury}>{t('unavailable')}</li >
              
            </ul>
          </div>
          <div className={styles.riskHeatmap__message}>{t('to-view-the-risk-heatmap-for-this-team-view-this-page-on-a-tablet-or-desktop')}</div>
          <div
            id="chartdiv"
            className={styles.riskHeatmap__chart}
            style={{
              width: '100%',
            }}
          />
        </div>
      )}
    </>
  );
}
