export const ROUTES = Object.freeze({
  BASE: '/ui',
  LOGIN: '/login',
  PLAYERS: '/ui/players',
  PLAYER: '/ui/player',
  MY_TEAM: '/ui/my-team',
  TEAMS: '/ui/teams',
  TEAM_REPORT: '/ui/team-report',
  HISTORY: '/ui/history',
  DATA: '/ui/data',
  CONTACT_US: '/ui/contact-us',
  PLAYER_SUMMARY: '/ui/player-summary',
  PLAYERS_RISK: '/ui/playersrisk',
  RADAR: '/ui/radar',
  RISKTREND: '/ui/risktrend',
  HEATMAP: '/ui/heatmap',
  WORKLOAD: '/ui/workload',
  //CHANGE_PASSWORD: '/change_password/'
});

export const RISK_LEVEL = Object.freeze({
  LOW: { START: 0, END: 0.2, COLOR: '#0f98d7' },
  MEDIUM: { START: 0.2, END: 0.4, COLOR: '#e75d0d' },
  HIGH: { START: 0.4, END: 1, COLOR: '#da2a16' },
});

let appMenuItems;
if (process.env.REACT_APP_NFL_MODE === "TRUE") {
    appMenuItems = [
        {
            icon: '/static/images/icons/players.svg',
            link: ROUTES.PLAYERS,
            title: 'Players',
        },
        {
            icon: '/static/images/icons/teams.svg',
            link: ROUTES.TEAMS,
            title: 'Teams',
        },
        {
          icon: '/static/images/icons/team_report.svg',
          link: ROUTES.TEAM_REPORT,
          title: 'Team-Report',
      },
        {
            icon: '/static/images/icons/data.svg',
            link: ROUTES.DATA,
            title: 'Injuries',
        },
        {
            icon: '/static/images/icons/history.svg',
            link: ROUTES.HISTORY,
            title: 'History',
        },
    ];
}
else {
    appMenuItems = [
      {
        icon: '/static/images/icons/players.svg',
        link: ROUTES.PLAYERS,
        title: 'Players',
      },
      {
        icon: '/static/images/icons/my_team.svg',
        link: ROUTES.MY_TEAM,
        title: 'My Team',
      },
      {
        icon: '/static/images/icons/teams.svg',
        link: ROUTES.TEAMS,
        title: 'Teams',
      },
      {
        icon: '/static/images/icons/team_report.svg',
        link: ROUTES.TEAM_REPORT,
        title: 'Team-Report',
      },
      {
        icon: '/static/images/icons/history.svg',
        link: ROUTES.HISTORY,
        title: 'History',
      },
      {
        icon: '/static/images/icons/data.svg',
        link: ROUTES.DATA,
        title: 'Data',
      },
    ];
}

export const menuItems = appMenuItems;
