import React from 'react';
import styles from './RecommendationCategory.module.scss';
import { useTranslation } from 'react-i18next';

export default function RecommendationCategory(props) {
  const { t, i18n } = useTranslation();
  const trend = props.trend || 'equal';
  return (
    <div className={styles.recommendationCategory}>
      <div className={`${styles.recommendationCategory__nameTrend} ${styles.recommendationCategory__nameTrend_open}`}>
        <div className={`${styles.recommendationCategory__name}`}>{props.name || props.category}</div>
        <div className={`${styles.recommendationCategory__trend}`}>
          {props.trend !== 'inconsistent' ? (
            <span className={`${styles.recommendationCategory__trendArrow} ${styles[trend]}`}>
              <img src="/static/images/icons/arrow_trend.svg" />
            </span>
          ) : (
            <span className={styles.recommendationCategory__trendArrowCont}>
              <span className={`${styles.recommendationCategory__trendArrow}`}>
                <img src="/static/images/icons/arrow_trend_twice.svg" />
              </span>
            </span>
          )}
          {props.trend_text}
        </div>
      </div>
      {props.no_workload ? (
        <div className={`${styles.recommendationCategory__keys} ${props.active && styles.active} ${styles.noWorkload}`}>{t('avoid-any-workload-in-this-category')}</div>
      ) : (
        <div className={`${styles.recommendationCategory__keys} ${props.active && styles.active}`}>
          {props.recommendations.map((el) => {
            return (
              <div key={el.key} className={styles.recommendationCategory__keysItem}>
                <div className={styles.recommendationCategory__value} dangerouslySetInnerHTML={{ __html: el.value }}></div>
                <div className={styles.recommendationCategory__key} dangerouslySetInnerHTML={{ __html: el.key[0].toUpperCase() + el.key.substring(1) }}></div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
