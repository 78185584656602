import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import TeamReportComponent from '../../components/TeamReportComponent/TeamReportComponent';
import API from '../../utils/API';
import styles from './team-report.module.scss';
import Preloader from '../../components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HandleError, handleRedirect } from '../../utils/helpers';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';

export default function TeamReport(props) {
  const [data, setData] = useState(null);
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const { t, i18n } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.get('/team_report/');
      if (response.status === 200) {
        handleRedirect(response, history);
        setData(response.data);
        setLastUpdateDate(response.data.report_date);
        setImage(response.data.club.icon);
        if (props.user === '') {
          props.setUser(response.data.user);
        }
      }
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status) {
        HandleError(e, history);
      }
      console.log('Error fetch team report data: ', e);
    }
  }, [setData, setLastUpdateDate, setImage, setLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout isSidebarCollapsed={props.isSidebarCollapsed} changeCollapseSidebar={props.changeCollapseSidebar} user={props.user}>
      {/*<Head>*/}
      {/*    <title>Team report</title>*/}
      {/*    <meta name="viewport" content="initial-scale=1.0, width=device-width"/>*/}
      {/*</Head>*/}
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.teamReportContainer}>
          <HeaderComponent isSidebarCollapsed={props.isSidebarCollapsed} title={t('team-report')} lastUpdateDate={lastUpdateDate} lastUpdateTitle={'last-updated'} image={image} />

          <div className={styles.teamReport}>{data && <TeamReportComponent isSidebarCollapsed={props.isSidebarCollapsed} {...data} />}</div>
        </div>
      )}
    </Layout>
  );
}
