import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../Tooltip/Tooltip';
import styles from './TeamRiskFactors.module.scss';

const teamRiskFactorsNames = {
  ['biomechanics']: 'biomechanics.svg',
  ['count']: 'count.svg',
  ['distance']: 'distance.svg',
  ['duration']: 'duration.svg',
  ['general']: 'count.svg',
  ['heart rate']: 'heart_rate.svg',
  ['injury history']: 'injury_history.svg',
  ['previous injuries']: 'injury_history.svg',
  ['lightning']: 'lightning1.svg',
  ['speed']: 'speed.svg',
  ['team schedule']: 'team_schedule.svg',
};

export default function TeamRiskFactors(props) {
  const [tooltipData, setTooltipData] = useState(null);

  const { t, i18n } = useTranslation();

  const toggleTooltip = (inf) => {
    setTooltipData(inf);
  };

  return (
    <>
      {props.common_categories && (
        <div className={`${styles.teamRiskFactors} ${styles.panel}`}>
          <div
            className={styles.tooltip}
            onClick={(e) =>
              toggleTooltip({
                clientX: e.clientX,
                clientY: e.clientY,
                title: t('team-report'),
                content: `${t('team-report')}.`,
              })
            }
          >
            i
          </div>
          <div className={styles.teamRiskFactors__title}>{t('team-risk-factors')}</div>
          {props.common_categories.map((el) => {
            return (
              <div className={`${styles.teamRiskFactors__item}`} key={el.name}>
                <div className={styles.teamRiskFactors__itemIcon}>
                  <img style={{ width: '100%' }} src={`/static/images/icons/${teamRiskFactorsNames[el.group]}`} />
                </div>
                <div className={styles.teamRiskFactors__itemTitle} dangerouslySetInnerHTML={{ __html: el.name }}></div>
              </div>
            );
          })}
          {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
        </div>
      )}
    </>
  );
}
