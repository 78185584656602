import { ROUTES } from './constants';
import API from './API';

export const HandleError = (e, history) => {
  switch (e.response.status) {
    case 400: {
      history.push('/400');
      break;
    }
    case 403: {
      history.push('/403');
      break;
    }
    case 404: {
      history.push('/404');
      break;
    }
    case 500: {
      history.push('/500');
      break;
    }
    default: {
      if (e.response.status > 500) {
        history.push('/500');
        break;
      }
      break;
    }
  }
};

export const handleRedirect = (response, history) => {
  // if the api redirected we need to login again
  if (response.request.responseURL.includes('ui/?next=')) {
    localStorage.removeItem('csrftoken');
    localStorage.removeItem('userPersonalData');
    localStorage.removeItem('clubData');
    history.push(ROUTES.LOGIN);
  }
};

export const fetchTeamData = async (history, setLoading) => {
  try {
    setLoading(true);
    const response = await API.get('/team_summary');

    if (response.status === 200) {
      handleRedirect(response, history);
      setLoading(false);
      return response.data;
    }
    setLoading(false);
  } catch (e) {
    setLoading(false);
    if (e.response && e.response.status) {
      HandleError(e, history);
    }
    console.log('Error fetch team data: ', e);
  }
};

export const fetchRadarData = async (history, setLoading, playerId, playerDate) => {
  try {
    setLoading(true);
    const response = await API.get(`/player/${playerId}/?date=${playerDate}`);
    if (response.status === 200) {
      handleRedirect(response, history);
      setLoading(false);
      return response.data;
    }
    setLoading(false);
  } catch (e) {
    setLoading(false);
    if (e.response && e.response.status) {
      HandleError(e, history);
    }
    console.log('Error fetch player radar data: ', e);
  }
};
