import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { host } from '../../../utils/API';
import Tooltip from '../../Tooltip/Tooltip';
import styles from './Overview.module.scss';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const showRisk = {
  high: true,
  medium: true,
  low: true,
  contained: true,
  injured: false,
};

export default function Overview(props) {
  const [tooltipData, setTooltipData] = useState(null);

  const { t, i18n } = useTranslation();

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };

  return (
    <>
      <div
        className={`${styles.playerInfo}
                                    ${props.alert.player.injured && styles.injured}
                                    ${(props.alert.level === 'low' || props.alert.level === null) && styles.noAdjustments}`}
      >
        <div className={`${styles.player__details} ${styles.panel}`}>
          {!props.alert.player.injured && props.alert.level !== 'low' && props.alert.level !== null && (
            <div
              className={styles.player__tooltip}
              onClick={(e) =>
                toggleTooltip({
                  clientX: e.clientX,
                  clientY: e.clientY,
                  title: props.tooltips.risk.title,
                  content: props.tooltips.risk.text,
                })
              }
              
            >
              i
            </div>
          )}
          <div className={styles.playerInfo__list}>
            <div
              className={`${styles.player__level}
                                                     ${styles[props.alert.level]}
                                                     ${styles.dot}
                                                     ${props.alert.player.injured && styles.injured}
                                                     ${props.alert.level === null && !props.alert.player.injured && styles.low}`}
            ></div>
            <div className={styles.player__image}>
              {props.alert.player.has_image ? (
                <img src={`${host}/core/api/player_image/${props.alert.player.id}/`} />
              ) : (
                <div
                  className={`${styles.player__imageInitials}
                                                             ${styles[props.alert.level]}
                                                             ${props.alert.player.injured && styles.injured}
                                                             ${props.alert.level === null && !props.alert.player.injured && styles.low}`}
                >
                  {props.alert.player.initials}
                </div>
              )}
            </div>
          </div>
          <div className={styles.player__wrap}>
            <div
              className={`${styles.player__level} ${styles[props.alert.level]} 
                                                    ${props.alert.player.injured && styles.injured}
                                                    ${props.alert.level === null && !props.alert.player.injured && styles.low}`}
            >
              {`${props.alert.level !== null && props.alert.level !== 'injured' ? t(props.alert.level + '-risk') : ''}`}
              {`${props.alert.level !== null && props.alert.level === 'injured' ? t('injured') : ''}`}
              {props.alert.level === null && !props.alert.player.injured && t('low-risk')}
            </div>
            <div className={styles.player__detected}>
              {props.alert.player.injured ? `${t('injured-since')} ${dayjs(props.injury.date).format('MMMM D, YYYY')}` : `${t('Detected')} ${dayjs(props.alert.detected).format('MMMM D, YYYY')}`}
            </div>
          </div>
        </div>
        {(props.alert.player.injured || props.alert.level === 'low' || (props.alert.level === null && !props.alert.player.injured)) && (
          <div className={`${styles.additionalInfo} ${styles.panel}`}>
            {props.alert.player.injured && (
              <div className={styles.player__injured}>
                <div className={styles.player__injuredTitle}>{t('this-player-is-currently-injured')}</div>
                <div className={styles.player__injuredIcon}>
                  <img src="/static/images/icons/minus_circle.svg" />
                </div>
              </div>
            )}
            {(props.alert.level === 'low' || (props.alert.level === null && !props.alert.player.injured)) && (
              <div className={styles.player__noAdjustments}>
                <div
                  className={styles.player__tooltip}
                  onClick={(e) =>
                    toggleTooltip({
                      clientX: e.clientX,
                      clientY: e.clientY,
                      title: props.tooltips.risk.title,
                      content: props.tooltips.risk.text,
                    })
                  }
                >
                  i
                </div>
                <div className={styles.player__noAdjustmentsTitle}>{t('no-training-adjustments-needed')}</div>
                <div className={styles.player__noAdjustmentsIcon}>
                  <img src="/static/images/icons/check_circle.svg" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
    </>
  );
}
