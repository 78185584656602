import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PlayerSummary from '../PlayerSummary/PlayerSummary';
import { colors } from '../Team';
import RiskHeatmap from '../RiskHeatmap/RiskHeatmap';

export default function RiskHeatmapContainer(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    function handleEvent(message) {
      setData(message.data);
    }

    window.addEventListener('message', handleEvent);

    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return <>{data && <RiskHeatmap {...data} colors={{ ...colors }} background={true} />}</>;
}
