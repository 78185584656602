import styles from './BadRequest.module.scss';

export default function BadRequest() {
  return (
    <div className={styles.center}>
      <div className={styles.errorCode}>400</div>
      <div className={styles.errorDesc}>Bad Request</div>
      <div>The server did not understand the request</div>
    </div>
  );
}