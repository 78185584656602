import dayjs from 'dayjs';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './RiskTrend.module.scss';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';

let am4core = null;
let am4charts = null;
if (process.browser) {
  am4core = require('@amcharts/amcharts4/core');
  am4charts = require('@amcharts/amcharts4/charts');
}

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const riskTrendLevels = ['low', 'medium', 'high'];

export default function RiskTrend(props) {
  const [dateSeeMore, setDateSeeMore] = useState(null);
  const [levelSeeMore, setLevelSeeMore] = useState(null);

  const { t, i18n } = useTranslation();

  const riskTrendSeries = [
    {
      name: 'Low Medium High',
      type: 'line',
      data: props.risk_trend && [...props.risk_trend.scores],
    },
  ];

  const injuriesDays = [].fill(false, 0, props.risk_trend && props.risk_trend.dates.length);

  let not_return_dateMass = props.injuries.filter((item) => !item.return_date || !item.return_date.length);
  if (not_return_dateMass && not_return_dateMass.length) {
    not_return_dateMass = not_return_dateMass.sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1));
  }
  if (props.injuries) {
    for (let injurieRange of props.injuries) {
      if (injurieRange.return_date === null) {
        injurieRange.return_date = dayjs(new Date()).format('YYYY-MM-DD');
      }
      for (const [index, date] of props.risk_trend.dates.entries()) {
        if (dayjs(date).diff(dayjs(injurieRange.date), 'h') >= 0 && dayjs(injurieRange.return_date).diff(dayjs(date), 'h') > 0) {
          injuriesDays[index] = true;
        }
      }
    }

    if (riskTrendLevels.length === 3 && props.injuries.length > 0) {
      riskTrendLevels.push('injured');
    }
  }

  const riskTrendOptions = {
    chart: {
      height: 270,
      id: 'risk_chart',
      type: 'line',
      width: '100%',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      //redrawOnWindowResize: true,
      redrawOnParentResize: true,
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '13px',
      fontFamily: 'Roboto',
      fontWeight: 'lighter',
      labels: {
        colors: ['rgba(255,255,255, .5)'],
      },
      itemMargin: {
        horizontal: 30,
      },
      markers: {
        width: 8,
        height: 8,
        radius: 8,
      },
    },
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 4,
        sizeOffset: 0,
      },
      discrete:
        props.risk_trend &&
        props.risk_trend.scores.map((el, i) => {
          let color;
          if (el < props.team.medium_risk_threshold) {
            color = props.colors.low;
          } else if (el >= props.team.medium_risk_threshold && el < props.team.high_risk_threshold) {
            color = props.colors.medium;
          } else {
            color = props.colors.high;
          }
          if (injuriesDays[i]) {
            color = props.colors.injured;
          }
          if (
            not_return_dateMass &&
            not_return_dateMass.length &&
            (dayjs(dayjs(props.risk_trend.dates[i]).toDate()).isAfter(dayjs(not_return_dateMass[0].date).toDate()) ||
              dayjs(dayjs(props.risk_trend.dates[i]).toDate()).isSame(dayjs(not_return_dateMass[0].date).toDate()))
          ) {
            color = props.colors.injured;
          }
          return {
            seriesIndex: 0,
            dataPointIndex: i,
            fillColor: color,
            size: 4,
            hover: {
              size: 4,
            },
          };
        }),
      onClick: function (e) {
        let dateFormat;
        if (props.club.date_format === 'D-M-Y') {
          dateFormat = 'D/MM/YYYY';
        } else if (props.club.date_format === 'M-D-Y') {
          dateFormat = 'M/D/YYYY';
        }
        let value = props.risk_trend.scores[e.target.attributes.rel.value];
        let level;
        if (value < props.team.medium_risk_threshold) {
          level = 'low';
        } else if (value >= props.team.medium_risk_threshold && value < props.team.high_risk_threshold) {
          level = 'medium';
        } else {
          level = 'high';
        }
        if (injuriesDays[e.target.attributes.rel.value] || e.target.attributes.fill.value === props.colors.injured) {
          level = 'injured';
        }
        setLevelSeeMore(level);
        setDateSeeMore(dayjs(props.risk_trend.dates[e.target.attributes.rel.value]).format(dateFormat));

        //if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        //ApexCharts.exec(`risk_chart`, 'updateOptions' , { chart: { width: "100%"}})
        //}
      },
    },
    responsive: [
      {
        breakpoint: 767,
        height: 390,
        options: {
          title: {
            style: {
              fontSize: '15px',
            },
          },
          xaxis: {
            tickAmount: 4,
            labels: {
              formatter: function (val) {
                return dayjs(val).format('M/D');
              },
            },
          },
        },
      },
    ],
    states: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      ? {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        }
      : {
          normal: {
            filter: {
              type: 'darken',
              value: 0.5,
            },
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.5,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'lighten',
              value: 0.5,
            },
          },
        },
    stroke: {
      show: true,
      curve: 'smooth',
      colors: [props.colors.white],
      width: 0.5,
    },
    title: {
      text: t('risk-trend'),
      align: 'left',
      offsetX: -10,
      style: {
        fontSize: '20px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        color: props.colors.white,
      },
    },
    tooltip: {
      enabled: false,
      shared: false,
      intersect: true,
      followCursor: false,
      theme: 'dark',
      marker: {
        show: true,
      },
      x: {
        show: false,
      },
      items: {
        display: 'none',
      },
    },
    xaxis: {
      categories: props.risk_trend && [...props.risk_trend.dates],
      type: 'datetime',
      tickAmount: 5,
      labels: {
        show: true,
        maxHeight: 40,
        style: {
          fontSize: '13px',
          fontFamily: 'Roboto',
          fontWeight: 'lighter',
          colors: 'rgba(255, 255, 255, .5)',
        },
        format: undefined,
        formatter: function (val) {
          return dayjs(val).format('MMMM DD');
        },
        datetimeUTC: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      min: 0,
      max: 0.7,
    },
  };

  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const riskTrandRanges = [];

    if (props.risk_trend && props.risk_trend.scores !== undefined) {
      const levels = [];

      for (const [index, score] of props.risk_trend.scores.entries()) {
        if (injuriesDays[index]) {
          levels.push('injured');
        } else {
          if (score < props.team.medium_risk_threshold) {
            levels.push('low');
          } else if (score >= props.team.medium_risk_threshold && score < props.team.high_risk_threshold) {
            levels.push('medium');
          } else {
            levels.push('high');
          }
        }
      }

      for (let [index, level] of levels.entries()) {
        if (riskTrandRanges.length === 0) {
          riskTrandRanges.push({
            start: props.risk_trend.dates[index],
            end: props.risk_trend.dates[index],
            level: level,
          });
        } else if (riskTrandRanges[riskTrandRanges.length - 1].level !== level) {
          riskTrandRanges[riskTrandRanges.length - 1].end = props.risk_trend.dates[index];

          riskTrandRanges.push({
            start: props.risk_trend.dates[index],
            end: props.risk_trend.dates[index],
            level: level,
          });
        } else {
          riskTrandRanges[riskTrandRanges.length - 1].end = props.risk_trend.dates[index];
        }

        if (props.risk_trend.dates[index] === props.risk_trend.dates[props.risk_trend.dates.length - 1]) {
          riskTrandRanges[riskTrandRanges.length - 1].end = dayjs(props.risk_trend.dates[index]).add(1, 'day').format('YYYY-MM-DD');
        }
      }

      let chart = am4core.create('chartdiv', am4charts.XYChart);

      //chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.paddingLeft = 10;
      chart.paddingRight = 0;
      chart.paddingTop = 0;
      chart.paddingBottom = 0;

      //chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";
      const dateMass = [];

      chart.data = riskTrandRanges.map((el, index) => {
        let endDate = el.end;
        let injury = false;
        if (not_return_dateMass && not_return_dateMass.length) {
          injury = dayjs(dayjs(not_return_dateMass[0].date).toDate()).isBefore(dayjs(el.start).toDate()) || dayjs(dayjs(not_return_dateMass[0].date).toDate()).isSame(dayjs(el.start).toDate());
        }

        if (!injury && not_return_dateMass && not_return_dateMass.length && (dayjs(el.end).isAfter(not_return_dateMass[0].date) || dayjs(el.end).isSame(not_return_dateMass[0].date))) {
          while (!dayjs(not_return_dateMass[0].date).isSame(dayjs(endDate).toDate())) {
            endDate = dayjs(endDate).add(-1, 'day').toDate();
          }

          dateMass.push({
            index,
            el: {
              start: dayjs(endDate).format('YYYY-MM-DD'),
              end: el.end,
              task: 'injured',
              category: '',
              color: props.colors.injured,
              color_grad: props.colors.injured,
            },
          });

          return {
            start: el.start,
            end: dayjs(endDate).format('YYYY-MM-DD'),
            task: injury ? 'injured' : el.level,
            category: '',
            color: injury ? props.colors.injured : props.colors[el.level],
            color_grad: injury ? props.colors.injured : props.colors[el.level + '_grad'],
          };
        }

        return {
          start: el.start,
          end: el.end,
          task: injury ? 'injured' : el.level,
          category: '',
          color: injury ? props.colors.injured : props.colors[el.level],
          color_grad: injury ? props.colors.injured : props.colors[el.level + '_grad'],
        };
      });

      dateMass.map((item) => {
        chart.data.splice(item.index, 0, item.el);
      });

      chart.dateFormatter.dateFormat = 'yyyy-MM-dd';
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'category';
      categoryAxis.dataFields.task = 'task';
      categoryAxis.renderer.inversed = true;

      categoryAxis.renderer.minGridDistance = 1;

      categoryAxis.renderer.grid.template.disabled = true;

      categoryAxis.renderer.labels.template.fill = am4core.color(props.colors.white);
      categoryAxis.renderer.labels.template.fontSize = 13;
      categoryAxis.renderer.labels.template.fontWeight = 100;

      categoryAxis.renderer.labels.template.align = 'left';

      categoryAxis.renderer.width = 1;

      categoryAxis.renderer.paddingRight = 0;

      chart.maskBullets = false;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.baseInterval = { count: 1, timeUnit: 'day' };

      dateAxis.renderer.tooltipLocation = 0;

      dateAxis.renderer.grid.template.disabled = false;

      dateAxis.renderer.opposite = true;

      dateAxis.renderer.labels.template.fill = am4core.color(props.colors.white);
      dateAxis.renderer.labels.template.fontSize = 13;
      dateAxis.renderer.labels.template.fontWeight = 100;
      dateAxis.renderer.labels.template.opacity = 0.5;

      dateAxis.renderer.grid.template.opacity = 0;
      dateAxis.renderer.ticks.template.disabled = false;
      dateAxis.renderer.ticks.template.strokeOpacity = 0;
      dateAxis.renderer.ticks.template.stroke = am4core.color(props.colors.white);
      dateAxis.renderer.ticks.template.length = 6;
      dateAxis.renderer.baseGrid.disabled = false;
      dateAxis.renderer.minGridDistance = 0;
      dateAxis.renderer.height = 0;
      dateAxis.renderer.disabled = true;
      dateAxis.renderer.paddingRight = 0;

      let series1 = chart.series.push(new am4charts.ColumnSeries());

      series1.columns.template.height = 10;

      series1.dataFields.openDateX = 'start';
      series1.dataFields.dateX = 'end';
      series1.dataFields.categoryY = 'category';
      series1.dataFields.task = 'task';
      series1.columns.template.propertyFields.fill = 'color'; // get color from data
      series1.columns.template.propertyFields.stroke = 'color';
      series1.columns.template.strokeOpacity = 1;

      series1.columns.template.column.cornerRadius(5, 5, 5, 5);
      series1.columns.template.column.paddingRight = 8;

      series1.columns.template.adapter.add('fill', (fill, target) => {
        let gradient = new am4core.LinearGradient();
        if (target.dataItem && target.dataItem.task) {
          gradient.addColor(am4core.color(props.colors[target.dataItem.task]));
          gradient.addColor(am4core.color(props.colors[target.dataItem.task + '_grad']));
        }
        if (target.dataItem && target.dataItem.task == null) {
          gradient.addColor(am4core.color(props.colors['low']));
          gradient.addColor(am4core.color(props.colors['low_grad']));
        }
        return target.dataItem ? gradient : fill;
      });

      let fillModifier = new am4core.LinearGradientModifier();
      series1.columns.template.fillModifier = fillModifier;
      series1.showOnInit = false;

      // Set cell size in pixels
      // let cellSize = 10;
      // chart.events.on("datavalidated", function (ev) {
      //     if (props.heat_map_players) {
      //         // Get objects of interest
      //         let chart = ev.target;
      //         let categoryAxis = chart.yAxes.getIndex(0);
      //
      //         // Calculate how we need to adjust chart height
      //         let adjustHeight = cellSize - categoryAxis.pixelHeight;
      //
      //         // get current chart height
      //         let targetHeight = chart.pixelHeight + adjustHeight;
      //
      //         // Set it on chart's container
      //         chart.svgContainer.htmlElement.style.height = targetHeight + "px";
      //     }
      // });

      const emptyDivs = document.querySelectorAll('body > div:not(#root)');

      if (emptyDivs) {
        emptyDivs.forEach((el) => {
          el.style.display = 'none';
        });
      }

      chartRef.current = chart;

      return () => {
        chart.dispose();
      };
    }
  }, [injuriesDays, props]);

  useEffect(() => {
    if (!props.isSidebarCollapsed) {
      ApexCharts.exec(`risk_chart`, 'updateOptions', { chart: { width: '100%' } });
    }
  }, [props.isSidebarCollapsed]);

  return (
    <>
      {props.alert ? (
        <div className={`${styles.riskTrend} ${styles.panel}`} style={props.background && { background: 'linear-gradient(#2C2F35, #2F3539)' }}>
          <div className={styles.riskTrend__wrap}>
            {dateSeeMore && (
              <div className={`${styles.riskTrend__seeMore}`}>
                <span className={`${styles.riskTrend__seeMoreDate} ${styles[levelSeeMore]}`}>{dateSeeMore}</span>
                <span
                  className={styles.riskTrend__seeMoreText}
                  onClick={() => {
                    let dateView;
                    if (props.club.date_format === 'D-M-Y') {
                      dateView = dayjs(dateSeeMore, 'D/MM/YYYY').format('YYYY-MM-DD') + 'T12:00:00';
                    } else if (props.club.date_format === 'M-D-Y') {
                      dateView = dayjs(dateSeeMore, 'M/D/YYYY').format('YYYY-MM-DD') + 'T12:00:00';
                    }
                    props.handleDate(dateView);
                    props.viewDate(dateView);
                  }}
                >
                  {t('see-more')}
                </span>
              </div>
            )}
            <ul className={styles.riskTrendLegend}>
              {riskTrendLevels.map((el) => {
                return (
                  <li key={el} className={`${styles.riskTrendLegend__item} ${styles[el]} ${styles.dot}`}>
                    <div className={styles.riskTrendLegend__name}>{t(el)}</div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.riskTrend__apexchart}>
            <Chart options={riskTrendOptions} series={riskTrendSeries} type="line" height={300}></Chart>
          </div>
          <div
            id="chartdiv"
            style={{
              width: '100%',
              height: '40px',
              marginTop: '-26px',
            }}
          ></div>
        </div>
      ) : (
        <div className={`${styles.panel} ${styles.noData}`}>{t('no-data-is-available-for-this-player')}</div>
      )}
    </>
  );
}
