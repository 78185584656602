import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from '../../Preloader/Preloader';
import { colors } from '../PlayerInfo/PlayerInfo';
import RiskTrend from '../RiskTrend/RiskTrend';
import { fetchRadarData } from '../../../utils/helpers';
import styles from '../RiskTrend/RiskTrend.module.scss';

export default function PlayerRiskTrendContainer(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id, date } = useParams();
  let history = useHistory();

  // useEffect(() => {
  //         async function f() {
  //             const radarData = await fetchRadarData(history, setLoading, id, date);
  //             setData(radarData);
  //         }
  //
  //         f();
  //     }
  //     , [history, setLoading, id, date]);

  useEffect(() => {
    function handleEvent(message) {
      setData(message.data);
      setLoading(false);
    }

    setLoading(true);

    window.addEventListener('message', handleEvent);

    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return (
    <>
      {loading ? (
        <div className={`${styles.riskTrend} ${styles.panel}`} style={props.background && { background: 'linear-gradient(#2C2F35, #2F3539)' }}></div>
      ) : (
        <RiskTrend {...data} colors={{ ...colors }} isSidebarCollapsed={true} background={true} />
      )}
    </>
  );
}
