import axios from 'axios';

export const host = process.env.REACT_APP_API_HOST;
const baseURL = `${host}/core/api`;

export default axios.create({
  baseURL,
  withCredentials: true,
  responseType: 'json',
});
