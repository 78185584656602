import React, { useCallback, useEffect, useState } from 'react';
import API from '../../utils/API';
import Layout from '../../components/layout/layout';
import styles from './players.module.scss';
import Preloader from '../../components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';
import { HandleError, handleRedirect } from '../../utils/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import PlayerItem from '../../components/Players/PlayerItem/PlayerItem';
import MultiSelect from '../../components/Players/MultiSelect/MultiSelect';
import { CSVLink } from "react-csv";
import { sendAmplitudeData } from '../../utils/amplitude';


export default function Players(props) {
  const [isMobile, setIsMobile] = useState(false);
  const [players, setPlayers] = useState([]);
  const [mediumRiskThreshold, setMediumRiskThreshold] = useState(null);
  const [team, setTeam] = useState([]);
  const [lastUpdate, setLastUpdate] = useState('');
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredPosition, setFilteredPosition] = useState([]);
  const [filteredRisk, setFilteredRisk] = useState([
    { value: 'high', label: 'High' },
    { value: 'medium', label: 'Medium' },
    { value: 'contained', label: 'Contained' },
  ]);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      console.log('isMobile');
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  //options for risk select
  const riskOptions = [
    { value: 'high', label: 'High' },
    { value: 'medium', label: 'Medium' },
    { value: 'contained', label: 'Contained' },
    { value: 'low', label: 'Low' },
    { value: 'injured', label: 'Unavailable' },
  ];

  //options for position select
  const positionOptions = positions;

  //custom Style for risk select desktop
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'white',
      padding: '10px 20px',
      fontSize: '15px',
      ':hover': {
        backgroundColor: '#26292E',
        
      },
      backgroundColor: 'transparent'
    }),
    container: (provided) => ({
      ...provided,
       marginRight: isMobile? '0' : '20px',
       marginBottom: isMobile? '15px' : '0',
    }),
    valueContainer: (provided) => ({
      ...provided,
      background: 'linear-gradient(180deg, #2C2F35 0%, #1F2326 100%)',
      boxShadow: '0px 0px 20px 3px rgb(0 0 0 / 30%)',
      borderRadius: '84px',
      display: 'flex',
      padding: '0 24px',
      width: '100%',
    }),
    control: () => ({
      minWidth: 200,
      display: 'flex',
      position: 'relative',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    menu: (provided) => ({
      ...provided,
      background: '#191A1E',
      boxShadow: ' 0px 2px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '11px',
      display: 'block',
      width: '200px',
      position: 'absolute',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      position: 'absolute',
      top: '0',
      right: '0',
      display: 'flex',
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '15px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      color: 'white',
      marginRight: '5px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided, state) => {
      const hover = 'color green';

      return { ...provided, hover };
    },
    clearIndicator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'white',
    }),
    
    menuList: (provided) => ({
      ...provided,
     
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '85%'
     
    }),
    
  };


  const fillPositions = (players) => {
    let positionsArr = [...positions];
    positionsArr = players.map((player) => {
     return ({ value: player.alert.player.position_long, label: player.alert.player.position_long })
    });

    //remove duplicate array object from position array
    const values = positionsArr.map(g => g.value);
    const filteredValues = positionsArr.filter(({value}, index) => !values.includes(value, index + 1));
    setPositions(filteredValues);
  };

  const filterRiskChangeHandler = (selectedRisk) => {
    setFilteredRisk(selectedRisk);
    sendAmplitudeData(`filter by risk`, { detail: selectedRisk })
  };

  const filterPositionChangeHandler = (selectedPosition) => {
    setFilteredPosition(selectedPosition);
    sendAmplitudeData(`filter by position`, { detail: selectedPosition })
  };

  const resetFilters = () => {
    setFilteredRisk([
      { value: 'high', label: 'High' },
      { value: 'medium', label: 'Medium' },
      { value: 'contained', label: 'Contained' },
    ]);
  
    setFilteredPosition([]);
  };


  let history = useHistory();
  let location = useLocation();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize)
  }, []);

  useEffect(() => {
    let filtered = players;
    if (filteredRisk.length>0) {
      filtered = filtered.filter((player) => {
        for (let i = 0; i < filteredRisk.length; i++) {
          if (player.alert.level === filteredRisk[i].value) {
            return true;
          }
        }
        return false;
      });
    }

    if (filteredPosition.length > 0) {
      filtered = filtered.filter((player) => {
        for (let i = 0; i < filteredPosition.length; i++) {
          if (player.alert.player.position_long === filteredPosition[i].value) {
            return true;
          }
        }
        return false;
      });
    }
    
    if(filteredRisk.length === 0 && filteredPosition.length === 0){
      setFilteredPlayers(players);
    } else {
      setFilteredPlayers(filtered);
    }

    
  }, [ players,filteredRisk ,filteredPosition ]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      // TODO: take this from router params (used for navigation from teams page)
      const parts = location.pathname.split('/');
      const suffix = parts.slice(3)[0]; 
      const apiURL = suffix ? `/players/${suffix}/` : '/players/';
      const response = await API.get(apiURL);
      
      if (response.status === 200) {
        handleRedirect(response, history);
        setPlayers(response.data.alerts);
        setFilteredPlayers(response.data.alerts);
        setMediumRiskThreshold(response.data.team.medium_risk_threshold);
        setTeam(response.data.team);
        fillPositions(response.data.alerts);
        setLastUpdate(response.data.team.last_update);
      }
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status) {
        HandleError(e, history);
      }
      console.log('Error fetch players data: ', e);
    }
  }, [setPlayers]);


  // for csv file
  var headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Risk Category", key: "level" },
    { label: "Date", key: "date" },
  ];
  
  var data = fillCsvData();
 
  var csvReport = {
    data: data,
    headers: headers,
    filename: ['Zone7_players_risk_level.csv']
  };

  function fillCsvData(){
    const csvData = [];

      // add distance col only for teams with enable_risk_export == true
      if(team.enable_risk_export){
      headers.push({ label: "Distance from medium risk threshold", key: "distance" });
    }

    if(players && players.length>0){
      for(let i=0; i<players.length; i++){
        csvData.push({'firstName': players[i].alert.player.first_name , 'lastName': players[i].alert.player.last_name,
      'level': players[i].alert.level==='injured'? 'Unavailable': (players[i].alert.level).charAt(0).toUpperCase()+ (players[i].alert.level).slice(1), 'date': players[i].alert.date, 'distance': mediumRiskThreshold !== null && players[i].alert.level==='low'?  ((mediumRiskThreshold-players[i].alert.score)* 100).toFixed(1) + '%': ''})
      }

    }
    return csvData
  }

  return (
    <Layout isSidebarCollapsed={props.isSidebarCollapsed} changeCollapseSidebar={props.changeCollapseSidebar} user={props.user} userData={props.userData}>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.playersContainer}>
          <HeaderComponent isSidebarCollapsed={props.isSidebarCollapsed} title={t('Players')}  lastUpdateTitle={'last-updated'} lastUpdateDate={lastUpdate}/>

          <div className="mainContent">
            <div className={styles.actionBar}>
              <div className={styles.actionBar__filters}>
              <MultiSelect 
                className="selectFilter" 
                onChangeFilter={filterRiskChangeHandler} 
                options={riskOptions} 
                customStyles={customStyles } 
                selected={filteredRisk}
                placeholder="Select.."
                label="Risk">
              </MultiSelect>
              <MultiSelect 
                className="selectFilter" 
                onChangeFilter={filterPositionChangeHandler} 
                options={positionOptions} 
                customStyles={customStyles} 
                selected={filteredPosition}
                placeholder="Select.."
                label="Position">
              </MultiSelect>
           
              </div>
              <div className={styles.actionBar__secondaryActions}>
                <div className={styles.actionBar__action} onClick={resetFilters}>
                  <img src="/static/images/icons/filter_list_off.svg" alt="filter-off" /> Reset
                </div>
                <div className={styles.actionBar__action} >
                  <img src="/static/images/icons/file_upload.svg" alt="export" />
                  <CSVLink className={styles.csvButton} {...csvReport} onClick={() => { sendAmplitudeData(`players/export risk levels`, { detail: props.user.club })}}>
                      <button>Export Risk Levels</button>
                  </CSVLink> 
                </div>
              </div>
            </div>
            <div className={styles.alertGroups}>
              {filteredPlayers.length > 0 ? (
                filteredPlayers.map((el) => {
                  return (
                    <div key={el.alert.player.id}>
                      <PlayerItem player={el} isMobile={isMobile}/>
                    </div>
                  );
                })
              ) : (
                <div className={styles.noPlayersWrap}>
                  <img className={styles.accountImg} src="/static/images/icons/account_circle.svg" alt="account" />
                  <div className={styles.noPlayers}>No players found</div>
                  <button className={styles.resetBtn} onClick={resetFilters}>
                    <img src="/static/images/icons/filter_list_off.svg" alt="filter-off" /> Reset filters
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
