import React, { useCallback, useEffect, useState, Fragment } from 'react';
import styles from './TeamsComponent.module.scss';
import API, { host } from '../../utils/API';
import { ROUTES } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import Preloader from '../Preloader/Preloader';
import { sendAmplitudeData } from '../../utils/amplitude';

export default function TeamsComponent(props) {
  const [active, setActive] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [players, setPlayers] = useState([]);
  const [togglePlayerActive, setTogglePlayerActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [successActivate, setSuccessActivate] = useState(null);
  const [playersBeforeSearch, setPlayersBeforeSearch] = useState([]);
  const [currentTeamId, setCurrentTeamId] = useState(null);
  const [status, setStatus] = useState('');
  const [arrowUrl, setArrowUrl] = useState('url(/static/images/icons/activation_status_arrow.svg)');

  const csrf_token = localStorage.getItem('csrftoken');
  //const team_id = props && props.teams && props.teams['0'] && props.teams['0'].team && props.teams['0'].team.id;

  const getCloseModal = useCallback(() => {
    setIsModalOpen(null);
    setTogglePlayerActive(null);
    setSuccessActivate(null);
  }, []);

  const getNewToken = useCallback(async () => {
    try {
      const response = await API.get(`/refresh_token/`, { withCredentials: true });

      if (response.status === 200) {
        localStorage.setItem('csrftoken', response.data.csrf_token);
      }
    } catch (e) {
      console.log('Error when getting a new token ', e);
    }
  }, []);

  const fetchPlayers = useCallback(async (team_id, localPage, status = '', filter = '') => {
    const options = {
      withCredentials: true,
      headers: { 'X-CSRFToken': csrf_token },
    };
    let url = `/player_status/${team_id}/?page=${localPage}`;
    if (status) url = `player_status/${team_id}/?sort=status&direction=${status}&page=${localPage}`;
    if (filter && !status) {
      url = `/player_status/${team_id}/?filter=${filter}`;
    } else {
      url += `&filter=${filter}`;
    }
    try {
      if (team_id && !search) {
        const response = await API.get(url, options);
        if (response.status === 200) {
          return response.data;
        }
      }
    } catch (e) {
      if (e.response.status === 403) {
        await getNewToken();
      }
      console.log('Error with fetching players in team', e);
    }
  }, []);

  const searchPlayer = useCallback(
    async (text, team_id) => {
      // setLoading(true);
      const options = {
        withCredentials: true,
        headers: { 'X-CSRFToken': csrf_token },
      };
      try {
        if (team_id) {
          const response = await API.get(`/player_status/${team_id}/?filter=${text}`, options);
          if (response.status === 200) {
            // setPlayersBeforeSearch(players);
            setPlayers([{ team_id: team_id, data: response.data }]);
            setPage(response.data.page);
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.status === 403) {
          await getNewToken();
        }
        console.log('Error with searching players in team', e);
      }
    },
    [players],
  );

  const setActivationStatus = useCallback(
    async (player_id, activationStatusChecked, team_id) => {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append('player', player_id);
        formData.append('activate', activationStatusChecked.toString());
        const options = {
          withCredentials: true,
          headers: { 'X-CSRFToken': csrf_token },
        };
        const response = await API.post(`/submit_player_status/`, formData, options);
        if (response.status === 200) {
          setTogglePlayerActive(true);
          if (searchValue && searchValue.length > 0) {
            await searchPlayer(searchValue, team_id);
          } else {
            await fetchPlayers(team_id, page);
          }
          setSuccessActivate(activationStatusChecked);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log('Error in activate player ', e.message);
      }
    },
    [searchValue, page],
  );

  const changeArrow = useCallback(() => {
    if (!status) {
      setArrowUrl('url(/static/images/icons/activation_status_arrow_dsc.svg)');
      return 'dsc';
    } else if (status === 'dsc') {
      setArrowUrl('url(/static/images/icons/activation_status_arrow_ads.svg)');
      return 'asd';
    } else {
      setArrowUrl('url(/static/images/icons/activation_status_arrow.svg)');
      return '';
    }
  }, [status]);

  const { t, i18n } = useTranslation();

  const addOrRemove = useCallback((arr, item) => (arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]));

  const togglePanel = useCallback(
    async (id, name) => {
      //if (active.length > 0) {
      //setPlayers([])
      //} else {
      //setPlayers(playersBeforeSearch);
      //    setPage(1);
      //}
      if (name) {
        console.log(`event: expend details Team name: ${name}`);
        sendAmplitudeData(`expend details`, { detail: `Team name: ${name}` });
        console.log(`event: switched teams "Teams"`);
        sendAmplitudeData(`switched teams`, { detail: `Team name: ${name}` });
      }
      props.setCurrentPanel(id);
      const res = await fetchPlayers(id, 1);
      setPlayers([{ team_id: id, data: res }]);
      setPlayersBeforeSearch([{ team_id: id, data: res }]);
      // setPlayers([...players, { team_id: id, data: res }]);
      // setPlayersBeforeSearch([...players, { team_id: id, data: res }]);
      setActive([id]);
      setSearch(false);
      setSearchValue('');
    },
    [active, players, props],
  );

  useEffect(() => {
    let data = [];
    setLoading(true);
    props &&
      props.teams &&
      props.teams.length > 0 &&
      props.teams.forEach(async (el) => {
        const res = await fetchPlayers(el.team.id, 1);
        data = [...data, { team_id: el.team.id, data: res }];
        setPlayers(data);
        setPlayersBeforeSearch(data);
      });
    if (props && props.teams && props.teams.length > 0) {
      const id = props.currentPanel ? props.currentPanel : props.teams[0].team.id;
      setArrowUrl('url(/static/images/icons/activation_status_arrow.svg)');
      setStatus('');
      setSearchValue('');
      setSearch(false);
      setCurrentTeamId(id);
      togglePanel(id);
    }
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader style={{ marginTop: '150px' }} />
      ) : (
        <div className={styles.teams}>
          {props &&
            props.teams &&
            props.teams.length > 0 &&
            props.teams.map((el) => {
              return (
                <div key={el.team.id} className={styles.team__wrapper}>
                  <div className={`${styles.team} ${styles.panel}`}>
                    <Link to={`${ROUTES.PLAYERS}/${el.team.id}`}>
                      <div className={styles.team__title}>{el.team.name}</div>
                    </Link>
                    <div className={styles.accordionWrap}>
                      <button
                        className={styles.accordion}
                        onClick={() => {
                          setArrowUrl('url(/static/images/icons/activation_status_arrow.svg)');
                          setStatus('');
                          setSearchValue('');
                          setSearch(false);
                          setCurrentTeamId(el.team.id);
                          active.includes(el.team.id) ? setActive([]) : togglePanel(el.team.id, el.team.name);
                        }}
                      >
                        {active.includes(el.team.id) ? <img src="/static/images/icons/minus.svg" /> : <img src="/static/images/icons/plus.svg" />}
                      </button>
                    </div>
                    <div className={styles.team__numbers}>
                      <div className={`${styles.team__numbersItem} ${el.at_risk > 0 ? styles.high : styles.low}`}>
                        <div className={`${styles.team__number} ${styles.team__numberRisk}`}>{el.at_risk}</div>
                        <div className={`${styles.team__numberTitle} ${styles.team__titleRisk}`}>{t('players-at-risk')}</div>
                      </div>
                      <div className={styles.team__numbersItem}>
                        <div className={`${styles.team__number} ${styles.team__numberActive}`}>{el.active_count}</div>
                        <div className={`${styles.team__numberTitle} ${styles.team__titleActive}`}>{t('active-players')}</div>
                      </div>
                    </div>

                    {props && props.user && props.user.is_data_manager && currentTeamId && props.club.enable_player_status ? (
                      <>
                        {players &&
                          !!players.length &&
                          players.filter((team) => !!team.data).length > 0 &&
                          players.filter((team) => team.team_id === el.team.id).length > 0 &&
                          players.filter((team) => team.team_id === el.team.id)[0].data.player && (
                            <div className={`${styles.players} ${active.includes(el.team.id) && currentTeamId === el.team.id && styles.active}`}>
                              <div className={styles.team__search_block}>
                                <input
                                  className={styles.team__search_input}
                                  type="text"
                                  placeholder={t('Type Player Name')}
                                  value={searchValue}
                                  onChange={async (e) => {
                                    if (e.target.value[0] !== ' ') {
                                      if (e.target.value.length >= 725) {
                                        setSearchValue(e.target.value.trim().substr(0, 725));
                                        const res = await fetchPlayers(el.team.id, page, status, e.target.value.trim().substr(0, 725));
                                        setPlayers([{ team_id: el.team.id, data: res }]);
                                      } else {
                                        setSearchValue(e.target.value);
                                        const res = await fetchPlayers(el.team.id, page, status, e.target.value);
                                        setPlayers([{ team_id: el.team.id, data: res }]);
                                      }
                                    }
                                  }}
                                  onKeyDown={async (e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                      const res = await fetchPlayers(el.team.id, page, status, searchValue.trim().substr(0, 725));
                                      setPlayers([{ team_id: el.team.id, data: res }]);
                                      setSearch(true);
                                    }
                                  }}
                                />
                                <button
                                  className={styles.team__search_button}
                                  style={{ backgroundImage: `url(/static/images/icons/search.svg)` }}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    const res = await fetchPlayers(el.team.id, page, status, searchValue);
                                    setPlayers([{ team_id: el.team.id, data: res }]);
                                    setSearch(true);
                                  }}
                                />
                              </div>

                              {players &&
                                players.filter((team) => team.team_id === el.team.id).length > 0 &&
                                players.filter((team) => !!team.data).length &&
                                players.filter((team) => team.team_id === el.team.id)[0].data.player.length > 0 && (
                                  <div className={styles.players__title}>
                                    <div className={styles.players__titlePlayer}>{t('Player')}</div>
                                    <div className={styles.players__titlePosition}>{t('Position')}</div>
                                    <div className={styles.players__titleToggle}>
                                      <button
                                        className={`${styles.team__search_button} ${styles.sortArrow}`}
                                        style={{ backgroundImage: `${arrowUrl}` }}
                                        onClick={async (e) => {
                                          e.preventDefault();
                                          let status = changeArrow();
                                          const res = await fetchPlayers(el.team.id, 1, status);
                                          setPlayers([{ team_id: el.team.id, data: res }]);
                                          setStatus(status);
                                          setPage(1);
                                        }}
                                      />
                                      {t('Active')}
                                    </div>
                                  </div>
                                )}

                              {players &&
                                players.filter((team) => team.team_id === el.team.id).length > 0 &&
                                players
                                  .filter((team) => team.team_id === el.team.id)[0]
                                  .data.player.map((alert, index) => {
                                    return (
                                      <div
                                        key={alert.id}
                                        className={`${styles.player} ${styles.cursor} ${
                                          index === players.filter((team) => team.team_id === el.team.id)[0].data.player.length - 1 ? styles.border_botton_none : ''
                                        }`}
                                      >
                                        <div className={styles.player__details_data_manager}>
                                          <div className={styles.player__wrap_data_manager}>
                                            <div className={styles.player__title}>{`${alert.first_name} ${alert.last_name}`}</div>
                                          </div>
                                          <div className={styles.player__levelList_data_manager}>
                                            <p className={styles.player__level}>{t(alert.position.name)}</p>
                                          </div>
                                          <div className={styles.player__levelList_data_manager}>
                                            {/*Button block*/}

                                            <div
                                              className={styles.btn_simulator}
                                              onClick={() => {
                                                setCurrentPlayer({ ...alert, team_id: el.team.id });
                                                setIsModalOpen(true);
                                              }}
                                            >
                                              <div className={alert.active ? `${styles.btn__switch_case} ${styles.btn__end}` : `${styles.btn__switch_case} ${styles.btn__start}`}>
                                                <div className={styles.btn__switch_case_for}>
                                                  <span className={alert.active ? styles.btn__switch_case_for_on : styles.btn__switch_case_for_off} />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                              <div className={styles.pagination__buttons_block}>
                                <div className={styles.pagination__buttons_wrap}>
                                  <div className={styles.pagination__text}>
                                    {`${t('Page')} ${players.filter((team) => team.team_id === el.team.id)[0].data.page} ${t('of')} ${
                                      players.filter((team) => team.team_id === el.team.id)[0].data.total_pages
                                    } `}
                                  </div>
                                  <div className={styles.pagination__buttons}>
                                    <button
                                      className={`${styles.pagination__button} ${page === 1 && styles.opacity}`}
                                      onClick={async () => {
                                        if (page > 1) {
                                          const res = await fetchPlayers(el.team.id, page - 1, status);
                                          setPlayers([{ team_id: el.team.id, data: res }]);
                                          setPage(page - 1);
                                          setSearch(false);
                                        }
                                      }}
                                    >
                                      <img src="/static/images/icons/arrow-paginate.svg" className={`${styles.arrow_rotate} ${page === 1 && styles.opacity}`} />
                                    </button>
                                    <button
                                      className={`${styles.pagination__button} ${page === players.filter((team) => team.team_id === el.team.id)[0].data.total_pages && styles.opacity}`}
                                      onClick={async () => {
                                        if (page < players.filter((team) => team.team_id === el.team.id)[0].data.total_pages) {
                                          const res = await fetchPlayers(el.team.id, page + 1, status);
                                          setPlayers([{ team_id: el.team.id, data: res }]);
                                          setPage(page + 1);
                                          setSearch(false);
                                        }
                                      }}
                                    >
                                      <img src="/static/images/icons/arrow-paginate.svg" className={`${page === players.total_pages && styles.opacity}`} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        {el.alerts && (
                          <div className={`${styles.players} ${active.includes(el.team.id) && styles.active}`}>
                            {el.alerts && el.alerts.length > 0 && (
                              <div className={styles.players__title_user}>
                                <div className={styles.players__title_userPlayer}>{t('Player')}</div>
                                <div className={styles.players__title_userLevel}>{t('risk-level')}</div>
                              </div>
                            )}
                            {el.alerts.map((alert) => {
                              return (
                                <div key={alert.player.id} className={`${styles.player}`}>
                                  <div className={styles.player__details}>
                                    <Link to={`${ROUTES.PLAYER}/${alert.player.id}`}>
                                      <div className={styles.player__wrap}>
                                        <div className={styles.player__image}>
                                          {alert.player.has_image ? (
                                            <img src={`${host}/core/api/player_image/${alert.player.id}/`} />
                                          ) : (
                                            <div className={`${styles.player__imageInitials} ${styles[alert.level]}`}>{alert.player.initials}</div>
                                          )}
                                        </div>
                                        <div className={styles.player__title}>{`${alert.player.first_name} ${alert.player.last_name}`}</div>
                                      </div>
                                      <div className={styles.player__levelList}>
                                        <ul>
                                          <li className={`${styles.player__level} ${styles[alert.level]} ${styles.dot}`}>{t(alert.level)}</li>
                                        </ul>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}

          {isModalOpen && (
            <Modal isSidebarCollapsed={props.isSidebarCollapsed} isOpen={isModalOpen} maxWidth={399} minHeight={194} onClose={getCloseModal} display={'none'}>
              <div className={styles.local__modal_header}>
                {currentPlayer?.first_name} {currentPlayer?.last_name}
              </div>
              <div className={styles.local__modal_line} />
              <div className={styles.local__modal_title}>
                When you do this, {currentPlayer?.first_name} {currentPlayer?.last_name}'s status will be set to <b>{currentPlayer?.active ? 'not active' : 'active'}</b>.
              </div>
              <div className={styles.local__modal_buttons_wrapper}>
                <div className={styles.local__modal_buttons_block} style={{ maxWidth: window.matchMedia('(max-width: 425px)').matches && '95%' }}>
                  <button
                    className={styles.local__modal_button}
                    onClick={() => {
                      getCloseModal();
                    }}
                  >
                    {t('Cancel')}
                  </button>
                  <button
                    className={styles.local__modal_button}
                    onClick={() => {
                      setActivationStatus(currentPlayer.id, !currentPlayer.active, currentPlayer.team_id);
                      getCloseModal();
                    }}
                  >
                    {t('Confirm')}
                  </button>
                </div>
              </div>
            </Modal>
          )}

          {!isModalOpen && togglePlayerActive && (
            <Fragment>
              <div
                className={`${styles.injury_modal__popup_substrate} ${togglePlayerActive ? styles.block : styles.none}`}
                onClick={() => {
                  props.fetchData();
                  setTogglePlayerActive(false);
                }}
              />
              <Modal isSidebarCollapsed={props.isSidebarCollapsed} isOpen={togglePlayerActive} maxWidth={399} minHeight={194} onClose={getCloseModal} display={'flex'}>
                <div className={styles.local__modal_header}>{`${successActivate ? t('Request Submitted') : `${currentPlayer?.first_name} ${currentPlayer?.last_name} ${t('Deactivated')}`}`}</div>
                <div className={styles.local__modal_line} />
                <div className={styles.local__modal_title}>{`${currentPlayer?.first_name} ${currentPlayer?.last_name}`}</div>
                <div className={styles.local__modal_icons_block}>{successActivate ? <img src="/static/images/icons/ok-icon.svg" /> : <img src="/static/images/icons/ok-icon-red.svg" />}</div>
              </Modal>
            </Fragment>
          )}
        </div>
      )}
    </>
  );
}
