import React, { useState, useEffect} from 'react';
import dayjs from 'dayjs';
import Modal from '../../Modal/Modal';
import styles from './Context.module.scss';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../Tooltip/Tooltip';

export default function Context(props) {
  const [modalDateItem, setModalDateItem] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [hasDrills, setDrills] = useState(false);

  const { t, i18n } = useTranslation();

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };

  function foundDrills(){
    if(props.recommended_dates && props.recommended_dates.length > 0){
      for(let i=0; i<props.recommended_dates.length; i++){
        if(props.recommended_dates[i].sessions && props.recommended_dates[i].sessions.length>0)
          for(let j=0; j<props.recommended_dates[i].sessions.length; j++){
            if(props.recommended_dates[i].sessions[j].drills && props.recommended_dates[i].sessions[j].drills.length>0){
              setDrills(true);
            }
          }
      }
    }
  }

  useEffect(() => {
    foundDrills();
  }, [""]);



  return (
      <>
        {props.recommended_dates && props.recommended_dates.length > 0 && (
            <div className={`${styles.contextReference} ${styles.panel} ${props.micro_cycle && styles.micro_cycle}`}>
              {/*<div className={styles.context}>*/}
              {/*    <div className={styles.context__title}>*/}
              {/*        <span>{t('Context')}</span>*/}
              {/*        <span className={styles.player__tooltip}*/}
              {/*              onClick={(e) => toggleTooltip(*/}
              {/*                  {*/}
              {/*                      clientX: e.clientX,*/}
              {/*                      clientY: e.clientY,*/}
              {/*                      title: props.tooltips.risk_context.title,*/}
              {/*                      content: props.tooltips.risk_context.text*/}
              {/*                  }*/}
              {/*              )*/}
              {/*              }*/}
              {/*        >i</span>*/}
              {/*    </div>*/}
              {/*    <div className={styles.context__next}>*/}
              {/*        <div className={styles.context__nextTitle}>*/}
              {/*            {t('next-session') + ': ' + props.next_session}*/}
              {/*        </div>*/}
              {/*        <div className={styles.context__nextShort} dangerouslySetInnerHTML={{__html: props.training_status }}>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              {
                <div className={styles.reference}>
                  <div className={styles.reference__title}>{t('reference-sessions')}
                    { hasDrills ?  <span className={styles.reference__click}> ({t('click-to-expand')})</span> : "" }
                  </div>
                  <div className={`${styles.reference__dates} ${props.micro_cycle && styles.micro_cycle}`}>
                    {props.recommended_dates.map((el) => {
                      return (
                          <div
                              key={el.date}
                              className={`${styles.reference__date} 
                       ${styles.panel}
                       ${el.sessions && el.sessions.length > 0 && el.sessions[0].drills.length > 0 && styles.reference__dateActive}`}
                              onClick={() => {
                                el.sessions && el.sessions.length > 0 && el.sessions[0].drills.length > 0 && setModalDateItem(el);
                              }}
                          >
                            {dayjs(el.date).format('MMMM DD, YYYY')}
                          </div>
                      );
                    })}
                    {!!modalDateItem && (
                        <Modal isSidebarCollapsed={props.isSidebarCollapsed} isOpen={!!modalDateItem} onClose={() => setModalDateItem(null)}>
                          <div className={styles.recommended}>
                            <div className={styles.recommended__total}>
                              <div className={styles.recommended__date}>{dayjs(modalDateItem.date).format('MMMM DD, YYYY')}</div>
                              {modalDateItem.sessions && modalDateItem.sessions.length > 0 && (
                                  <div className={styles.recommended__time}>
                                    <div className={styles.recommended__startTime}>{t('start-time') + ': ' + modalDateItem.sessions[0].session_start_time}</div>
                                    <div className={styles.recommended__totalDuration}>{t('total-duration-fd747cf7093bd6b5c8eb3b40658a3b13') + ': ' + modalDateItem.sessions[0].session_duration}</div>
                                  </div>
                              )}
                            </div>
                            <div className={styles.recommended__drills}>
                              <div className={styles.recommended__drill}>
                                <div className={styles.recommended__name}>{t('Drill')}</div>
                                <div className={styles.recommended__duration}>{t('Duration')}</div>
                              </div>
                              {modalDateItem.sessions &&
                                  modalDateItem.sessions.length > 0 &&
                                  modalDateItem.sessions[0].drills.map((drill) => {
                                    return (
                                        <div key={drill.name} className={styles.recommended__drill}>
                                          <div className={`${styles.recommended__name} ${styles.recommended__item}`}>{drill.name}</div>
                                          <div className={`${styles.recommended__duration} ${styles.recommended__item}`}>{`${Math.round(drill.duration)} ${t('Minutes')}`}</div>
                                        </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </Modal>
                    )}
                  </div>
                </div>
              }
            </div>
        )}
        {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
      </>
  );
}
