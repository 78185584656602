import { useTranslation } from 'react-i18next';
import styles from './PlayerSummary.module.scss';
import Chart from 'react-apexcharts';
import React from 'react';

const playerSummaryItems = {
  low_count: 'low-risk',
  contained_count: 'contained-risk',
  risk_count: 'medium-or-high-risk',
  injured_count: 'Injured',
};

export default function PlayerSummary(props) {
  const optionsRadialBar = {
    chart: {
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: '60%',
          background: 'transparent',
          position: 'back',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: 'rgba(255, 255, 255, .1)',
          strokeWidth: '70%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: false,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [props.colors.gray],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
  };

  const percentage = Object.keys(playerSummaryItems).map((el) => ({
    name: el,
    percent: Math.round((props[el] / props.total_count) * 100),
  }));

  //rounding percents to 100 by biggest percent
  let percentsSum = percentage.reduce((sum, el) => {
    return sum + el.percent;
  }, 0);
  if (percentsSum !== 100) {
    percentage.sort((a, b) => {
      return a.percent < b.percent;
    });
    percentage[0].percent = percentage[0].percent + 100 - percentsSum;
  }

  const { t, i18n } = useTranslation();

  return (
    <>
      {props.total_count && (
        <div className={`${styles.playerSummary} ${styles.panel}`} style={props.background && { background: 'linear-gradient(#2C2F35, #2F3539)' }}>
          <div className={styles.playerSummary__title}>{t('player-summary')}</div>
          <div className={styles.playerSummary__items}>
            {Object.keys(playerSummaryItems).map((el) => {
              const percent = percentage.filter((e) => e.name === el)[0].percent;
              return (
                <div key={el} className={styles.playerSummaryItem}>
                  <div className={styles.playerSummaryItem__chart}>
                    <Chart
                      series={[percent]}
                      options={{
                        ...optionsRadialBar,
                        fill: {
                          type: 'gradient',
                          gradient: {
                            shade: 'dark',
                            type: 'horizontal',
                            shadeIntensity: 0.5,
                            gradientToColors: [props.colors[el]],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                            colorStops: [
                              {
                                offset: 0,
                                color: props.colors[el + '_grad'],
                              },
                              {
                                offset: 100,
                                color: props.colors[el],
                              },
                            ],
                          },
                        },
                      }}
                      type="radialBar"
                      height={120}
                      width={70}
                    />
                  </div>
                  <div className={styles.playerSummaryItem__description}>
                    <div className={`${styles.playerSummaryItem__percent} ${styles[el]}`}>{percent}%</div>
                    <div className={`${styles.playerSummaryItem__title} ${styles[el]}`}>{t(playerSummaryItems[el])}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
