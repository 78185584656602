import styles from './login.module.scss';
import React, { useState, useCallback } from 'react';
import API, { host } from '../../utils/API';
import { ROUTES } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { HandleError } from '../../utils/helpers';

export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const {setUser, setUserData} = props.setUser;

  let history = useHistory();

  const { t, i18n } = useTranslation();

  
  const login = useCallback(async () => {
    try {
      API.defaults.withCredentials = true;

      const dataLogin = await API.get('/login/', { withCredentials: true });
      const csrf_token = dataLogin.data['csrf_token'];

      const options = {
        withCredentials: true,
        headers: { 'X-CSRFToken': csrf_token },
      };

      const payload = {
        username: email,
        password: password,
      };

      const dataAuth = await API.post('/authenticate/', { ...payload }, options);
      if (dataAuth.status === 200) {
        if (dataAuth.data.authenticated === 'no') {
          setError('no');
        } else {
          localStorage.setItem('csrftoken', dataAuth.data.csrf_token);
          localStorage.setItem('userPersonalData', JSON.stringify(dataAuth.data.user));
          localStorage.setItem('clubData', JSON.stringify(dataAuth.data));

          setUserData(dataAuth.data);
          setUser(dataAuth.data.user);
          
          history.push(ROUTES.PLAYERS);
          // if(dataAuth.data.club.ui_version === "1.0"){
          //   history.push(ROUTES.NEW_PLAYERS);
          // } else {
          //   history.push(ROUTES.PLAYERS);
          // }
          
        }
      }
    } catch (e) {
      // if (e.response && e.response.status) {
      //     HandleError(e, history);
      // }
      setError('no');
      console.log('Error login: ', e);
    }
  }, [email, password]);

  return (
    <div className={styles.container}>
      <div className={styles.login}>
        <div className={styles.logo}>
          <img alt="logo" src="/static/images/logo.png" />
          <span>{t('Intelligent-Sports-Performance')}</span>
        </div>
        <div className={styles.loginWrapper}>
          <div className={styles.error}>{error ? t('your-username-and-password-did-not-match-please-try-again') : ''}</div>
          <div className={styles.email}>
            <input
              type="text"
              placeholder="username"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="email"
            />
          </div>
          <div className={styles.password}>
            <input
              type="password"
              placeholder="password"
              required
              value={password}
              id="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              name="password"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  login();
                }
              }}
            />
          </div>
        </div>
        <button className={styles.loginButton} href="/login" onClick={login}>
          <span>{t('Login')}</span>
        </button>
        <p className={styles.forgot}>
          <Trans i18nKey="forgot-your-password-click-here-to-reset-d6a6927cb4f01397fb2f0e2d48b2ce6d">
            Forgot your password? Click <a href={`${host}/password_reset/`}>here</a> to reset
          </Trans>
        </p>
      </div>
    </div>
  );
}
