import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import styles from './SelectDate.module.scss';

export default function SelectDate(props) {
  const { t, i18n } = useTranslation();
  const dateFormat = props.club.date_format === 'M-D-Y' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
  const pickerRef = useRef(null);
  const isMobile = window.innerWidth <= 1024;
  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);

  return (
    <>
      <div className={styles.select__dateLabel}>{t('select-date')}</div>
      <div className={styles.select}>
        <div className={styles.select__date}>
          <DatePicker
            className="datePicker"
            popperClassName="datePickerPopper"
            dateFormat={dateFormat}
            maxDate={new Date(props.last_update)}
            selected={new Date(props.date)}
            //onChange={(date) => {props.handleDate(date); props.viewDate(props.date)}}
            onChange={(date) => props.handleDate(date)}
            ref={pickerRef}
          />
        </div>

        <div className={styles.select__dateView} onClick={() => props.viewDate(props.date)}>
          <span>{t('view-date')}</span>
        </div>
      </div>
    </>
  );
}
