import React, { useCallback, useEffect, useState } from 'react';
import API from '../../utils/API';
import Layout from '../../components/layout/layout';
import styles from './my-team.module.scss';
import Team from '../../components/Team/Team';
import Preloader from '../../components/Preloader/Preloader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HandleError, handleRedirect } from '../../utils/helpers';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';

export default function MyTeam(props) {
  const [data, setData] = useState(null);
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  let history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.get('/team/');
      if (response.status === 200) {
        handleRedirect(response, history);
        setData(response.data);
        setLastUpdateDate(response.data.last_update);
        setImage(response.data.club.icon);
        if (props.user === '') {
          props.setUser(response.data.user);
        }
      }
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status) {
        HandleError(e, history);
      }
      console.log('Error fetch team data: ', e);
    }
  }, [setData, setLastUpdateDate, setImage, setLoading]);

  return (
    <Layout isSidebarCollapsed={props.isSidebarCollapsed} changeCollapseSidebar={props.changeCollapseSidebar} user={props.user}>
      {/*<Head>*/}
      {/*    <title>Team</title>*/}
      {/*    <meta name="viewport" content="initial-scale=1.0, width=device-width"/>*/}
      {/*</Head>*/}
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.teamContainer}>
          <HeaderComponent isSidebarCollapsed={props.isSidebarCollapsed} title={t('Team')} lastUpdateDate={lastUpdateDate} lastUpdateTitle={'last-updated'} image={image} />

          <div className={styles.team}>
            <Team {...data} isSidebarCollapsed={props.isSidebarCollapsed} />
          </div>
        </div>
      )}
    </Layout>
  );
}
