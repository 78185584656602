import { Link } from 'react-router-dom';
import React, { useState, useCallback, useEffect } from 'react';
import UserDropdown from '../UserDropdown/UserDropdown';
import TeamsDropdown from '../TeamsDropdown/TeamsDropdown';
import { menuItems, ROUTES } from '../../utils/constants';
import css from './Sidebar.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HandleError, handleRedirect } from '../../utils/helpers';
import API from '../../utils/API';
import { useHistory } from 'react-router-dom';


export const Sidebar = ({ isSidebarCollapsed, changeCollapseSidebar, userData }) => {
  const router = useRouteMatch();
  const { t, i18n } = useTranslation();
  const [isUserDropdown, setIsUserDropdown] = useState(false);
  const [isTeamsDropdown, setIsTeamsDropdown] = useState(true);
  const [image, setImage] = useState('');
  const [teams, setTeams] = useState([]);
  const [clubName, setClubName] = useState('');
  const [lastUpdateDate, setLastUpdateDate] = useState('');
  const [loading, setLoading] = useState(false);
  

  let history = useHistory();
  
  const user = JSON.parse(localStorage.getItem('userPersonalData'));
  const clubData = JSON.parse(localStorage.getItem('clubData'));

 
  
  const changeIsUserDropdown = useCallback(() => {
    setIsUserDropdown(!isUserDropdown);
  }, [isUserDropdown, setIsUserDropdown]);
  const changeIsTeamsDropdown = useCallback(() => {
    setIsTeamsDropdown(!isTeamsDropdown);
  }, [isTeamsDropdown, setIsTeamsDropdown]);
  const toggleMenu = useCallback(
    (value) => {
      changeCollapseSidebar(value);
    },
    [changeCollapseSidebar],
  );

  useEffect(() => {
    if(clubData){
      setImage(clubData.club.icon);
      setClubName(clubData.club.name)
      //setLastUpdateDate(clubData.teams[0].last_update)
    } 

    return () => setLoading(false);
  }, [clubData]);
 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        toggleMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);
  }, [toggleMenu]);

  const sidebarContent = () => {
    return menuItems.map((el) => {
      let collapseSidebar;
      if (window.matchMedia('(min-width: 1024px)').matches) {
        collapseSidebar = isSidebarCollapsed;
      } else {
        collapseSidebar = true;
      }
      return (
        <React.Fragment key={el.title}>
          { user &&!user.is_data_manager && el.link === ROUTES.DATA ? (
            <React.Fragment key={el.title} />
          ) : (
            <div key={el.title} className={`${css['sidebar__item']} ${router && el.link.substr(0, el.link.length) === router.url.substr(0, router.url.length) ? css['sidebar__item--active'] : ''}`}>
              <Link to={el.link} onClick={toggleMenu}>
                <div className={css['sidebar__item-wrapper']}>
                  <div className={css['sidebar__item-img-wrapper']}>
                    <img className={css['sidebar__item-img']} src={el.icon} alt={el.link} />
                  </div>
                  <span className={css['sidebar__item-title']}>{t(el.title)}</span>
                </div>
              </Link>
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={`${css['sidebar']} ${!isSidebarCollapsed ? css['sidebar--open'] : ''}`}>
      <button className={css['sidebar__toggle']} onClick={() => toggleMenu(!isSidebarCollapsed)}>
        <span>
          <img src="/static/images/icons/arrow.svg" alt="arrow" />
        </span>
      </button>
      <div className={css['sidebar__wrapper']}>
        <div className={css['sidebar__logo']}>
          <img
            className={`${css['sidebar__logo-image']} ${css['sidebar__logo-image--desktop']}`}
            src={'/static/images/logo.svg'}
            alt="company logo"
          />
          <img className={`${css['sidebar__logo-image']} ${css['sidebar__logo-image--mobile']}`} src="/static/images/logo.svg" alt="company logo" />
        </div>
        <div className={css['sidebar__user']}>
            <div className={css['sidebar__user-avatar']}>{user && user.initials}</div>
            <div className={css['sidebar__user-content']}>
              <div className={css['sidebar__user-wrapper']}>
                <span className={css['sidebar__user-name']}>{user && `${user.first_name} ${user.last_name}`}</span>
                <span className={`${css['sidebar__user-dropdown']} ${isUserDropdown ? css['sidebar__user-dropdown--open'] : ''}`} onClick={changeIsUserDropdown}>
                  <img src="/static/images/icons/arrow.svg" alt="arrow" />
                </span>
              </div>
            </div>
            {isUserDropdown && <UserDropdown changeIsUserDropdown={changeIsUserDropdown} changeCollapseSidebar={changeCollapseSidebar} />}
          </div>
        {/* {isTeamsDropdown && <TeamsDropdown  teams={teams} changeIsTeamsDropdown={changeIsTeamsDropdown} image={image}  clubName={clubName} lastUpdateDate={lastUpdateDate}/>} */}
       
 
        <div className={css['sidebar__content']}>
          <div className={css['sidebar__content-menu']}>{sidebarContent()}</div>
        
        </div>
      </div>
    </div>
  );
};
