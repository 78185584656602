import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styles from './DailyPlayersAtRisk.module.scss';

export default function DailyPlayersAtRisk(props) {
  const { t, i18n } = useTranslation();

  const seriesBar = [
    {
      name: t('medium'),
      data: props.availability !== undefined && props.availability.counts.map((el) => el.medium),
    },
    {
      name: t('High'),
      data: props.availability !== undefined && props.availability.counts.map((el) => el.high),
    },
  ];

  const optionsBar = {
    chart: {
      id: 'bar_chart',
      width: '100%',
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors: [props.colors.medium, props.colors.risk_count],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      offsetY: -40,
      labels: {
        colors: ['rgba(255, 255, 255, 1)'],
      },
      itemMargin: {
        horizontal: 30,
      },
      markers: {
        width: 6,
        height: 6,
        radius: 6,
        offsetX: '-14px',
      },
      tooltipHoverFormatter: function (seriesName, opts) {
        return `${seriesName}<div class='bar_tooltip ${seriesName}'>${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}</div>`;
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        startingShape: 'rounded',
        endingShape: 'rounded',
        columnWidth: '30%',
      },
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          legend: {
            fontSize: '12px',
            offsetY: 0,
          },
          title: {
            style: {
              fontSize: '15px',
            },
          },
          xaxis: {
            tickAmount: 7,
            labels: {
              formatter: function (val) {
                return dayjs(val).format('M/D');
              },
            },
          },
        },
      },
    ],
    title: {
      text: t('players-at-risk-per-day'),
      align: 'left',
      offsetX: -10,
      style: {
        fontSize: '20px',
        fontFamily: 'Roboto',
        fontWeight: '500',
        color: props.colors.white,
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      followCursor: true,
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      items: {
        display: 'none',
      },
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetX: 11,
        offsetY: 11,
      },
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 10,
      categories: props.availability !== undefined && [props.availability.dates[0], ...props.availability.dates],
      labels: {
        show: true,
        style: {
          fontSize: '13px',
          fontFamily: 'Roboto',
          fontWeight: 'lighter',
          colors: 'rgba(255, 255, 255, .5)',
        },
        formatter: function (val) {
          return dayjs(val).format('MMM DD');
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          fontSize: '13px',
          fontFamily: 'Roboto',
          fontWeight: 'lighter',
          colors: 'rgba(255, 255, 255, .5)',
        },
        formatter: (value) => {
          return value;
        },
      },
    },
  };

  useEffect(() => {
    if (!props.isSidebarCollapsed) {
      ApexCharts.exec(`bar_chart`, 'updateOptions', { chart: { width: '100%' } });
    }
  }, [props.isSidebarCollapsed]);

  return (
    <>
      {props.availability && (
        <div className={`${styles.dailyPlayersAtRisk} ${styles.panel}`} style={props.background && { background: 'linear-gradient(#2C2F35, #2F3539)' }}>
          <Chart series={seriesBar} options={optionsBar} height={window.matchMedia('(min-width: 768px)').matches ? '352' : '322'} type="bar" width="100%" />
        </div>
      )}
    </>
  );
}
