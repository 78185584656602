import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Tooltip from '../../Tooltip/Tooltip';
import RiskTrend from '../RiskTrend/RiskTrend';
import Context from '../Context/Context';
import SelectDate from '../SelectDate/SelectDate';
import Recommendations from '../Recommendations/Recommendations';
import BioRiskFactors from '../BioRiskFactors/BioRiskFactors';
import SimilarInjuries from '../SimilarInjuries/SimilarInjuries';
import styles from './PlayerInfo.module.scss';
import RiskFactors from '../RiskFactors/RiskFactors';
import Overview from '../Overview/Overview';
import PlayerEditInjury from '../PlayerEditInjury/PlayerEditInjury';
import API from '../../../utils/API';
import { sendAmplitudeData } from '../../../utils/amplitude';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export const colors = {
  white: '#ffffff',
  black32: '#202020',
  black40: '#282828',
  gray: '#6e6e6e',
  average: '#0f98d7',
  alert: '#da2a16',
  low: '#0f98d7',
  medium: '#e75d0d',
  high: '#da2a16',
  low_grad: '#708fff',
  medium_grad: '#eb7800',
  high_grad: '#e03300',
  injured: '#9ea2a8',
  injured_grad: '#9ea2a8',
};

export default function PlayerInfo(props) {
  const [tooltipData, setTooltipData] = useState(null);
  const [playerDateView, setPlayerDateView] = useState(props.date);
  const [data, setData] = useState(null);
  const [show, setShow] = useState(!dayjs(props.last_update).isAfter(playerDateView) || dayjs(props.last_update).isSame(dayjs(playerDateView).format('YYYY-MM-DD')));

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };

  const handleDate = useCallback(
    async (el) => {
      console.log('handleDate')
      setPlayerDateView(el);
      setShow(!dayjs(props.last_update).isAfter(props.date) || dayjs(props.last_update).isSame(dayjs(props.date).format('YYYY-MM-DD')));
    },
    [setPlayerDateView, props],
  );

  const viewDate = useCallback(
    async (el) => {
      console.log('viewDate')
      props.handleDate(el);
      props.viewDate(el);
      console.log(`event: Changed date Date: ${dayjs(el).format('DD-MM-YYYY')}`);
      sendAmplitudeData(`Changed date`, { detail: `Date: ${dayjs(el).format('DD-MM-YYYY')}` });
    },
    [setPlayerDateView],
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await API.get('/data/');
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (e) {
      console.log('Error fetch Data: ', e);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={styles.player}>
        {props.alert && (
          <div className={`${styles.player__blocksWrap} ${props.biomechanical_factors && styles.bio}`}>
            <div
              className={`${styles.player__block1} ${props.biomechanical_factors && styles.bio}
                                 ${props.alert.player.injured && styles.injured}
                                 ${(props.alert.level === 'low' || props.alert.level === null) && styles.noAdjustments}`}
            >
              <Overview {...props} />
              <SimilarInjuries {...props} />
            </div>
            {!props.alert.player.injured && props.alert.level !== 'low' && props.alert.level !== null && (
              <div className={`${styles.player__block2} ${props.biomechanical_factors && styles.bio}`}>
                <RiskFactors {...props} colors={{ ...colors }} />
                <BioRiskFactors {...props} colors={{ ...colors }} />
              </div>
            )}
          </div>
        )}
        <Recommendations {...props} />

        <>
          <Context {...props} />
          <RiskTrend {...props} colors={{ ...colors }} />
          <SelectDate {...props} date={playerDateView} viewDate={viewDate} handleDate={handleDate} />
          {!!(data && data.user && props.club.enable_injury_editing && show) && <PlayerEditInjury {...props} data={data} selectDate={playerDateView} isSidebarCollapsed={props.isSidebarCollapsed} />}
        </>

        {/*{props.btnSimulatorChecked ? (*/}
        {/*{props.isSidebarCollapsed && <MicroCycleSimulator {...props} isSidebarCollapsed={props.isSidebarCollapsed} />}*/}
        {/*) : (*/}
        {/*<>*/}
        {/*  <Context {...props} />*/}
        {/*  <RiskTrend {...props} colors={{ ...colors }} />*/}
        {/*  <SelectDate {...props} date={playerDateView} viewDate={viewDate} handleDate={handleDate} />*/}
        {/*  {!!(data && data.user && props.club.enable_injury_editing && show) && <PlayerEditInjury {...props} data={data} selectDate={playerDateView} isSidebarCollapsed={props.isSidebarCollapsed} />}*/}
        {/*</>*/}
        {/*)}*/}

        {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
      </div>
    </>
  );
}
