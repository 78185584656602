import { Link, useHistory } from 'react-router-dom';
import React, { useState, useCallback, useEffect, Fragment } from 'react';
import styles from './PlayerItem.module.scss';
import { ROUTES } from '../../../utils/constants';
import API, { host } from '../../../utils/API';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeData } from '../../../utils/amplitude';
import dayjs from 'dayjs';

const showRisk = {
  high: true,
  medium: true,
  low: true,
  contained: true,
};

export default function PlayerItem(props) {
  const { t, i18n } = useTranslation();

  let history = useHistory();

  return (
    <>
      <div className={styles.playerCard}>
      <Link className={styles.link} to={`${ROUTES.PLAYER}/${props.player.alert.player.id}/${props.player.alert.date}`}>
          <div className={styles.player}>
            <div className={`${styles.player__image} ${styles[props.player.alert.level]}`}>
              {props.player.alert.player.has_image ? (
                <img src={`${host}/core/api/player_image/${props.player.alert.player.id}/`} alt={props.player.alert.player.id} />
              ) : (
                <div
                  className={`${styles.player__imageInitials}
                              ${styles[props.player.alert.level]}
                              ${props.player.alert.player.injured && styles.injured}
                              ${props.player.alert.level === null && !props.player.alert.player.injured && styles.low}`}
                >
                  {props.player.alert.player.initials}
                </div>
              )}
              {props.player.alert.player.enable_modified_thresholds && <span className={`${styles.modified} ${styles[props.player.alert.level]}`}></span>}
            </div>
            <div className={styles.player__info}>
              {showRisk[props.player.alert.level] ? (
                <div className={`${styles.player__level} ${styles[props.player.alert.level]}`}>{t(props.player.alert.level) + ' ' + t('Risk')}</div>
              ) : (
                <div className={`${styles.player__level} ${styles[props.player.alert.level]}`}>{t('unavailable')}</div>
              )}
              <div className={styles.player__name}>{`${props.player.alert.player.first_name} ${props.player.alert.player.last_name}`}</div>

              {props.player.alert.detected !== '' &&  props.player.alert.level !== 'injured' &&  <div className={styles.player__lastUpdate}>{t('Identified')} {dayjs(props.player.alert.detected).fromNow('dd')} ago</div>}
            </div>
          </div>
          <div className={styles.player__arrow}>
            {props.player.modifications && !props.isMobile &&  !props.player.complete_rest &&<div className={styles.player__label}><span>Modification recommended</span></div> }
            {!props.isMobile && props.player.complete_rest &&<div className={styles.player__label}><span>Complete Rest</span></div> }
            {props.player.modifications && props.isMobile && <div className={styles.player__mobileLabel}><span>*</span></div> }
            <img alt="arrow" src="/static/images/icons/arrow_panel.svg" />
          </div>
        </Link>
      </div>
    </>
  );
}
