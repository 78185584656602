import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './FindPlayer.module.scss';
import API, { host } from '../../utils/API';
import { ROUTES } from '../../utils/constants';

const App = React.memo(function ({ isSidebarCollapsed }) {
  const history = useHistory();
  const ref = React.createRef();
  const inputRef = useRef();
  const [filterValue, setFilterValue] = useState('');
  const [hint, setHint] = useState('');
  const [gFocus, setGFocus] = useState(false);
  const [isHover, setIsHover] = useState(-1);
  const [caret, setCaret] = useState(0);
  const [player, setPlayer] = useState([]);

  const setInputValue = useCallback(
    (e) => {
      e.stopPropagation();
      if (inputRef.current && inputRef.current.selectionStart) setCaret(inputRef.current.selectionStart);
      if (filterValue.length === 0 && e.target.value !== ' ') {
        setFilterValue(e.target.value);
      } else if (filterValue.length > 0 || (filterValue.length === 0 && e.target.value !== ' ')) {
        setFilterValue(e.target.value);
      }
    },
    [filterValue, inputRef],
  );

  const resetAll = useCallback(() => {
    if (!gFocus) {
      setFilterValue('');
      setHint('');
      setIsHover(-1);
      setPlayer([]);
    }
  }, [gFocus]);

  const handleKeyPress = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.keyCode === 38 && !!!isHover) event.preventDefault();

      if (event.keyCode === 38 && player && player.length && isHover > 0) {
        setIsHover(isHover - 1);
        ref.current && ref.current.scrollTo(0, (isHover - 1) * 35);
        setHint(`${player[isHover - 1].first_name} ${player[isHover - 1].last_name}`);
        event.preventDefault();
      }
      if (event.keyCode === 40 && player && player.length && isHover < player.length - 1) {
        setIsHover(isHover + 1);
        ref.current && ref.current.scrollTo(0, (isHover + 1) * 35);
        setHint(`${player[isHover + 1].first_name} ${player[isHover + 1].last_name}`);
      }
      if (event.key === 'Enter' && player && player.length && isHover > -1 && isHover < player.length) {
        console.log('enter press here! ');
        history.push(`${ROUTES.PLAYER}/${player[isHover].id}`);
      }
    },
    [isHover, player, history, ref, inputRef, caret, filterValue],
  );

  useEffect(async () => {
    try {
      if (filterValue && filterValue.length >= 2) {
        const response = await API.get(`/search_players/?filter=${filterValue}`, { withCredentials: true });
        setPlayer(response.data.players);

        if (response.data.players.length) {
          const player = response.data.players[0];
          setHint(`${player.first_name} ${player.last_name}`);
          setIsHover(0);
        }
      } else {
        setHint('');
        setIsHover(-1);
      }
    } catch (e) {
      console.log(e);
    }
  }, [filterValue]);

  return (
    <div className={styles.searchBar}>
      <div
        className={`${filterValue.length >= 2 ? styles.listContainer : styles.inputContainer} ${!isSidebarCollapsed && styles.collapsed}`}
        onMouseEnter={() => setGFocus(true)}
        onMouseLeave={() => setGFocus(false)}
        onClick={() => inputRef.current.focus()}
      >
        <div className={styles.listContainer__search}>
          <div style={{ backgroundImage: `url(/static/images/icons/find-player-search.svg)` }} className={`${styles.icon} ${!isSidebarCollapsed && styles.icon__collapsed}`} />
          <input
            ref={inputRef}
            type={'text'}
            placeholder={'Player Search'}
            value={filterValue}
            onChange={setInputValue}
            onKeyDown={handleKeyPress}
            className={`${styles.input} ${!isSidebarCollapsed && styles.input_collapsed}`}
            onBlur={resetAll}
          />
        </div>

        {/*<div className={`${styles.input} ${styles.hint}`}>{hint}</div>*/}

        {filterValue.length >= 2 && player.length ? (
          <>
            <div className={`${styles.listContainer__line}   ${!isSidebarCollapsed && styles.listContainer__line_collapsed}`} />
            <div className={`${styles.listContainer__searchContainer}`} ref={ref}>
              {player.map((item, index) => (
                <Link to={`${ROUTES.PLAYER}/${item.id}`} key={item.id}>
                  <div
                    className={`${styles.listContainer__playerListItem} ${index === isHover && styles.listContainer__playerListItemHover}`}
                    key={item.id}
                    onMouseEnter={() => {
                      setIsHover(index);
                      setHint(`${item.first_name} ${item.last_name}`);
                    }}
                    onMouseLeave={() => {
                      setIsHover(-1);
                      setHint(`${player[0].first_name} ${player[0].last_name}`);
                    }}
                  >
                    <div className={`${styles.player__image}`}>
                      {item.has_image ? <img src={`${host}/core/api/player_image/${item.id}/`} alt={item.id} /> : <div className={`${styles.player__imageInitials}`}>{item.initials}</div>}
                    </div>
                    {item.first_name} {item.last_name}
                  </div>
                </Link>
              ))}
            </div>
          </>
        ) : (
          filterValue.length >= 2 &&
          !player.length && (
            <div className={styles.listContainer__noResults} onClick={() => inputRef.current.focus()}>
              <div className={`${styles.listContainer__line}   ${!isSidebarCollapsed && styles.listContainer__line_collapsed}`} />
              <div className={styles.listContainer__playerListItem}>No results</div>
            </div>
          )
        )}
      </div>
    </div>
  );
});

export default App;
