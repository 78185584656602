import React from 'react';
import Modal from '../Modal/Modal';
import styles from '../DataInjuryManagement/DataInjuryManagement.module.scss';
import { useTranslation } from 'react-i18next';

export default function App({ isSidebarCollapsed, errorModalOpen, errorList, close }) {
  const { t } = useTranslation();
  return (
    <Modal isSidebarCollapsed={isSidebarCollapsed} isOpen={errorModalOpen} maxWidth={321} minHeight={178} onClose={close} isBlackModal={true}>
      <div className={styles.errorModal_header}>{t('Mandatory Field Missing')}</div>
      <div className={styles.line} />
      <div className={styles.errorModal_please}>
        Please input: <b className={styles.errorModal_please_bold}>{errorList}.</b>
      </div>

      <div className={`${styles.not_found_svg} ${styles.errorModal_x}`} onClick={close}>
        <div className={`${styles.not_found_svg_x} ${styles.not_found_svg_rotate_right}`} />
        <div className={`${styles.not_found_svg_x} ${styles.not_found_svg_rotate_left}`} />
      </div>
    </Modal>
  );
}
