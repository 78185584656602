import styles from './PermissionDenied.module.scss';

export default function PermissionDenied() {
  return (
    <div className={styles.center}>
      <div className={styles.errorCode}>403</div>
      <div className={styles.errorDesc}>Permission Denied</div>
      <div>You are not authorized to view this page</div>
    </div>
  );
}