import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SimilarInjuries.module.scss';
import Tooltip from '../../Tooltip/Tooltip';

export default function SimilarInjuries(props) {
  const [tooltipData, setTooltipData] = useState(null);

  const { t, i18n } = useTranslation();

  const toggleTooltip = (data) => {
    setTooltipData(data);
  };

  return (
    <>
      {props.similar_injuries && (
        <div className={`${styles.similar} ${styles.panel} ${props.biomechanical_factors && styles.bio}`}>
          <div className={styles.similar__wrap}>
            <div className={styles.similar__title}>
              <span>{t('players-with-similar-data-suffered-from-the-following-injuries')}</span>
              <span
                className={styles.player__tooltip}
                onClick={(e) =>
                  toggleTooltip({
                    clientX: e.clientX,
                    clientY: e.clientY,
                    title: props.tooltips.similar_injuries.title,
                    content: props.tooltips.similar_injuries.text,
                  })
                }
              >
                i
              </span>
            </div>
            <div className={styles.similar__injuries}>
              {props.similar_injuries.map((el) => {
                return (
                  <div key={el.name} className={`${styles.injury} ${styles[props.alert.level.toString().substr(0, 2)]} ${styles[el.severity]}`}>
                    <div className={styles.injury__name}>{el.name}</div>
                    <div className={styles.injury__severity}>{t(el.severity)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {tooltipData && tooltipData.title !== undefined && <Tooltip {...tooltipData} toggleTooltip={toggleTooltip} />}
    </>
  );
}
