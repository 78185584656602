import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styles from './DataInjuryManagement.module.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import API from '../../utils/API';
import Preloader from '../Preloader/Preloader';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import PlayerInjuryErrorModal from '../PlayerInjuryErrorModal/index';
import { sendAmplitudeData } from '../../utils/amplitude';

export default function DataInjuryManagement(props) {
  const [players, setPlayers] = useState({});
  const [page, setPage] = useState(1);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isModalInjuryOpen, setIsModalInjuryOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isReturnDateOpen, setIsReturnDateOpen] = useState(false);
  const [isModalEditPlayerOpen, setIsModalEditPlayerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowTextarea, setIsShowTextarea] = useState(false);
  const [isShowPlayersDropdown, setIsShowPlayersDropdown] = useState(false);
  const [isSideDropdown, setIsSideDropdown] = useState(false);
  const [isTeamDropdown, setIsTeamDropdown] = useState(false);
  const [isTypeDropdown, setIsTypeDropdown] = useState(false);
  const [isSettingDropdown, setIsSettingDropdown] = useState(false);
  const [isContactDropdown, setIsContactDropdown] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isDeletedPlayer, setIsDeletedPlayer] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [playerId, setPlayerId] = useState(null);
  const [textareaHeight, setTextareaHeight] = useState(110);
  const [searchValue, setSearchValue] = useState('');
  const [playerTeams, setPlayerTeams] = useState({});
  const [error, setError] = useState([]);
  const [errorList, setErrorList] = useState('');
  const [team, setTeam] = useState();
  const [descriptionInput, setDescriptionInput] = useState('');
  const [playerDisabled, setPlayerDisabled] = useState(true);
  const [allDisabled, setAllDisabled] = useState(true);
  const csrf_token = localStorage.getItem('csrftoken');
  const dateFormat = props.club?.date_format === 'M-D-Y' ? 'MMMM do yyyy' : 'dd/MM/yyyy';
  const uniquePlayers = players && players.injuries && players.injuries.filter((ele, ind) => ind === players?.injuries?.findIndex((elem) => elem.player.id === ele.player.id));
  const { t } = useTranslation();

  const resetFlags = useCallback(() => {
    setIsTeamDropdown(false);
    setIsShowTextarea(false);
    setIsSideDropdown(false);
    setIsTypeDropdown(false);
    setIsShowTextarea(false);
    setIsOptionsVisible(false);
    setIsContactDropdown(false);
    setIsSettingDropdown(false);
    setIsModalInjuryOpen(false);
    setIsShowPlayersDropdown(false);
    setIsModalEditPlayerOpen(false);
  }, []);

  const getCloseModal = useCallback(() => {
    setIsModalInjuryOpen(false);
    setSelectedPlayer({});
    setIsShowTextarea(false);
    setIsModalEditPlayerOpen(false);
    setIsEdit(false);
    setIsDeletedPlayer(false);
    setDescriptionInput('');
    resetFlags();
    setError([]);
    setPlayerDisabled(true);
    setAllDisabled(true);
  }, []);

  const fetchPlayers = useCallback(async () => {
    // setLoading(true);
    try {
      if (searchValue.length === 0) {
        const response = await API.get(`/injuries/?page=${page}`, { withCredentials: true });
        if (response.status === 200) {
          setPlayers(response.data);
          setSearchValue('');
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('Error with Injuries fetching players in team', e);
    }
  }, [page, searchValue]);

  const getNewToken = useCallback(async () => {
    try {
      const response = await API.get(`/refresh_token/`, { withCredentials: true });

      if (response.status === 200) {
        localStorage.setItem('csrftoken', response.data.csrf_token);
      }
    } catch (e) {
      console.log('Error when getting a new token ', e);
    }
  }, []);

  const capitalize = useCallback((s) => {
    return s[0].toUpperCase() + s.slice(1);
  }, []);

  const getMassParam = useCallback((array) => {
    return array
      .map((item) => {
        const mass = item.split('_');
        if (mass.length < 2) {
          return capitalize(mass[0]);
        } else {
          if (mass[0].length > mass[1].length) {
            return capitalize(mass[0]);
          } else {
            return capitalize(mass[1]);
          }
        }
      })
      .join(', ');
  }, []);

  const submitInjury = useCallback(
    async (submitPlayerInput) => {
      const getOwnPropertyNames = Object.getOwnPropertyNames(submitPlayerInput);
      const empty = selectedPlayer.team ? [] : ['team'];
      const amplitudeData = [];
      getOwnPropertyNames.forEach((key) => {
        if (!submitPlayerInput[key] && !['description', 'return_date', 'id', 'side', 'is_contact', 'match_or_training'].includes(key)) {
          empty.push(key);
        }
      });

      if (isEdit) {
        const player = players.injuries.filter((item) => item.player.id === submitPlayerInput.player_id);
        Object.keys(player[0]).forEach((key) => {
          if (key !== 'player' && player[0][key] !== submitPlayerInput[key]) {
            key === 'match_or_training' ? amplitudeData.push('Setting') : amplitudeData.push(key);
          }
        });
        console.log(getMassParam(amplitudeData));
      }

      if (!empty.length) {
        setErrorModalOpen(false);
        setLoading(true);
        let formData = new FormData();

        [submitPlayerInput].forEach((player) => {
          Object.entries(player).forEach((item) => {
            formData.append(item[0], item[1]);
          });
        });
        const options = {
          withCredentials: true,
          headers: { 'X-CSRFToken': csrf_token },
        };
        try {
          const response = await API.post(`/submit_injury/`, formData, options);
          if (response.status === 200) {
            console.log(response.data);
          }
          if (!isEdit) console.log(`event: submitted an injury Status: Success`);
          if (!isEdit) sendAmplitudeData(`submitted an injury`, { detail: `Status: Success` });
          if (isEdit) console.log(`event: Edited an injury Status: Success Edited Fields:[${getMassParam(amplitudeData)}]`);
          if (isEdit) sendAmplitudeData(`Edited an injury`, { detail: `Status: Success Edited Fields:[${getMassParam(amplitudeData)}]` });
          setLoading(false);
        } catch (e) {
          if (e.response.status === 403) {
            await getNewToken();
          }
          if (!isEdit) console.log(`event: submitted an injury Status: Fail`);
          if (!isEdit) sendAmplitudeData(`submitted an injury`, { detail: `Status: Fail` });
          if (isEdit) console.log(`event: Edited an injury Status: Fail Edited Fields:[${getMassParam(amplitudeData)}]`);
          if (isEdit) sendAmplitudeData(`Edited an injury`, { detail: `Status: Fail Edited Fields:[${getMassParam(amplitudeData)}]` });
          setLoading(false);
          console.log('Error with submit injury players in team', e);
        } finally {
          searchPlayer(searchValue);
        }
        setSelectedPlayer({});
        getCloseModal();
      } else {
        setErrorModalOpen(true);
        setError(empty);
        setErrorList(getMassParam(empty));
      }
    },
    [csrf_token, selectedPlayer, searchValue, isEdit],
  );

  const submitDeleteInjury = useCallback(
    async (id) => {
      setLoading(true);
      const options = {
        withCredentials: true,
        headers: { 'X-CSRFToken': csrf_token },
      };
      const formData = new FormData();
      formData.append('id', id);
      try {
        const response = await API.post(`/submit_delete_injury/`, formData, options);
        if (response.status === 200) {
          console.log(response.data);
        }
        setLoading(false);
        sendAmplitudeData(`Deleted an injury`, { detail: `Status: Success` });
      } catch (e) {
        if (e.response.status === 403) {
          await getNewToken();
        }
        sendAmplitudeData(`Deleted an injury`, { detail: `Status: Fail` });

        setLoading(false);
        console.log('Error with submit deleted injury players in team', e);
      }
    },
    [csrf_token],
  );

  useEffect(() => {
    fetchPlayers();
  }, [page, searchValue]);

  useEffect(() => {
    const obj = {};
    if (props && props.players && props.players.length) {
      if (props.players.length > 1) {
        props.players.map((items) => {
          items.players.map((item) => {
            obj[item.id] = items.team;
          });
        });
      } else {
        setSelectedPlayer({
          ...selectedPlayer,
          team: props.players[0].team,
        });
        setTeam(0);
        setPlayerDisabled(false);
      }
    }
    setPlayerTeams(obj);
  }, [props, isModalInjuryOpen]);

  const sortedPlayers = useCallback(
    (inputPlayers) => {
      const sorted = [...inputPlayers].sort((a, b) => {
        if (!isSorting) {
          setIsSorting(true);
          return dayjs(a.date).isBefore(b.date) ? -1 : 1;
        } else {
          setIsSorting(false);
          return dayjs(a.date).isBefore(b.date) ? 1 : -1;
        }
      });
      setPlayers({
        total_pages: players.total_pages,
        page,
        injuries: sorted,
      });
    },
    [players.injuries, players.total_pages, page, isSorting],
  );

  const searchPlayer = useCallback(
    async (text) => {
      const options = {
        withCredentials: true,
        headers: { 'X-CSRFToken': csrf_token },
      };
      try {
        if (text && text.length > 0) {
          const response = await API.get(`/injuries?filter=${text}`, options);
          if (response.status === 200) {
            setPlayers(response.data);
            setPage(response.data.page);
          }
        } else if (text.length === 0) {
          //setLoading(true);
          const response = await API.get(`/injuries/?page=${page}`, options);
          if (response.status === 200) {
            setPlayers(response.data);
          }
        }
        setLoading(false);
      } catch (e) {
        if (e.response.status === 403) {
          await getNewToken();
        }
        console.log('Error with searching players in team', e);
      }
    },
    [players, page, searchValue],
  );

  const ucFirst = (str) => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  };

  const today = new Date();
  const date = new Date(selectedPlayer?.date);
  const tomorrow = selectedPlayer?.date ? new Date(date.getTime() + 24 * 60 * 60 * 1000) : new Date(today.getTime() + 24 * 60 * 60 * 1000);

  return (
    <Fragment>
      {loading ? (
        <Preloader style={{ marginTop: '150px' }} />
      ) : (
        <section className={styles.injury_management}>
          <div className={styles.injury_management__title}>{t('Injury Data Management')}</div>
          <div className={styles.injury_management__report_block}>
            <button
              className={styles.injury_management__report_button}
              onClick={() => {
                setIsModalInjuryOpen(true);
                console.log(`event: add injury dialog`);
                sendAmplitudeData(`add injury dialog`);
              }}
            >
              {t('Add Injury')}
            </button>
          </div>

          {!players ||
            !players.injuries ||
            (!!!players.injuries.length && (
              <div className={`${styles.players_table} ${styles.panel}`}>
                <div className={styles.players_table__search_block}>
                  <input
                    className={styles.players_table__input}
                    type="text"
                    placeholder={t('Type Player Name')}
                    onChange={(e) => {
                      if (e.target.value[0] !== ' ') {
                        setSearchValue(e.target.value);
                        searchPlayer(e.target.value);
                      }
                    }}
                    value={searchValue}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        searchPlayer(searchValue);
                      }
                    }}
                  />
                  <button
                    className={styles.players_table__search_button}
                    style={{ backgroundImage: `url(/static/images/icons/search.svg)` }}
                    onClick={(e) => {
                      e.preventDefault();
                      searchPlayer(searchValue);
                    }}
                  />
                </div>
                <div className={styles.random_player_not_found}>
                  "{searchValue}" {t('Not Found')}
                </div>
                <div className={styles.not_found_svg}>
                  <div className={`${styles.not_found_svg_x} ${styles.not_found_svg_rotate_right}`} />
                  <div className={`${styles.not_found_svg_x} ${styles.not_found_svg_rotate_left}`} />
                </div>
              </div>
            ))}
          {players && players.injuries && !!players.injuries.length && (
            <div className={`${styles.players_table} ${styles.panel}`}>
              <div className={styles.players_table__search_block}>
                <input
                  className={styles.players_table__input}
                  type="text"
                  placeholder={t('Type Player Name')}
                  onChange={(e) => {
                    if (e.target.value[0] !== ' ') {
                      setSearchValue(e.target.value);
                      searchPlayer(e.target.value);
                    }
                  }}
                  value={searchValue}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      searchPlayer(searchValue);
                    }
                  }}
                />
                <button
                  className={styles.players_table__search_button}
                  style={{ backgroundImage: `url(/static/images/icons/search.svg)` }}
                  onClick={(e) => {
                    e.preventDefault();
                    searchPlayer(searchValue);
                  }}
                />
              </div>
              <div className={styles.players_table__headers}>
                <div className={`${styles.players_table__item} ${styles.players_table__player}`}>{t('Player')}</div>
                {props.players && props.players.length > 1 && <div className={`${styles.players_table__item} ${styles.date_team}`}>{t('Team')}</div>}
                <div className={`${styles.players_table__item_date__block} ${props.players.length === 1 && styles.players_table__item_date__block_with_out_team}`}>
                  {/*<span className={styles.sorted_button}>*/}
                  {/*  <img*/}
                  {/*    src="/static/images/icons/arrow-paginate.svg"*/}
                  {/*    className={styles.arrow_rotate__sortDate}*/}
                  {/*    onClick={() => {*/}
                  {/*      sortedPlayers(players.injuries);*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</span>*/}
                  <span>{t('Date')}</span>
                </div>
                <div className={`${styles.players_table__item} ${styles.players_table__type}  ${props.players.length === 1 && styles.players_table__type_with_out_team}`}>{t('Type')}</div>
                <div className={`${styles.players_table__item} ${styles.players_table__return} ${props.players.length === 1 && styles.players_table__return_with_out_team}`}>{t('Return Date')}</div>
                <div className={`${styles.players_table__action}  ${props.players.length === 1 && styles.players_table__action_with_out_team}`}>{t('Action')}</div>
              </div>

              {players &&
                players.injuries &&
                players.injuries.map((injuriePlayer, index) => {
                  return (
                    <div className={styles.player} key={injuriePlayer?.id + injuriePlayer?.date + injuriePlayer?.player?.id + injuriePlayer?.player?.first_name + injuriePlayer?.player?.last_name}>
                      <div
                        className={styles.player__details}
                        style={{
                          borderBottom: players.injuries.length - 1 !== index ? '0.5px solid rgba(255, 255, 255, 0.25)' : 'none',
                        }}
                      >
                        <div className={`${styles.player__details_data_item} ${styles.player__name}`}>{`${injuriePlayer?.player?.first_name} ${injuriePlayer?.player?.last_name}`}</div>
                        {props.players && props.players.length > 1 && (
                          <div className={`${styles.player__details_data_item}`}>
                            {Object.keys(playerTeams).includes(injuriePlayer?.player?.id.toString()) ? playerTeams[injuriePlayer?.player?.id].name : `-`}
                          </div>
                        )}
                        <div className={styles.player__details_data_item}>{(injuriePlayer && injuriePlayer.date && t(`${dayjs(injuriePlayer.date).format('MMM. D, YYYY')}`)) || `-`}</div>
                        <div className={styles.player__details_data_item} style={{ textTransform: 'capitalize' }}>
                          {(injuriePlayer && injuriePlayer.type) || `-`}
                        </div>
                        <div className={styles.player__details_data_item}>{(injuriePlayer && injuriePlayer.return_date && t(`${dayjs(injuriePlayer.return_date).format('MMM. D, YYYY')}`)) || `-`}</div>
                        <div className={`${styles.player__details_data_item} ${styles.player__actions}`}>
                          <button
                            className={`${styles.player__button} ${styles.player__edit}`}
                            style={{ backgroundImage: `url(/static/images/icons/edit.png)` }}
                            onClick={() => {
                              setAllDisabled(false);
                              setIsModalInjuryOpen(true);
                              setSelectedPlayer({
                                id: injuriePlayer?.id,
                                date: injuriePlayer?.date ? dayjs(injuriePlayer?.date).toDate() : null,
                                side: injuriePlayer?.side,
                                type: injuriePlayer?.type,
                                player: injuriePlayer?.player,
                                is_contact: injuriePlayer?.is_contact,
                                description: injuriePlayer?.description,
                                return_date: injuriePlayer?.return_date ? dayjs(injuriePlayer?.return_date).toDate() : null,
                                match_or_training: injuriePlayer?.match_or_training,
                                index: index,
                                team: Object.keys(playerTeams).includes(injuriePlayer?.player?.id.toString()) ? playerTeams[injuriePlayer?.player?.id] : undefined,
                              });
                              setIsEdit(true);
                              setAllDisabled(false);
                              console.log(`event: Edit injury dialog`);
                              sendAmplitudeData(`Edit injury dialog`, { detail: `injury id: [${injuriePlayer?.id}]` });
                            }}
                          />
                          <button
                            className={`${styles.player__button} ${styles.player__delete}`}
                            style={{ backgroundImage: `url(/static/images/icons/delete.png)` }}
                            onClick={() => {
                              setIsDeletedPlayer(true);
                              setPlayerId(injuriePlayer?.id);
                              // const getOwnPropertyNames = Object.getOwnPropertyNames(injuriePlayer);
                              // const empty = [];
                              // getOwnPropertyNames.forEach((key) => {
                              //   if (key !== 'id') {
                              //     key === 'match_or_training' ? empty.push('Setting') : empty.push(key);
                              //   }
                              // });
                              // console.log(
                              //   empty
                              //     .map((item) => {
                              //       const mass = item.split('_');
                              //       if (mass.length < 2) {
                              //         return ucFirst(mass[0]);
                              //       } else {
                              //         if (mass[0].length > mass[1].length) {
                              //           return ucFirst(mass[0]);
                              //         } else {
                              //           return ucFirst(mass[1]);
                              //         }
                              //       }
                              //     })
                              //     .join(', '),
                              // );
                              // console.log(`event: Edited an injury`);
                              // sendAmplitudeData(`Edited an injury`, { detail: `injury id: [${injuriePlayer?.id}]` });

                              console.log(`event: Delete injury dialog`);
                              sendAmplitudeData(`Delete injury dialog`, { detail: `injury id: [${injuriePlayer?.id}]` });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className={styles.pagination__buttons_block}>
                <div className={styles.pagination__buttons_wrap}>
                  <div className={styles.pagination__text}>{`${t('Page')} ${page} ${t('of')} ${players?.total_pages}`}</div>
                  <div className={styles.pagination__buttons}>
                    <button
                      className={`${styles.pagination__button} ${page === 1 && styles.opacity}`}
                      onClick={() => {
                        if (players.total_pages !== 1) {
                          resetFlags();
                          if (page > 1) {
                            setPage(page - 1);
                          }
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      <img src="/static/images/icons/arrow-paginate.svg" className={`${styles.arrow_rotate}`} />
                    </button>
                    <button
                      className={`${styles.pagination__button} ${page === players?.total_pages && styles.opacity}`}
                      onClick={() => {
                        if (players.total_pages !== 1) {
                          resetFlags();
                          if (page < players.total_pages) {
                            setPage(page + 1);
                          }
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      <img src="/static/images/icons/arrow-paginate.svg" className={styles.total_pages} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isModalEditPlayerOpen && (
            <Modal
              isSidebarCollapsed={props.isSidebarCollapsed}
              isOpen={isModalEditPlayerOpen}
              maxWidth={577}
              minHeight={570}
              onClose={() => {
                getCloseModal();
                if (isEdit) {
                  console.log(`event: closed edit injury dialog`);
                  sendAmplitudeData(`closed edit injury dialog`);
                } else {
                  console.log(`event: closed injury dialog`);
                  sendAmplitudeData(`closed injury dialog`);
                }
              }}
            >
              <div className={styles.injury_modal}>
                <div className={styles.injury_modal__title}>{t('Add Missing Information')}</div>
                <div className={styles.injury_modal__fields_group}>
                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                      {t('Player')}
                    </span>
                    <div className={`${styles.injury_modal__select_group}`}>
                      <div className={`${styles.injury_modal__select}`}>
                        <button className={`${styles.dropdown__button} ${styles.dropdown__button_long}`}>{`${selectedPlayer.player.first_name} ${selectedPlayer.player.last_name}`}</button>
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                      {t('Date')}
                    </span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <div className={styles.injury_modal__select} style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` }}>
                        {!allDisabled && (
                          <DatePicker
                            className={`${styles.injury_modal__select} ${styles.injury_modal__select_datepicker}`}
                            dateFormat={dateFormat}
                            selected={selectedPlayer?.date}
                            onChange={(date) => setSelectedPlayer({ ...selectedPlayer, date: date })}
                            maxDate={Date.now()}
                            showDisabledMonthNavigation
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>{`${t('Distance')} (km)`}</span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <input
                        name="distance"
                        className={`${styles.injury_modal__select} ${styles.injury_modal__select_inputField}`}
                        value={selectedPlayer?.distance}
                        onChange={(event) =>
                          setSelectedPlayer({
                            ...selectedPlayer,
                            distance: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>{`${t('Over')} 25 km/h`}</span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <input
                        name="over"
                        className={`${styles.injury_modal__select} ${styles.injury_modal__select_inputField}`}
                        value={selectedPlayer?.over}
                        onChange={(event) =>
                          setSelectedPlayer({
                            ...selectedPlayer,
                            over: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>{`${t('Duration')} (min)`}</span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <input
                        name="duration"
                        className={`${styles.injury_modal__select} ${styles.injury_modal__select_inputField}`}
                        value={selectedPlayer?.duration}
                        onChange={(event) =>
                          setSelectedPlayer({
                            ...selectedPlayer,
                            duration: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>{`${t('Accelerations')} ${t('Over')} 3 m/s^2`}</span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <input
                        name="Accelerations Over 3 m/s^2"
                        className={`${styles.injury_modal__select} ${styles.injury_modal__select_inputField}`}
                        value={selectedPlayer?.accelerations}
                        onChange={(event) =>
                          setSelectedPlayer({
                            ...selectedPlayer,
                            accelerations: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>{`${t('Decelerations')} ${t('Over')} 3 m/s^2`}</span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <input
                        name="Decelerations Over 3 m/s^2"
                        className={`${styles.injury_modal__select} ${styles.injury_modal__select_inputField}`}
                        value={selectedPlayer?.decelerations}
                        onChange={(event) =>
                          setSelectedPlayer({
                            ...selectedPlayer,
                            decelerations: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.injury_modal__buttons_block}>
                  <div className={styles.injury_modal__buttons}>
                    <button
                      className={styles.injury_modal__button}
                      onClick={() => {
                        getCloseModal();
                      }}
                    >
                      {t('Cancel')}
                    </button>
                    <button
                      className={styles.injury_modal__button}
                      onClick={() => {
                        const injuriesData = {
                          date: selectedPlayer?.date ? dayjs(selectedPlayer?.date).format('YYYY-MM-DD') : '',
                          over: selectedPlayer?.over,
                          duration: selectedPlayer?.duration,
                          distance: selectedPlayer?.distance,
                          player_id: selectedPlayer?.player?.id,
                          injury_id: selectedPlayer?.injury_id,
                          return_date: selectedPlayer?.return_date ? dayjs(selectedPlayer?.return_date).format('YYYY-MM-DD') : '',
                          accelerations: selectedPlayer?.accelerations,
                          decelerations: selectedPlayer?.decelerations,
                        };
                        submitInjury(injuriesData);
                        getCloseModal();
                      }}
                    >
                      {t('Complete')}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          {isModalInjuryOpen && (
            <Modal
              isSidebarCollapsed={props.isSidebarCollapsed}
              isOpen={isModalInjuryOpen}
              maxWidth={577}
              minHeight={628}
              onClose={() => {
                getCloseModal();
                if (isEdit) {
                  console.log(`event: closed edit injury dialog`);
                  sendAmplitudeData(`closed edit injury dialog`);
                } else {
                  console.log(`event: closed injury dialog`);
                  sendAmplitudeData(`closed injury dialog`);
                }
              }}
              notBackgroundClose={true}
            >
              <div className={styles.injury_modal}>
                <div className={styles.injury_modal__title}>{isEdit ? t('Edit player injury') : t('Add injury')}</div>
                <div className={styles.injury_modal__fields_group}>
                  {props.players && props.players.length > 1 && (
                    <div className={styles.injury_modal__fields_item}>
                      <span className={styles.injury_modal__fields_name}>{t('Team')}</span>

                      <div className={`${styles.injury_modal__popup_substrate} ${isTeamDropdown ? styles.block : styles.none}`} onClick={() => setIsTeamDropdown(false)} />

                      <div className={`${styles.injury_modal__select_group} ${isTeamDropdown && styles['dropdown__button--active']}`}>
                        <div className={`${styles.injury_modal__select}`}>
                          <button
                            disabled={isEdit}
                            className={`${styles.dropdown__button} ${isTeamDropdown && styles['dropdown__button--active']}`}
                            style={{
                              backgroundImage: !isEdit && `url(/static/images/icons/open-dropdown-arrow.svg)`,
                              width: '210px',
                              cursor: isEdit && 'auto',
                            }}
                            onClick={() => {
                              setIsTeamDropdown(!isTeamDropdown);
                              setIsSideDropdown(false);
                              setIsTypeDropdown(false);
                              setIsShowPlayersDropdown(false);
                              setIsContactDropdown(false);
                              setIsShowTextarea(false);
                              setIsSettingDropdown(false);
                            }}
                          >
                            {selectedPlayer && selectedPlayer.team
                              ? selectedPlayer.team.name
                              : !isTeamDropdown && <span className={`${styles.dropdown__button_line} ${styles.dropdown__button_line__short} ${error.includes('team') ? styles.error : ''}`} />}
                          </button>
                          <ul className={`${styles.dropdown__list} ${isTeamDropdown ? styles['dropdown__list--visible'] : ''}`} style={{ maxHeight: '350px' }}>
                            {props &&
                              props.players &&
                              props.players.length &&
                              props.players.map((item, index) => {
                                return (
                                  <li
                                    className={styles['dropdown__list-item']}
                                    value={item.team.name}
                                    key={item.team.name + item.team.id}
                                    onClick={() => {
                                      setSelectedPlayer({ team: item.team });
                                      setPlayerDisabled(false);
                                      setTeam(index);
                                      setIsTeamDropdown(false);
                                    }}
                                  >
                                    {item.team.name}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={`${styles.injury_modal__popup_substrate} ${isShowPlayersDropdown ? styles.block : styles.none}`} onClick={() => setIsShowPlayersDropdown(false)} />
                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                      {t('Player')}
                    </span>
                    <div className={`${styles.injury_modal__select_group} ${isShowPlayersDropdown && !isEdit && styles['dropdown__button--active']}`}>
                      <div className={`${styles.injury_modal__select}`}>
                        <button
                          disabled={playerDisabled}
                          className={`${styles.dropdown__button} ${styles.dropdown__button_long} ${isShowPlayersDropdown && !isEdit && styles['dropdown__button--active']}`}
                          style={
                            !playerDisabled
                              ? {
                                  backgroundImage: !isEdit && `url(/static/images/icons/open-dropdown-arrow.svg)`,
                                }
                              : { cursor: 'auto' }
                          }
                          onClick={() => {
                            setIsShowPlayersDropdown(!isShowPlayersDropdown);
                            setIsTeamDropdown(false);
                            setIsTypeDropdown(false);
                            setIsSideDropdown(false);
                            setIsContactDropdown(false);
                            setIsShowTextarea(false);
                            setIsSettingDropdown(false);
                          }}
                        >
                          {selectedPlayer && selectedPlayer.player && selectedPlayer.player.first_name && selectedPlayer.player.last_name
                            ? `${selectedPlayer.player.first_name}  ${selectedPlayer.player.last_name}`
                            : !playerDisabled &&
                              !isShowPlayersDropdown && <span className={`${styles.dropdown__button_line} ${styles.dropdown__button_line__long} ${error.includes('player_id') ? styles.error : ''}`} />}
                        </button>
                        {
                          <ul className={`${styles.dropdown__list} ${isShowPlayersDropdown && !isEdit ? styles['dropdown__list--visible'] : ''}`}>
                            {props &&
                              props.players &&
                              typeof team === 'number' &&
                              props.players[team].players.map((injuriePlayer) => {
                                return (
                                  <li
                                    className={styles['dropdown__list-item']}
                                    style={{ maxWidth: '313px' }}
                                    value={injuriePlayer?.id}
                                    key={injuriePlayer?.id}
                                    onClick={() => {
                                      // if (!isEdit) {
                                      setSelectedPlayer({
                                        ...selectedPlayer,
                                        player: injuriePlayer,
                                        injury_id: injuriePlayer?.id,
                                      });
                                      setIsShowPlayersDropdown(false);
                                      setAllDisabled(false);
                                      // }
                                    }}
                                  >
                                    {`${injuriePlayer?.first_name} ${injuriePlayer?.last_name}`}
                                  </li>
                                );
                              })}
                          </ul>
                        }
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                      {t('Date')}
                    </span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <div
                        className={styles.injury_modal__select}
                        style={
                          !allDisabled
                            ? {
                                backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`,
                              }
                            : { cursor: 'auto' }
                        }
                      >
                        {selectedPlayer && !selectedPlayer.date && !isDateOpen && !allDisabled && (
                          <span className={`${styles.dropdown__button_line__datepicker} ${error.includes('date') ? styles.error : ''}`} />
                        )}
                        {!allDisabled && (
                          <DatePicker
                            className={`${styles.injury_modal__select} ${styles.injury_modal__select_datepicker}`}
                            dateFormat={dateFormat}
                            selected={selectedPlayer && selectedPlayer.date}
                            onCalendarOpen={() => {
                              setIsDateOpen(!isDateOpen);
                              setIsTeamDropdown(false);
                              setIsShowTextarea(false);
                              setIsContactDropdown(false);
                              setIsSettingDropdown(false);
                              setIsSideDropdown(false);
                              setIsTypeDropdown(false);
                              setIsShowPlayersDropdown(false);
                            }}
                            onCalendarClose={() => setIsDateOpen(!isDateOpen)}
                            onChange={(date) => setSelectedPlayer({ ...selectedPlayer, date: date, return_date: undefined })}
                            maxDate={dayjs().toDate()}
                            showDisabledMonthNavigation
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name}>{t('Type')}</span>

                    <div className={`${styles.injury_modal__popup_substrate} ${isTypeDropdown ? styles.block : styles.none}`} onClick={() => setIsTypeDropdown(false)} />
                    <div className={`${styles.injury_modal__select_group} ${isTypeDropdown && styles['dropdown__button--active']}`}>
                      <div className={`${styles.injury_modal__select}`}>
                        <button
                          disabled={allDisabled}
                          className={`${styles.dropdown__button} ${isTypeDropdown && styles['dropdown__button--active']}`}
                          style={
                            !allDisabled
                              ? {
                                  backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`,
                                  maxWidth: '369px',
                                  width: '210px',
                                }
                              : {
                                  maxWidth: '369px',
                                  width: '210px',
                                  cursor: 'auto',
                                }
                          }
                          onClick={() => {
                            setIsTypeDropdown(!isTypeDropdown);
                            setIsTeamDropdown(false);
                            setIsShowPlayersDropdown(false);
                            setIsSideDropdown(false);
                            setIsContactDropdown(false);
                            setIsShowTextarea(false);
                            setIsSettingDropdown(false);
                          }}
                        >
                          {selectedPlayer && selectedPlayer.type
                            ? selectedPlayer.type
                            : !isTypeDropdown &&
                              !allDisabled && <span className={`${styles.dropdown__button_line} ${styles.dropdown__button_line__short} ${error.includes('type') ? styles.error : ''}`} />}
                        </button>
                        <ul className={`${styles.dropdown__list} ${isTypeDropdown ? styles['dropdown__list--visible'] : ''}`} style={{ maxHeight: '350px' }}>
                          {props &&
                            props.injury_values &&
                            props.injury_values.types &&
                            props.injury_values.types.map((injuryType) => {
                              return (
                                <li
                                  className={styles['dropdown__list-item']}
                                  value={injuryType?.value}
                                  key={injuryType?.name + injuryType?.value}
                                  onClick={() => {
                                    setSelectedPlayer({ ...selectedPlayer, type: injuryType.value });
                                    setIsTypeDropdown(false);
                                  }}
                                >
                                  {injuryType.name}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name}>{t('Side')}</span>

                    <div className={`${styles.injury_modal__popup_substrate} ${isSideDropdown ? styles.block : styles.none}`} onClick={() => setIsSideDropdown(false)} />

                    <div className={`${styles.injury_modal__select_group} ${isSideDropdown && styles['dropdown__button--active']}`}>
                      <div className={`${styles.injury_modal__select}`}>
                        <button
                          disabled={allDisabled}
                          className={`${styles.dropdown__button} ${isSideDropdown && styles['dropdown__button--active']}`}
                          style={
                            !allDisabled
                              ? {
                                  backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`,
                                  width: '210px',
                                }
                              : {
                                  width: '210px',
                                  cursor: 'auto',
                                }
                          }
                          onClick={() => {
                            setIsSideDropdown(!isSideDropdown);
                            setIsTeamDropdown(false);
                            setIsTypeDropdown(false);
                            setIsShowPlayersDropdown(false);
                            setIsContactDropdown(false);
                            setIsShowTextarea(false);
                            setIsSettingDropdown(false);
                          }}
                        >
                          {selectedPlayer && selectedPlayer.side
                            ? selectedPlayer.side
                            : !isSideDropdown &&
                              !allDisabled && <span className={`${styles.dropdown__button_line} ${styles.dropdown__button_line__short} ${error.includes('side') ? styles.error : ''}`} />}
                        </button>
                        <ul className={`${styles.dropdown__list} ${isSideDropdown ? styles['dropdown__list--visible'] : ''}`} style={{ maxHeight: '350px' }}>
                          {props &&
                            props.injury_values &&
                            props.injury_values.sides &&
                            props.injury_values.sides.map((injurySide) => {
                              return (
                                <li
                                  className={styles['dropdown__list-item']}
                                  value={injurySide?.value}
                                  key={injurySide?.name + injurySide?.value}
                                  onClick={() => {
                                    setSelectedPlayer({ ...selectedPlayer, side: injurySide?.value });
                                    setIsSideDropdown(false);
                                  }}
                                >
                                  {injurySide?.name}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                      {t('Setting')}
                    </span>

                    <div className={`${styles.injury_modal__popup_substrate} ${isSettingDropdown ? styles.block : styles.none}`} onClick={() => setIsSettingDropdown(false)} />

                    <div className={`${styles.injury_modal__select_group} ${isSettingDropdown && styles['dropdown__button--active']}`}>
                      <div className={`${styles.injury_modal__select}`}>
                        <button
                          disabled={allDisabled}
                          className={`${styles.dropdown__button} ${isSettingDropdown && styles['dropdown__button--active']}`}
                          style={
                            !allDisabled
                              ? {
                                  backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`,
                                  maxWidth: '369px',
                                  width: '210px',
                                }
                              : {
                                  maxWidth: '369px',
                                  width: '210px',
                                  cursor: 'auto',
                                }
                          }
                          onClick={() => {
                            setIsSettingDropdown(!isSettingDropdown);
                            setIsTeamDropdown(false);
                            setIsSideDropdown(false);
                            setIsTypeDropdown(false);
                            setIsShowPlayersDropdown(false);
                            setIsContactDropdown(false);
                            setIsShowTextarea(false);
                          }}
                        >
                          {selectedPlayer && selectedPlayer.match_or_training
                            ? selectedPlayer.match_or_training
                            : !isSettingDropdown &&
                              !allDisabled && <span className={`${styles.dropdown__button_line} ${styles.dropdown__button_line__short} ${error.includes('Setting') ? styles.error : ''}`} />}
                        </button>
                        <ul className={`${styles.dropdown__list} ${isSettingDropdown ? styles['dropdown__list--visible'] : ''}`} style={{ maxHeight: '350px' }}>
                          <li
                            className={styles['dropdown__list-item']}
                            onClick={() => {
                              setSelectedPlayer({ ...selectedPlayer, match_or_training: 'match' });
                              setIsSettingDropdown(false);
                            }}
                          >
                            {'Match'}
                          </li>
                          <li
                            className={styles['dropdown__list-item']}
                            onClick={() => {
                              setSelectedPlayer({ ...selectedPlayer, match_or_training: 'training' });
                              setIsSettingDropdown(false);
                            }}
                          >
                            {'Training'}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                      {t('Contact')}
                    </span>

                    <div className={`${styles.injury_modal__popup_substrate} ${isContactDropdown ? styles.block : styles.none}`} onClick={() => setIsContactDropdown(false)} />

                    <div className={`${styles.injury_modal__select_group} ${isContactDropdown && styles['dropdown__button--active']}`}>
                      <div className={`${styles.injury_modal__select}`}>
                        <button
                          disabled={allDisabled}
                          className={`${styles.dropdown__button} ${isContactDropdown && styles['dropdown__button--active']}`}
                          style={
                            !allDisabled
                              ? {
                                  backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`,
                                  maxWidth: '369px',
                                  width: '210px',
                                }
                              : {
                                  maxWidth: '369px',
                                  width: '210px',
                                  cursor: 'auto',
                                }
                          }
                          onClick={() => {
                            setIsContactDropdown(!isContactDropdown);
                            setIsTeamDropdown(false);
                            setIsSettingDropdown(false);
                            setIsSideDropdown(false);
                            setIsTypeDropdown(false);
                            setIsShowPlayersDropdown(false);
                            setIsShowTextarea(false);
                          }}
                        >
                          {selectedPlayer && selectedPlayer.is_contact
                            ? selectedPlayer.is_contact
                            : !isContactDropdown &&
                              !allDisabled && <span className={`${styles.dropdown__button_line} ${styles.dropdown__button_line__short} ${error.includes('is_contact') ? styles.error : ''}`} />}
                        </button>
                        <ul className={`${styles.dropdown__list} ${isContactDropdown ? styles['dropdown__list--visible'] : ''}`} style={{ maxHeight: '350px' }}>
                          <li
                            className={styles['dropdown__list-item']}
                            onClick={() => {
                              setSelectedPlayer({ ...selectedPlayer, is_contact: 'yes' });
                              setIsContactDropdown(false);
                            }}
                          >
                            {t('Yes')}
                          </li>
                          <li
                            className={styles['dropdown__list-item']}
                            onClick={() => {
                              setSelectedPlayer({ ...selectedPlayer, is_contact: 'no' });
                              setIsContactDropdown(false);
                            }}
                          >
                            {t('No')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name} style={{ width: '120px' }}>
                      {t('Description')}
                    </span>

                    <div
                      className={`${styles.injury_modal__popup_substrate} ${isShowTextarea ? styles.block : styles.none}`}
                      onClick={() => {
                        setIsShowTextarea(false);
                      }}
                    />

                    <div className={`${styles.injury_modal__select_group} ${styles.dropdown__button_long__description}`}>
                      <div
                        className={`${styles.injury_modal__select} ${styles.injury_modal__select_description}`}
                        style={!allDisabled ? { backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)`, position: 'relative' } : { position: 'relative', cursor: 'auto' }}
                        onClick={() => {
                          if (!allDisabled) setIsShowTextarea(!isShowTextarea);
                          setIsTeamDropdown(false);
                          setIsContactDropdown(false);
                          setIsSettingDropdown(false);
                          setIsSideDropdown(false);
                          setIsTypeDropdown(false);
                          setIsShowPlayersDropdown(false);
                        }}
                      >
                        {selectedPlayer?.description && (selectedPlayer?.description?.length === 0 || selectedPlayer?.description?.length < 46)
                          ? selectedPlayer?.description
                          : selectedPlayer?.description
                          ? `${selectedPlayer?.description.substr(0, 40)}...`
                          : descriptionInput}
                      </div>

                      {isShowTextarea && (
                        <div style={{ position: 'absolute', top: '-1px', left: '10px', width: '300px' }}>
                          {/*<div*/}
                          {/*    className={styles.injury_modal__select_textarea__button}*/}
                          {/*    style={{top: textareaHeight === 110 ? '85px' : '270px'}}*/}
                          {/*    onClick={() => {*/}
                          {/*        if (textareaHeight === 110) {*/}
                          {/*            setTextareaHeight(305);*/}
                          {/*        } else if (textareaHeight === 305) {*/}
                          {/*            setTextareaHeight(110);*/}
                          {/*        } else {*/}
                          {/*            setTextareaHeight(110);*/}
                          {/*        }*/}
                          {/*    }}*/}
                          {/*>*/}
                          {/*    <span*/}
                          {/*        className={styles.injury_modal__select_textarea__button_top}*/}
                          {/*        style={{backgroundImage: `url(/static/images/icons/description-arrow.svg)`}}*/}
                          {/*    />*/}
                          {/*    <span*/}
                          {/*        className={styles.injury_modal__select_textarea__button_bottom}*/}
                          {/*        style={{backgroundImage: `url(/static/images/icons/description-arrow.svg)`}}*/}
                          {/*    />*/}
                          {/*</div>*/}

                          <button
                            className={styles.injury_modal__select_textarea__button_close}
                            style={{ backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` }}
                            onClick={() => {
                              setIsTeamDropdown(false);
                              setIsShowTextarea(false);
                              setIsContactDropdown(false);
                              setIsSettingDropdown(false);
                              setIsSideDropdown(false);
                              setIsTypeDropdown(false);
                              setIsShowPlayersDropdown(false);
                            }}
                          />

                          <textarea
                            className={styles.injury_modal__select_textarea}
                            placeholder={t('Type Description')}
                            style={{ height: `${textareaHeight}px` }}
                            value={selectedPlayer?.description}
                            onChange={(event) => {
                              setSelectedPlayer({
                                ...selectedPlayer,
                                description: event.target.value,
                              });
                              setDescriptionInput(event.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.injury_modal__fields_item}>
                    <span className={styles.injury_modal__fields_name}>{t('Return Date')}</span>
                    <div className={styles.injury_modal__select_group} style={{ width: '210px' }}>
                      <div className={styles.injury_modal__select} style={!allDisabled ? { backgroundImage: `url(/static/images/icons/open-dropdown-arrow.svg)` } : { cursor: 'auto' }}>
                        {selectedPlayer && !selectedPlayer.return_date && !isReturnDateOpen && !allDisabled && <span className={`${styles.dropdown__button_line__datepicker}`} />}
                        {!allDisabled && (
                          <DatePicker
                            className={`${styles.injury_modal__select} ${styles.injury_modal__select_datepicker}`}
                            dateFormat={dateFormat}
                            selected={selectedPlayer?.return_date}
                            onCalendarOpen={() => {
                              setIsReturnDateOpen(!isReturnDateOpen);
                              setIsTeamDropdown(false);
                              setIsShowTextarea(false);
                              setIsContactDropdown(false);
                              setIsSettingDropdown(false);
                              setIsSideDropdown(false);
                              setIsTypeDropdown(false);
                              setIsShowPlayersDropdown(false);
                            }}
                            onCalendarClose={() => setIsReturnDateOpen(!isReturnDateOpen)}
                            onChange={(date) => setSelectedPlayer({ ...selectedPlayer, return_date: date })}
                            minDate={tomorrow}
                            showDisabledMonthNavigation
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.injury_modal__buttons_block}>
                  <div className={styles.injury_modal__buttons}>
                    <button
                      className={styles.injury_modal__button}
                      onClick={() => {
                        getCloseModal();
                        if (isEdit) {
                          console.log(`event: closed edit injury dialog`);
                          sendAmplitudeData(`closed edit injury dialog`);
                        } else {
                          console.log(`event: closed injury dialog`);
                          sendAmplitudeData(`closed injury dialog`);
                        }
                      }}
                    >
                      {t('Cancel')}
                    </button>
                    <button
                      className={styles.injury_modal__button}
                      onClick={() => {
                        const injuriesData = {
                          date: selectedPlayer?.date ? dayjs(selectedPlayer?.date).format('YYYY-MM-DD') : '',
                          side: selectedPlayer?.side,
                          type: selectedPlayer?.type,
                          player_id: selectedPlayer?.player?.id,
                          is_contact: selectedPlayer?.is_contact,
                          description: selectedPlayer?.description ? selectedPlayer?.description : '',
                          return_date: selectedPlayer?.return_date ? dayjs(selectedPlayer?.return_date).format('YYYY-MM-DD') : '',
                          match_or_training: selectedPlayer?.match_or_training,
                        };
                        if (selectedPlayer?.id) {
                          injuriesData.id = selectedPlayer?.id;
                        }
                        submitInjury(injuriesData);
                      }}
                    >
                      {t('Complete')}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          {isDeletedPlayer && (
            <Modal
              isSidebarCollapsed={props.isSidebarCollapsed}
              isOpen={isDeletedPlayer}
              maxWidth={577}
              minHeight={219}
              onClose={() => {
                getCloseModal();
                console.log(`event: closed delete injury dialog`);
                sendAmplitudeData(`closed delete injury dialog`);
              }}
            >
              <div className={styles.local__modal_header}>{t('Delete injury')}?</div>
              <div className={styles.local__modal_title}>{t('Changes may take up to 24 hours to take effect.')}</div>
              <div className={styles.local__modal_buttons_wrapper}>
                <div className={styles.local__modal_buttons_block}>
                  <button
                    className={styles.local__modal_button}
                    onClick={() => {
                      getCloseModal();
                      console.log(`event: closed delete injury dialog`);
                      sendAmplitudeData(`closed delete injury dialog`);
                    }}
                  >
                    {t('Cancel')}
                  </button>
                  <button
                    className={styles.local__modal_button}
                    onClick={() => {
                      submitDeleteInjury(playerId);
                      getCloseModal();
                      console.log(`event: closed delete injury dialog`);
                      sendAmplitudeData(`closed delete injury dialog`);
                    }}
                  >
                    {t('Delete')}
                  </button>
                </div>
              </div>
            </Modal>
          )}
          <PlayerInjuryErrorModal
            isSidebarCollapsed={props.isSidebarCollapsed}
            errorModalOpen={errorModalOpen}
            errorList={errorList}
            close={() => {
              setErrorModalOpen(false);
            }}
          />
        </section>
      )}
    </Fragment>
  );
}
